export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const setIsAdmin = (data) => ({
    type: SET_IS_ADMIN,
    payload: data
});

export const SET_IS_SUPERADMIN = 'SET_IS_SUPERADMIN';
export const setIsSuperAdmin = (data) => ({
    type: SET_IS_SUPERADMIN,
    payload: data
});

export const SET_BACK_NAV = 'SET_BACK_NAV';
export const setToNavBack = (val, route = '') => ({
    type: SET_BACK_NAV,
    payload: { val, route }
});

export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const setLocationsList = (data) => ({
    type: SET_LOCATIONS_LIST,
    payload: data
});

export const SET_TRIMSEASON_LIST = 'SET_TRIMSEASON_LIST';
export const setTrimSeasonList = (data) => ({
    type: SET_TRIMSEASON_LIST,
    payload: data
});

export const SET_TRIMTYPE_LIST = 'SET_TRIMTYPE_LIST';
export const setTrimTypeList = (data) => ({
    type: SET_TRIMTYPE_LIST,
    payload: data
});

export const SET_FIELDS = 'SET_FIELDS';
export const setFields = (obj) => ({
    type: SET_FIELDS,
    payload: obj
});

export const SET_GROWER_ENTITY = 'SET_GROWER_ENTITY';
export const setGrowerEntities = (obj) => ({
    type: SET_GROWER_ENTITY,
    payload: obj
});

export const SET_NURSERY_ENTITY = 'SET_NURSERY_ENTITY';
export const setNurseryEntities = (obj) => ({
    type: SET_NURSERY_ENTITY,
    payload: obj
});
