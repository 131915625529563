import React from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { DrcMain, DrcPanel, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import FloatingAddBtn from '../../components/FloatingAddBtn';
import TagNumberSearch from '../../components/TagNumberSearch';
import NewReturn from './NewReturn';
import AccordianList from '../../components/AccordianList';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { getExcludedColumns } from '../../data/constants';
import APIEndPoints from '../../services/api';
import { setTagNbr } from '../../actions/barcodeSearchAction';
import { setReasonCodeList } from '../../actions/AdjustTrim';
import { setReturnHeader, setReturnInventory, setReturnList } from '../../actions/Return';
import { notify } from '../../actions/NotificationAction';
import { setToNavBack } from '../../actions/MasterActions';
import { setPageTitleAction } from '../../actions/actions';
import { makeColumns, formatData, formatKey, setColumnOrder, getStoredLocation, getTrimSeasonId, checkPermission } from '../../utils/helper';
import cloneDeep from 'lodash/cloneDeep';
import { ReportUtility } from '../../utils/reportUtility';
import PrintDialog from './../../components/PrintDialog';
import { PrintUtility } from './../../utils/printUtility';

const PAGE_KEY = 'RETURNADMINKEY';
const styles = (theme) => ({
    panel: {
        border: '0px',
        height: '100%'
    },
    main: {
        marginBottom: '8vh'
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    search: {
        width: '87% '
    },
    avtr: {
        backgroundColor: theme.palette.common.white,
        height: '17px',
        width: '17px',
        fontSize: '10px !important',
        lineHeight: '13px',
        marginLeft: '2rem'
    },
    avtrRed: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main
    },
    avtrGreen: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
    },
    labelContainer: {
        letterSpacing: '0.5px',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1)
    },
    labelStyle: {
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    label: {
        letterSpacing: '0.2px',
        fontWeight: 500,
        color: theme.palette.common.black,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '0.9rem',
        gridColumnEnd: 'span 4'
    }
});

const pageTitle = 'Return';
class Return extends React.Component {
    state = {
        createReturn: false,
        list: [],
        currentPageName: 'Return',
        hasMoreData: true,
        pageSize: 25,
        startIndex: 0,
        hasAccess: false,
        printDialogOpen: false,
        printRow: ''
    };

    async loadAndUpdateData(offset, limit) {
        try {
            if (!MasterDataUtilities.GetSetup()) {
                return;
            }

            let token = this.props.authState.accessToken;
            let response = await Middleware.Send(
                'ReturnList',
                token,
                APIEndPoints.RETURN_LIST(offset, limit, getTrimSeasonId().Id, getStoredLocation().Code),
                'GET',
                {}
            );
            let data = cloneDeep(this.props.returnList).concat(response.Records);
            this.props.setReturnList(data);
            this.setState({ list: data, totalRowCount: response.TotalRowCount, hasMoreData: true });
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Return Log', true);
        }
    }

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (!checkPermission(PAGE_KEY, this.props.distinctAccessKeys)) {
            this.setState({ hasAccess: false });
        } else {
            this.setState({ hasAccess: true });
            try {
                let token = this.props.authState.accessToken;
                let reasonCodeList = await Middleware.Send('ReasonCode', token, APIEndPoints.REASON_CODES('INVENTORY'), 'GET', {});
                this.props.setReasonCodeList(reasonCodeList.Result);
            } catch (err) {
                console.log(err);
            }
            this.loadAndUpdateData(this.state.startIndex, this.state.pageSize);
        }

        this.props.setReturnList([]);
        this.props.setReturnHeader({});
        this.props.setReturnInventory([]);
        this.props.setToNavBack(false);
    }

    fetchMoreData = () => {
        const { returnList } = this.props;
        const { totalRowCount } = this.state;
        const lastRowAdded = returnList.length;
        if (lastRowAdded >= totalRowCount) {
            this.setState({ hasMoreData: false });
            return;
        }

        this.loadAndUpdateData(lastRowAdded, this.state.pageSize);
    };

    fetchDetails = async (tagNbr) => {
        try {
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send(
                'Return',
                token,
                APIEndPoints.RETURN_INVENTORY(tagNbr, getTrimSeasonId().Id, getStoredLocation().Code),
                'GET',
                {}
            );
            let inventoryLines = Array.from(response.ReturnLines);
            let headerData = response.ReturnHeader;
            if (!headerData) {
                this.props.setTagNbr('');
                return this.props.notify('TagNbr not found', true);
            }
            this.props.setReturnHeader(headerData);
            this.props.setReturnInventory(inventoryLines);
            this.props.history.push(`/Return/${headerData.ReturnedFromType}/${headerData.LogNbr}`);
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Return Log', true);
        }
    };

    toggleCreateReturn = () => {
        this.setState({ createReturn: !this.state.createReturn, currentPageName: 'RETURN HEADER' });
    };

    handleTagNbrClick = (row) => {
        this.props.setReturnHeader(row);
        this.props.history.push(`/Return/${row.ReturnedFromType}/${row.LogNbr}`);
    };

    headerFormatter = (value, row) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.labelContainer}>
                    <span
                        className={classes.labelStyle}
                        onClick={() => {
                            this.handleTagNbrClick(row);
                        }}
                    >
                        {value}
                    </span>
                </div>
                <div className={classes.label}>{`${row.ReturnedFromNbr} - ${row.growerEntity}`}</div>
            </>
        );
    };

    clearSearch = (bool, row) => {
        // this.props.setTagNbr(bool ? row.LogNbr : '');
    };

    redirect = (logNbr) => {
        this.props.history.push(`/Return/Edit/${logNbr}`);
    };

    togglePrintDialog = () => {
        this.setState({ printDialogOpen: !this.state.printDialogOpen });
    };

    handlePrint = (row) => {
        if (!PrintUtility.GetPrintServer()) {
            this.props.notify('Printing report...', false);
            this.setState({ printRow: row }, async () => this.printRow());
            return;
        }

        this.setState({ printRow: row });
        this.togglePrintDialog();
    };

    printRow = async (numberOfCopies, printerAddress) => {
        const { printRow } = this.state;
        let token = this.props.authState.accessToken;
        try {
            await ReportUtility.PrintReturn(token, printRow && printRow.LogNbr, numberOfCopies, printerAddress);
            if (printerAddress) {
                this.togglePrintDialog();
                this.props.notify(`Submitted ${numberOfCopies} ${numberOfCopies > 1 ? 'copies' : 'copy'} to printer`, false);
            }
        } catch (err) {
            console.log(err);
            this.props.notify(`Unable to print report`, true);
        }
    };

    render() {
        const { returnList, isMasterDataInitialized, classes } = this.props;
        const { printDialogOpen, totalRowCount } = this.state;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }

        if (this.state.hasAccess) {
            return (
                <DrcMain transparent handHeld className={classes.main}>
                    <DrcPanel className={classes.panel}>
                        <Typography variant="h6">{this.state.currentPageName}</Typography>
                        {this.state.createReturn ? (
                            <NewReturn onCancel={this.toggleCreateReturn} redirect={this.redirect} />
                        ) : (
                            <>
                                <div className={classes.action}>
                                    <TagNumberSearch
                                        autoFocus
                                        className={classes.search}
                                        search={() => this.fetchDetails(this.props.tagNbr)}
                                        label={'Search by Log #'}
                                    />
                                    {checkPermission('NURSERYRETURNADMINKEY', this.props.distinctAccessKeys) ||
                                    checkPermission('GROWERRETURNADMINKEY', this.props.distinctAccessKeys) ? (
                                        <FloatingAddBtn onClick={this.toggleCreateReturn} />
                                    ) : null}
                                </div>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    className={classes.subText}
                                >{`${returnList.length} of ${totalRowCount}`}</Typography>
                                <AccordianList
                                    data={setColumnOrder(returnList, 'returnHeader')}
                                    columns={makeColumns(returnList[0], [])}
                                    // customExpand={(row) => '' + row.LogNbr === tagNbr}
                                    label="LogNbr"
                                    excludeColumns={getExcludedColumns('returnHeader')}
                                    labelFormatter={this.headerFormatter}
                                    hasMoreData={this.state.hasMoreData}
                                    fetchMoreData={this.fetchMoreData}
                                    dataFormatter={formatData}
                                    keyFormatter={formatKey}
                                    clearSearch={this.clearSearch}
                                    expandRight={false}
                                    checkEditable={(obj) => {
                                        return obj.PostStatus === 'OPEN' &&
                                            (checkPermission('NURSERYRETURNADMINKEY', this.props.distinctAccessKeys) ||
                                                checkPermission('GROWERRETURNADMINKEY', this.props.distinctAccessKeys))
                                            ? 'right'
                                            : '';
                                    }}
                                    onEdit={(obj) => {
                                        this.redirect(obj.LogNbr);
                                    }}
                                    scrollable
                                    checkPrintable={(row) => (row.PostStatus === 'POSTED' ? 'right' : null)}
                                    onPrint={(row) => this.handlePrint(row)}
                                />
                            </>
                        )}
                        {this.state.printDialogOpen && (
                            <PrintDialog reportType={'Return'} onCancel={this.togglePrintDialog} onPrint={this.printRow} open={printDialogOpen} />
                        )}
                    </DrcPanel>
                </DrcMain>
            );
        } else {
            return <DrcPageNotAuthorized />;
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setReturnHeader: (header) => dispatch(setReturnHeader(header)),
    setReturnInventory: (data) => dispatch(setReturnInventory(data)),
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr)),
    setReturnList: (list) => dispatch(setReturnList(list)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setReasonCodeList: (data) => dispatch(setReasonCodeList(data)),
    setToNavBack: (data) => dispatch(setToNavBack(data))
});

function mapStateToProps({ masterReducer, rootReducer, barcodeSearchReducer, returnLog, userAccesses, adjustTrim }) {
    return {
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        returnList: returnLog.returnList,
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        tagNbr: barcodeSearchReducer.tagNbr,
        locationMap: masterReducer.locationMap,
        reasonCodeList: adjustTrim.reasonCodeList
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Return)));
