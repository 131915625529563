import React, { useState, useEffect } from 'react';
import { DrcSelect } from '@driscollsinc/driscolls-react-components';
import { connect } from 'react-redux';
import AdjustTrim from '../AdjustTrim/AdjustTrim';
import { withStyles } from '@material-ui/styles';
import { useOktaAuth } from '@okta/okta-react';
import { getLocalTrimAndLocation, getStoredTrim, getTrimSeasonId, makeLocationOption } from '../../utils/helper';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../../services/api';
import MasterDataUtilities from '../../data/MasterDataUtilities';

const styles = (theme) => ({
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '1rem',
        fontStyle: 'italic',
        gridColumnEnd: 'span 4',
        padding: '0.8rem 0 0.3rem 0'
    }
});

const GrowerAdjustTrim = (props) => {
    const [grower, setGrower] = useState(null);
    const [growersList, setGrowerList] = useState([]);
    const { authState } = useOktaAuth();
    const onSelectChange = (row) => {
        setGrower(row);
    };
    useEffect(() => {
        const getData = async () => {
            try {
                let trimSeason = getStoredTrim();
                let berry = props.trimSeasonMap[trimSeason];
                let token = authState.accessToken;
                const resp = await Middleware.Send('GrowerEntities', token, APIEndPoints.GROWERS_LIST(berry, getTrimSeasonId().Id), 'GET', {});
                setGrowerList(makeLocationOption(resp.Result, 'Id', 'GrowerEntity'));
            } catch (err) {
                console.log(err);
            }
        };
        getData();
    }, []);

    if (!MasterDataUtilities.GetSetup()) {
        return MasterDataUtilities.RedirectToSetup();
    }

    const location = getLocalTrimAndLocation().location;

    const trimSeason = `${location.Code}-${location.Descript}`;
    const { classes } = props;
    return (
        <div>
            <div className={classes.subText}>{trimSeason}</div>
            <DrcSelect
                options={growersList}
                label="Select Grower"
                onChange={onSelectChange}
                value={grower}
                required={true}
                isWindowedSelect
                ignoreAccents={true}
            />
            {grower && <AdjustTrim ignorePermissions returnFrom={grower || {}} />}
        </div>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    trimSeasonMap: masterReducer.trimSeasonMap
});

export default connect(mapStateToProps, null)(withStyles(styles)(GrowerAdjustTrim));
