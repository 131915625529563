import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import LINKS from '../data/links';
import cloneDeep from 'lodash/cloneDeep';
import { useOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { checkPermission } from '../utils/helper';

function MenuBar(props) {
    const useStyles = makeStyles((theme) => ({
        menuBarContainer: {
            zIndex: '10',
            overflow: 'hidden',
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            background: theme.light.primary,
            [theme.darkTheme]: {
                background: theme.dark.primary
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '25px',
                marginRight: '25px',
                width: '96%'
            }
        },
        menuBarSecondaryContainer: {
            maxWidth: LINKS_TO_USE.length * 100,
            margin: 'auto'
        },
        button: {
            width: `${100 / LINKS_TO_USE.length}%`,
            lineHeight: 'inherit',
            display: 'inline-block',
            textAlign: 'center',
            padding: '10px 0',
            fontWeight: 'normal',
            borderRadius: 0,
            color: '#555 !important',
            textTransform: 'none',
            [theme.darkTheme]: {
                color: '#AAA !important'
            }
        },
        buttonSelected: {
            color: DuThemeUtilities.DefaultColors.primary.green + ' !important',
            fontWeight: 500,
            borderTop: '2px solid ' + DuThemeUtilities.DefaultColors.primary.green
        },
        icon: {
            '& svg': {
                fontSize: '2rem',
                marginTop: 4,
                fill: '#000',
                '@media (prefers-color-scheme: dark)': {
                    fill: '#fff'
                }
            }
        },
        title: {
            fontSize: '1.2rem',
            marginTop: -6
        }
    }));
    const LINKS_TO_USE =
        cloneDeep(LINKS).filter((link) => {
            if (link.title === 'Adjust Trim') link.title = 'Trim';
            return link.showInMenuBar && checkPermission(link.securitykey, props.distinctAccessKeys);
        }) || [];

    const classes = useStyles();
    const { authState } = useOktaAuth();
    // useEffect(() => {}, authState.isAuthenticated);
    //{ title: 'Home', url: '/Dashboard/', icon: <UploadIcon />, showInMenuBar: true },
    if (!authState.isAuthenticated) return null;
    return (
        <div className={classes.menuBarContainer}>
            <div className={classes.menuBarSecondaryContainer}>
                {LINKS_TO_USE.map((link) => {
                    return (
                        <DrcButton
                            key={link.title}
                            noStyle
                            isText
                            to={link.url}
                            className={`${classes.button} ${window.location.pathname.includes(link.url) ? classes.buttonSelected : ''}`}
                        >
                            <div className={classes.icon}>{link.icon}</div>
                            <div className={classes.title}>{link.title}</div>
                        </DrcButton>
                    );
                })}
            </div>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        distinctAccessKeys: state.userAccesses.distinctAccessKeys
    };
}
const mapDispatchToProps = (dispatch) => ({
    // checkPermission: (nbr) => checkPermission(nbr)
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
