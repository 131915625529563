import { Middleware } from '@driscollsinc/one-ring';

const printReportsMapping = [Middleware.CreateMapping('FileData', 'FileData'), Middleware.CreateMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PrintReport', 'GET')) {
        Middleware.RegisterMapping('PrintReport', 'GET', printReportsMapping);
    }
}

const ReportsMapping = {
    ConfigureMiddleware
};

export default ReportsMapping;
