export const SET_ADJUST_TRIM_HEADER_DETAILS = 'SET_ADJUST_TRIM_HEADER_DETAILS';
export const setAdjustTrimHeaderDetails = (data) => ({
    type: SET_ADJUST_TRIM_HEADER_DETAILS,
    payload: data
});

export const SET_REASON_CODE_LIST = 'SET_REASON_CODE_LIST';
export const setReasonCodeList = (data) => ({
    type: SET_REASON_CODE_LIST,
    payload: data
});

export const SET_VARIETY = 'SET_VARIETY';
export const setVariety = (data) => ({
    type: SET_VARIETY,
    payload: data
});
