import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DrcInput } from '@driscollsinc/driscolls-react-components';
import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
    label: {
        margin: '1em 0em'
    },
    outlinedInput: {
        '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        },
        '&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: theme.palette.primary.main
        }
    },
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0px`,
                border: '1px 0px 1px 0px solid rgba(0, 0, 0, 0.23)'
            }
        }
    }
});

class ButtonCounter extends React.Component {
    render() {
        const { counterLabel, classes, quantity, handleQuantityChange } = this.props;
        return (
            <div className="App">
                <div className={classes.label}>{counterLabel}</div>
                <ButtonGroup size="small" aria-label="small outlined button group" fullWidth={false}>
                    <Button disabled={quantity === 0} onClick={() => handleQuantityChange(parseInt(quantity) - 1)}>
                        -
                    </Button>
                    <DrcInput
                        value={quantity}
                        placeholder={'Enter Quantity'}
                        style={{ margin: 0, width: '30vw' }}
                        classes={classes}
                        onChange={(e) => handleQuantityChange(e.target.value)}
                    />
                    <Button onClick={() => handleQuantityChange(parseInt(quantity) + 1)}>+</Button>
                </ButtonGroup>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(ButtonCounter)));
