import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Barcode ID', 'Barcode ID'),
    Middleware.CreateMapping('Plant Code', 'Plant Code'),
    Middleware.CreateMapping('TrimType', 'TrimType'),
    Middleware.CreateMapping('StockType', 'StockType'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateArrayMapping('Grade', 'Grade'),
    Middleware.CreateArrayMapping('Rate', 'Rate'),
    Middleware.CreateArrayMapping('Storage Location', 'Storage Location'),
    Middleware.CreateArrayMapping('Attributes', 'Attributes'),
    Middleware.CreateArrayMapping('Reason Code', 'Reason Code'),
    Middleware.CreateArrayMapping('Quantity', 'Quantity')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AdjustTrim', 'GET')) {
        Middleware.RegisterMapping('AdjustTrim', 'GET', getListMapping);
    }
}

const AdjustTrimMapping = {
    ConfigureMiddleware
};

export default AdjustTrimMapping;
