import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('LogNbr', 'LogNbr'),
    Middleware.CreateMapping('LastChangedBy', 'LastChangedBy'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('TrimSeasonID', 'TrimSeasonID'),
    Middleware.CreateMapping('PostStatus', 'PostStatus'),
    Middleware.CreateMapping('ReturnedFromType', 'ReturnedFromType'),
    Middleware.CreateMapping('ReturnWarehouse', 'ReturnWarehouse'),
    Middleware.CreateMapping('RefNbr', 'RefNbr'),
    Middleware.CreateMapping('InventoryEffect', 'InventoryEffect'),
    Middleware.CreateMapping('ReturnedFromNbr', 'ReturnedFromNbr'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime'),
    Middleware.CreateMapping('ReturnedDate', 'ReturnedDate'),
    Middleware.CreateMapping('ReturnedBy', 'ReturnedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('TotalRowCount', 'TotalRowCount'),
    Middleware.CreateArrayMapping('Records', 'Records')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ReturnList', 'GET')) {
        Middleware.RegisterMapping('ReturnList', 'GET', getListMapping);
        Middleware.RegisterMapping('ReturnList', 'DELETE', getListMapping);
    }
}

const ReturnListMapping = {
    ConfigureMiddleware
};

export default ReturnListMapping;
