import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('VarietyCode', 'VarietyCode'),
    Middleware.CreateMapping('PlantCode', 'PlantCode'),
    Middleware.CreateMapping('TrimType', 'TrimType'),
    Middleware.CreateMapping('StorageType', 'StorageType'),
    Middleware.CreateMapping('StorageLocation', 'StorageLocation'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('CodeID', 'CodeID'),
    Middleware.CreateMapping('InventoryType', 'InventoryType'),
    Middleware.CreateMapping('StockType', 'StockType'),
    Middleware.CreateMapping('VarietyID', 'VarietyID'),
    Middleware.CreateMapping('Rate', 'Rate'),
    Middleware.CreateMapping('Grade', 'Grade'),
    Middleware.CreateMapping('AttributesID', 'AttributesID'),
    Middleware.CreateMapping('Attributes', 'Attributes'),
    Middleware.CreateMapping('ID', 'ID'),
    Middleware.CreateMapping('ReceivedQty', 'ReceivedQty'),
    Middleware.CreateMapping('BarcodeID', 'BarcodeID'),
    Middleware.CreateMapping('InventoryLocation', 'InventoryLocation'),
    Middleware.CreateMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PlantInventory', 'GET')) {
        Middleware.RegisterMapping('PlantInventory', 'GET', getListMapping);
        Middleware.RegisterMapping('PlantInventory', 'POST', getListMapping);
    }
}

const PlantInventoryMapping = {
    ConfigureMiddleware
};

export default PlantInventoryMapping;
