import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from './../services/api';

const fetchPrintServers = async (token, printServer, location) => {
    let response = await Middleware.Send('PrintServerList', token, APIEndPoints.PRINT_SERVER_LIST(printServer, location), 'GET', {});
    return response;
};

const savePrintServer = async (token, printServer) => {
    let response = await Middleware.Send('PrintServer', token, APIEndPoints.CREATE_PRINT_SERVER, 'POST', printServer);
    return response;
};

const deletePrintServer = async (token, printServerId) => {
    let response = await Middleware.Send('PrintServer', token, APIEndPoints.DELETE_PRINT_SERVER(printServerId), 'DELETE', {});
    return response;
};

export const MaintenanceUtility = {
    FetchPrintServers: fetchPrintServers,
    SavePrintServer: savePrintServer,
    DeletePrintServer: deletePrintServer
};
