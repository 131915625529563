import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import APIEndPoints from '../../services/api';
import { Middleware } from '@driscollsinc/one-ring';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { Typography, Grid } from '@material-ui/core';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import AccordianList from '../../components/AccordianList';
import { uiFieldMap, getExcludedColumns } from '../../data/constants';
import { startCase } from 'lodash';
import { makeColumns, formatData, formatKey, setColumnOrder, getStoredTrim } from '../../utils/helper';
import { notify } from '../../actions/NotificationAction';
import { connect } from 'react-redux';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { ReactComponent as StatusIndicator } from '../../assets/StatusIndicator.svg';
import { setToNavBack } from '../../actions/MasterActions';

const styles = (theme) => ({
    labelIndicator: {
        fill: theme.palette.primary.light
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '1rem',
        gridColumnEnd: 'span 4'
    },
    panel: {
        border: '0px',
        height: '100%'
    },
    main: {
        marginBottom: '8vh'
    },
    labelStyle: {
        color: 'grey',
        fontWeight: 'bold',
        fontSize: 16
    },
    valueStyle: {
        fontWeight: '20'
    },
    lineLabelStyle: {
        color: theme.palette.primary.light,
        fontWeight: 'bold',
        marginRight: '1em'
    },
    marginTop: {
        marginTop: '5px'
    },
    Accordian: {
        overflowY: 'hidden'
    }
});
class AdjustmentRequestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestNumber: '',
            Adjustment: {},
            AdjustmentLines: []
        };
    }
    async componentDidMount() {
        this.props.setToNavBack(true);
        await this.setState({ requestNumber: this.props.match.params.requestNumber });
        this.getDetails();
    }
    getDetails = async () => {
        let token = this.props.authState.accessToken;
        let Result = await Middleware.Send('adjustmentRequests', token, APIEndPoints.ADJUSTMENT_DETAILS(this.state.requestNumber), 'GET', {});
        this.setState({ Adjustment: Result.Adjustment, AdjustmentLines: Result.AdjustmentLines });
    };
    formatKey = (key, row) => {
        return uiFieldMap[key] || startCase(key);
    };
    getHeaderLabel = (value, dataObj) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.labelStyle}>
                    <b>Summary:</b>
                </div>
                <div className={classes.valueStyle}>{dataObj['RequestDetail']}</div>
            </>
        );
    };
    getLineLabel = (value, dataObj) => {
        const { classes } = this.props;
        return (
            <>
                <div>
                    <span className={classes.lineLabelStyle}>{dataObj['BarcodeID']}</span>
                    {dataObj.Status === 'REJECT' || dataObj.Status === 'ACCEPT' ? (
                        <span className={classes.labelIndicator}>
                            <StatusIndicator style={{ width: '1em', height: '1em' }} />
                        </span>
                    ) : null}
                </div>
                <Grid container>
                    <Grid xs={6} item>
                        <Grid container>
                            <Grid item xs={8} className={classes.labelStyle}>
                                <b>Shipped Qty:</b>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} className={classes.marginTop}>
                            <div className={classes.valueStyle}>{dataObj['ShippedQty']}</div>
                        </Grid>
                    </Grid>
                    <Grid xs={6} item>
                        <Grid container>
                            <Grid item xs={8} className={classes.labelStyle}>
                                <b>Received Qty:</b>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} className={classes.marginTop}>
                            <div className={classes.valueStyle}>{dataObj['ReceivedQty']}</div>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };
    areAllLinesAdjusted = () => {
        var AcceptedLines = this.state.AdjustmentLines.filter((line) => line.Status === 'ACCEPT' || line.Status === 'REJECT');
        if (AcceptedLines.length === this.state.AdjustmentLines.length) {
            return true;
        } else return false;
    };

    handleAccept = async (line) => {
        let { AdjustmentLines } = this.state;
        var id = line.LineID;
        let AdjustmentLine = AdjustmentLines.filter((item) => item.LineID === id)[0];
        let Qty = AdjustmentLine.Qty + (AdjustmentLine.ShippedQty - AdjustmentLine.ReceivedQty);
        if (Qty <= 0) {
            this.props.notify('The adjusted quantity is less than or equal to the inventory quantity, please reject and adjust manually', true);
            return;
        }
        line.Status = 'ACCEPT';
        var allLines = this.state.AdjustmentLines;
        let originalLines = JSON.parse(JSON.stringify(this.state.AdjustmentLines));
        let originalAdjustment = JSON.parse(JSON.stringify(this.state.Adjustment));
        var index = allLines.findIndex((index) => index.LineID === id);
        allLines = allLines.map((row, i) => {
            if (index === i) {
                return line;
            } else return row;
        });
        await this.setState({ AdjustmentLines: allLines });
        if (this.areAllLinesAdjusted()) {
            let { Adjustment } = this.state;
            Adjustment.Status = 'CLOSED';
            await this.setState({ Adjustment });
        }
        let payload = {};
        //  find if all line are adjusted

        payload.Adjustment = this.state.Adjustment;
        payload.Adjustment.TrimSeason = getStoredTrim();
        payload.Adjustment.LastChangedDateTime = new Date().toISOString();
        payload.AdjustmentLines = this.state.AdjustmentLines.filter((item) => item.LineID === id)[0];
        let token = this.props.authState.accessToken;
        try {
            let Result = await Middleware.Send('adjustmentRequests', token, APIEndPoints.ADJUSTMENT_POST, 'POST', payload);
            if (!Result.Message) {
                this.setState({ AdjustmentLines: originalLines, Adjustment: originalAdjustment });
                this.props.notify('Request Failed!', true);
            } else {
                this.props.notify(Result.Message, false);
                let Qty = AdjustmentLine.Qty + (AdjustmentLine.ShippedQty - AdjustmentLine.ReceivedQty);
                AdjustmentLine.Qty = Qty;
                AdjustmentLine.Status = 'ACCEPT';
                var adjustedLines = AdjustmentLines.map((row, i) => {
                    if (index === i) {
                        return AdjustmentLine;
                    } else return row;
                });
                this.setState({ AdjustmentLines: adjustedLines });
            }
        } catch (e) {
            this.props.notify('Request Failed!', true);
        }
    };
    handleReject = async (line) => {
        let originalLines = JSON.parse(JSON.stringify(this.state.AdjustmentLines));
        let originalAdjustment = JSON.parse(JSON.stringify(this.state.Adjustment));
        line.Status = 'REJECT';
        var allLines = this.state.AdjustmentLines;
        var id = line.LineID;
        var index = allLines.findIndex((item) => item.LineID === id);
        allLines = allLines.map((row, i) => {
            if (index === i) {
                return line;
            } else return row;
        });
        await this.setState({ AdjustmentLines: allLines });
        if (this.areAllLinesAdjusted()) {
            let { Adjustment } = this.state;
            Adjustment.Status = 'CLOSED';
            await this.setState({ Adjustment });
        }
        let payload = {};

        payload.Adjustment = this.state.Adjustment;
        payload.Adjustment.TrimSeason = getStoredTrim();
        payload.Adjustment.LastChangedDateTime = new Date().toISOString();
        payload.AdjustmentLines = this.state.AdjustmentLines.filter((item) => item.LineID === id)[0];
        let token = this.props.authState.accessToken;
        try {
            let Result = await Middleware.Send('adjustmentRequests', token, APIEndPoints.ADJUSTMENT_POST, 'POST', payload);
            if (!Result.Message) {
                this.setState({ AdjustmentLines: originalLines, Adjustment: originalAdjustment });
                this.props.notify('Request Failed!', true);
            } else {
                this.props.notify(Result.Message, false);
            }
        } catch (e) {
            this.setState({ AdjustmentLines: originalLines, Adjustment: originalAdjustment });
            this.props.notify('Request Failed!', true);
        }
    };
    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        var { classes } = this.props;
        return (
            <>
                <DrcMain transparent handHeld className={classes.main}>
                    <DrcPanel className={classes.panel}>
                        <Typography variant="h6" gutterBottom>
                            Adjustment Requests
                        </Typography>
                        <div className={classes.subText}>
                            <b>Transfer Nbr:</b> {this.state.Adjustment.TransferNbr}
                        </div>
                        <AccordianList
                            data={setColumnOrder([this.state.Adjustment], 'transferHeader')}
                            columns={makeColumns(this.state.Adjustment, [])}
                            label="Adjustment Header"
                            excludeColumns={getExcludedColumns('transferHeader')}
                            hasMoreData={this.state.hasMoreData}
                            dataFormatter={formatData}
                            keyFormatter={formatKey}
                            labelFormatter={this.getHeaderLabel}
                            checkEditable={() => false}
                            scrollable
                        ></AccordianList>
                        <Typography variant="h6" gutterBottom>
                            Inventory
                        </Typography>
                        {this.state.AdjustmentLines.map((line) => {
                            return (
                                <AccordianList
                                    className={classes.Accordian}
                                    columns={makeColumns(line, [])}
                                    data={setColumnOrder([line], 'AdjustmentRequests')}
                                    label="Adjustment Line"
                                    excludeColumns={getExcludedColumns('AdjustmentRequests')}
                                    hasMoreData={this.state.hasMoreData}
                                    dataFormatter={formatData}
                                    keyFormatter={formatKey}
                                    labelFormatter={this.getLineLabel}
                                    onSave={this.handleAccept}
                                    onCancel={this.handleReject}
                                    checkEditable={() => false}
                                    scrollable
                                >
                                    {line.Status === 'REJECT' || line.Status === 'ACCEPT' ? null : 'Accept'}
                                </AccordianList>
                            );
                        })}
                    </DrcPanel>
                </DrcMain>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setToNavBack: (bool, route) => dispatch(setToNavBack(bool, route)),
    notify: (data, isError) => dispatch(notify(data, isError))
});
function mapStateToProps({masterReducer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AdjustmentRequestDetails))));
