import { SET_DUMP_LIST, SET_DUMP_INVENTORY, SET_DUMP_HEADER, UPDATE_INVENTORY_STATUS } from '../actions/Dump';

const initialState = {
    dumpHeader: {},
    dumpList: [],
    inventoryLines: [],
    inventoryStatus: {}
};

const Dump = (state = initialState, action) => {
    switch (action.type) {
        case SET_DUMP_HEADER:
            return Object.assign({}, state, {
                dumpHeader: action.payload
            });
        case SET_DUMP_LIST:
            return Object.assign({}, state, {
                dumpList: action.payload
            });
        case SET_DUMP_INVENTORY:
            return Object.assign({}, state, {
                inventoryLines: action.payload
            });
        case UPDATE_INVENTORY_STATUS:
            return Object.assign({}, state, {
                inventoryStatus: action.payload
            });
        default:
            return state;
    }
};

export default Dump;
