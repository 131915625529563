import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { setPageTitleAction } from '../../actions/actions';
import { setFields } from '../../actions/MasterActions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcPanel, DrcInput, DrcButton, DrcSelect, DrcDatePicker } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import {
    addTimeStampToDate,
    getLocalTrimAndLocation,
    getTrimSeasonId,
    makeLocationOption,
    toISOdateString,
    isSuccessful,
    validateTemperature,
    isDateValid
} from '../../utils/helper';
import { DuDateUtilities, DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { setTransferInventory, setTransferHeader } from '../../actions/Transfer';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { Middleware } from '@driscollsinc/one-ring';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex'
    },
    spaceContainer: {
        paddingRight: '10px'
    },
    buttonContainer: {
        width: '100%',
        marginTop: '1rem',
        background: 'transparent',
        height: '50px',
        bottom: '0px'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        },
        fontSize: 'medium'
    }
});
const PAGE_TITLE = 'Transfer';

class TransferNew extends React.Component {
    constructor(props) {
        super(props);
        const {
            TransferNbr,
            Status,
            ReferenceNbr,
            CarrierName,
            ExpectedArrivalDate,
            ExpectedShipDate,
            TruckID,
            DestinationLocationID,
            OriginLocationID,
            ShipPlantTemperature,
            ReceiptPlantTemperature
        } = {
            headerData: {},
            ...this.props.headerDataObj
        };
        const sourceLabel = this.props.locationsList.filter((eachLoc) => eachLoc.Code === OriginLocationID);
        const destinationLabel = this.props.locationsList.filter((eachLoc) => eachLoc.Code === DestinationLocationID);

        this.state = {
            transferNbr: TransferNbr ? TransferNbr : null,
            status: Status ? Status : 'OPEN',
            selectedArrivalDate: ExpectedArrivalDate ? new Date(ExpectedArrivalDate) : new Date(),
            selectedShipDate: ExpectedShipDate ? new Date(ExpectedShipDate) : new Date(),
            destinationList: [],
            reference: ReferenceNbr ? ReferenceNbr : null,
            destination: DestinationLocationID ? makeLocationOption(destinationLabel, 'Code', 'Descript')[0] : null,
            carrierName: CarrierName ? CarrierName : null,
            source: OriginLocationID ? makeLocationOption(sourceLabel, 'Code', 'Descript')[0] : getLocalTrimAndLocation().location,
            shipTemp: ShipPlantTemperature ? ShipPlantTemperature : '',
            receiveTemp: ReceiptPlantTemperature ? ReceiptPlantTemperature : '',
            truckId: TruckID ? TruckID : null
        };
    }

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitleAction(PAGE_TITLE);
        }
        this.setState({
            destinationList: makeLocationOption(
                this.props.locationsList.filter((eachLoc) => eachLoc.Code !== this.state.source.Code),
                'Code',
                'Descript'
            )
        });
    }

    handleInput = (fieldName) => (event) => {
        this.setState({ [fieldName]: event.target.value });
    };

    handleDateChange = (dateObj, dateType) => {
        this.setState(dateType === 'shipDate' ? { selectedShipDate: dateObj } : { selectedArrivalDate: dateObj });
    };

    handleSelectChange = (option, props) => {
        this.setState({ [props.name]: option });
    };

    getHeaderData = async () => {
        const {
            transferNbr,
            status,
            reference,
            carrierName,
            source,
            destination,
            selectedArrivalDate,
            selectedShipDate,
            shipTemp,
            receiveTemp,
            truckId
        } = this.state;
        const { editTransfer } = this.props;
        let token = this.props.authState.accessToken;
        let headerObj = {
            TransferNbr: editTransfer ? transferNbr : null,
            ReferenceNbr: reference,
            CarrierName: carrierName,
            OriginLocationID: source.Code,
            ExpectedShipDate: toISOdateString(selectedShipDate),
            TruckID: truckId ? truckId : '',
            Status: status,
            DestinationLocationID: destination.Code,
            ExpectedArrivalDate: toISOdateString(selectedArrivalDate),
            ShipPlantTemperature: shipTemp || null,
            LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
            ReceiptPlantTemperature: receiveTemp || null,
            LastChangedDateTime: new Date().toISOString(),
            TrimSeasonID: getTrimSeasonId().Id,
            TrimSeasonName: getTrimSeasonId().TrimSeason
        };
        if (!editTransfer) {
            headerObj.CreatedBy = DuAuthenticationUtilities.GetUserId(token);
            headerObj.CreatedDateTime = new Date().toISOString();
        }
        return headerObj;
    };

    createTransferHeader = async () => {
        const { selectedShipDate, selectedArrivalDate } = this.state;
        const { editTransfer } = this.props;

        if (DuDateUtilities.ToIsoDate(selectedShipDate) > DuDateUtilities.ToIsoDate(selectedArrivalDate)) {
            this.props.notify(`Ship Date cannot be more than Arrival Date`, true);
        } else {
            try {
                let token = this.props.authState.accessToken;
                let header = await this.getHeaderData();
                //API call to save the header details
                let response = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                    TransferHeader: header,
                    TransferLines: []
                });
                if (isSuccessful(response.Message)) {
                    header.TransferNbr = response.TransferNbr;
                    this.props.setTransferHeader(header);
                    this.props.setFields({
                        label: 'ID',
                        value: 'Qty',
                        newEditableField: 'OrderQty'
                    });
                    if (editTransfer && this.props.close) {
                        this.props.close();
                    } else {
                        this.props.redirect(`${header.TransferNbr}`);
                    }

                    this.props.notify(`Transfer Header ${editTransfer ? 'Updated' : `${header.TransferNbr} Created`} Successfully`, false);
                } else {
                    this.props.notify(`Failed to ${editTransfer ? 'Update' : 'Create'} Transfer Header `, true);
                }
            } catch (err) {
                console.error(err);
                this.props.notify(`Failed to ${editTransfer ? 'Update' : 'Create'} Transfer Header `, true);
            }
        }
    };

    handleTemperature = (e, fieldName) => {
        const value = e.target.value;

        if (validateTemperature(value)) {
            this.setState({ [fieldName]: value });
        } else {
            e.preventDefault();
        }
    };

    isHeaderValid = () =>
        this.state.destination &&
        isDateValid(this.state.selectedShipDate) &&
        isDateValid(this.state.selectedArrivalDate) &&
        this.state.shipTemp !== '-' &&
        this.state.receiveTemp !== '-';

    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }

        const { classes, editTransfer } = this.props;
        const { transferNbr } = this.state;

        return (
            <DrcPanel style={{ border: '0px', padding: '0px', height: '100%' }}>
                {!editTransfer ? (
                    <Typography variant="h6" gutterBottom className={classes.heading}>
                        New Transfer
                    </Typography>
                ) : null}
                <div className={classes.flexContainer}>
                    {editTransfer && transferNbr ? (
                        <DrcInput label={'Transfer Nbr'} value={transferNbr} disabled className={classes.spaceContainer} />
                    ) : null}
                    <DrcInput label={'Ref No'} value={this.state.reference} onChange={this.handleInput('reference')} inputProps={{ maxLength: 50 }} />
                </div>
                <div className={classes.flexContainer}>
                    <DrcInput
                        label={'Carrier Name'}
                        value={this.state.carrierName}
                        onChange={this.handleInput('carrierName')}
                        className={classes.spaceContainer}
                        inputProps={{ maxLength: 75 }}
                    />
                    <DrcInput label={'Truck ID'} value={this.state.truckId} onChange={this.handleInput('truckId')} inputProps={{ maxLength: 15 }} />
                </div>

                <DrcInput label={'Source'} onChange={this.handleInput('source')} value={this.state.source.label} disabled={true} />
                <DrcSelect
                    label={'Destination'}
                    name={'destination'}
                    onChange={this.handleSelectChange}
                    options={this.state.destinationList}
                    value={this.state.destination}
                    className={null}
                    true
                ></DrcSelect>

                <div className={classes.flexContainer}>
                    <DrcDatePicker
                        clearable={false}
                        value={this.state.selectedShipDate || new Date()}
                        label={'Ship Date'}
                        onChange={(date) => {
                            this.handleDateChange(addTimeStampToDate(date), 'shipDate');
                        }}
                        className={classes.spaceContainer}
                        // disablePast
                    ></DrcDatePicker>
                    <DrcDatePicker
                        clearable={false}
                        value={this.state.selectedArrivalDate}
                        label={'Arrival Date'}
                        onChange={(date) => {
                            this.handleDateChange(addTimeStampToDate(date), 'arrivalDate');
                        }}
                        className={null}
                        minDate={this.state.selectedShipDate}
                        // disablePast
                    ></DrcDatePicker>
                </div>

                <div className={classes.flexContainer}>
                    <DrcInput
                        label={'Ship Temp'}
                        value={this.state.shipTemp}
                        onChange={(e) => this.handleTemperature(e, 'shipTemp')}
                        className={classes.spaceContainer}
                        inputProps={{ maxLength: 7 }}
                    />
                    <DrcInput
                        label={'Received Temp'}
                        value={this.state.receiveTemp}
                        onChange={(e) => this.handleTemperature(e, 'receiveTemp')}
                        inputProps={{ maxLength: 7 }}
                    />
                </div>

                <div className={`${classes.flexContainer} ${classes.buttonContainer}`}>
                    <DrcButton className={null} onClick={this.props.close} isSecondary={true}>
                        Cancel
                    </DrcButton>
                    <DrcButton className={null} onClick={this.createTransferHeader} isPrimary={true} disabled={!this.isHeaderValid()}>
                        {editTransfer ? 'Update' : 'Create'}
                    </DrcButton>
                </div>
            </DrcPanel>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setTransferInventory: (data) => dispatch(setTransferInventory(data)),
    setTransferHeader: (dataObj) => dispatch(setTransferHeader(dataObj)),
    setFields: (dataObj) => dispatch(setFields(dataObj)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ rootReducer, masterReducer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        locationsList: masterReducer.locationsList
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransferNew)));
