import React from 'react';
import { ReactComponent as TransferIcon } from '../assets/Transfer.svg';
import { ReactComponent as IssuanceIcon } from '../assets/Issuance.svg';
import { ReactComponent as DumpIcon } from '../assets/Dump.svg';
import { ReactComponent as ReturnIcon } from '../assets/Return.svg';
import { ReactComponent as GrowerStorageIcon } from '../assets/GrowerStorage.svg';
import { ReactComponent as PrintIcon } from '../assets/Printer.svg';
import { ReactComponent as AdjustTrim } from '../assets/Trim.svg';
import { ReactComponent as ReleaseIcon } from '../assets/Releases.svg';
import { ReactComponent as LogOutIcon } from '../assets/Logout.svg';
import DownloadIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super') || g.toLowerCase().includes('app'));

const LINKS = [
    {
        title: 'Adjust Trim',
        url: '/AdjustTrim/',
        icon: <AdjustTrim style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: true,
        securitykey: 'ADJUSTINVENTORYKEY'
    },
    {
        title: 'Transfer',
        url: '/Transfer/',
        icon: <TransferIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: true,
        securitykey: 'TRANSFERLOGADMINKEY'
    },
    {
        title: 'Issuance',
        url: '/Issuance/',
        icon: <IssuanceIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: true,
        securitykey: 'ISSUANCEADMINKEY'
    },
    {
        title: 'Return',
        url: '/Return/',
        icon: <ReturnIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: true,
        securitykey: 'RETURNADMINKEY'
    },
    { title: 'Dump', url: '/Dump/', icon: <DumpIcon style={{ width: '18px', height: '18px' }} />, showInMenuBar: true, securitykey: 'DUMPHOMEKEY' },
    {
        title: 'Grower Storage',
        url: '/GrowerStorage/',
        icon: <GrowerStorageIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: false,
        securitykey: 'GROWERSTORAGEHOMEKEY'
    },
    { separator: true },
    { title: 'Print', url: '/Print/', icon: <PrintIcon style={{ width: '18px', height: '18px' }} />, showInMenuBar: false, securitykey: false },
    { separator: true },
    {
        title: 'Downloads',
        url: '/Downloads/',
        icon: <DownloadIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: false,
        securitykey: false
    },
    { separator: true },
    {
        title: 'Releases',
        url: '/Releases/',
        icon: <ReleaseIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: false,
        requiresGroups: superAdminGroups,
        securitykey: false
    },
    { separator: true },
    {
        title: `Version ${process.env.REACT_APP_VERSION}`,
        icon: <InfoIcon style={{ width: '18px', height: '18px' }} />,
        showInMenuBar: false,
        securitykey: false
    },
    { title: 'LogOut', url: '/LogOut/', icon: <LogOutIcon style={{ width: '18px', height: '18px' }} />, showInMenuBar: false, securitykey: false }
];

export default LINKS;
