import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcDialog, DrcButton } from '@driscollsinc/driscolls-react-components';

const styles = (theme) => ({
    root: {
        minWidth: '0',
        fontSize: '1rem'
    },
    title: {
        fontSize: '1rem',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 0, 1),
        color: theme.light.accent.primary,
        '& h2': {
            fontSize: '1rem'
        },
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    actionWrapper: {
        margin: '0'
    }
});

class ConfirmDialog extends Component {
    render() {
        const { open, message, classes } = this.props;
        return (
            <DrcDialog
                classes={classes}
                title={<>{message ? message : 'Are you sure you want to proceed?'}</>}
                open={open}
                buttons={
                    <>
                        <DrcButton onClick={this.props.closeConfirmDialog}>No</DrcButton>
                        <DrcButton isPrimary onClick={this.props.submitRequest}>
                            Yes
                        </DrcButton>
                    </>
                }
            ></DrcDialog>
        );
    }
}

export default withStyles(styles)(ConfirmDialog);
