import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('IssuanceHeader', 'IssuanceHeader'),
    Middleware.CreateMapping('InventoryLocation', 'InventoryLocation'),
    Middleware.CreateMapping('ID', 'ID'),
    Middleware.CreateArrayMapping('InventoryLines', 'InventoryLines'),
    Middleware.CreateMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Issuance', 'GET')) {
        Middleware.RegisterMapping('Issuance', 'GET', getListMapping);
        Middleware.RegisterMapping('Issuance', 'POST', getListMapping);
        Middleware.RegisterMapping('Issuance', 'DELETE', getListMapping);
    }
}

const IssuanceMapping = {
    ConfigureMiddleware
};

export default IssuanceMapping;
