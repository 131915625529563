import React from 'react';
import { DrcInput, DrcSelect, DrcIcons } from '@driscollsinc/driscolls-react-components';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Store } from '../data/store';
import { uiFieldMap, getColumnsOrder } from '../data/constants';
import { startCase, isEqual } from 'lodash';

const getStoreData = (reducer) => Store.getState()[reducer] || {};

export const getLocalTrimAndLocation = () => {
    return JSON.parse(localStorage.getItem('trimSeasonWarehouse'));
};

export const setLocalTrimAndLocation = (trimSeason, location) => {
    localStorage.setItem('trimSeasonWarehouse', JSON.stringify({ trimSeason, location }));
};

export const getEditableFieldType = (fieldArr = []) => {
    const fieldType = fieldArr.map((field) => {
        let type = '';
        switch (field) {
            case 'Qty':
            case 'OrderQty':
                type = 'text';
                break;
            case 'ReasonCode':
                type = 'select';
                break;
            default:
                type = 'text';
                break;
        }
        return { label: field, type: type };
    });
    return fieldType;
};

export const getFormatter = (formatter) => {
    switch (formatter) {
        case 'date':
            return (date) => DuDateUtilities.FormatDateFromIso(date);
        case 'berryIcon':
            return (berryName) => DrcIcons.GetBerryIcon(berryName);
        default:
            return (data) => data;
    }
};
//Columns to be passed to Accordion to make a column editable programmatically
export const makeColumns = (
    row = {},
    editableField = [],
    onEdit = () => {
        // write code
    },
    getValue = (val) => val,
    onEditDropdown = () => {
        // write code
    },
    selectionList = [],
    dropdownSelected = { label: '', value: '' }
) => {
    const inputField = React.createRef();
    return Object.keys(row).map((field) => {
        const labelObj = editableField.find((obj) => {
            return obj.label === field;
        });

        return {
            key: field,
            editType: labelObj ? labelObj.editType : 'text',
            // ref: inputField,
            component:
                labelObj && labelObj.editType === 'select'
                    ? (row, value, selectObj = {}) => (
                          <DrcSelect
                              label={field}
                              onChange={onEditDropdown(row, value)}
                              value={dropdownSelected.label === '' ? selectObj : dropdownSelected}
                              options={selectionList}
                              required={true}
                          />
                      )
                    : (row, value) => {
                          setTimeout(() => {
                              if (inputField.current && inputField.current.focus) inputField.current.focus();
                          }, 1000);

                          return row.defaultValue ? (
                              <DrcInput
                                  InputProps={{
                                      inputProps: {
                                          min: 0,
                                          maxlength: 9
                                      }
                                  }}
                                  onChange={onEdit(row, value)}
                                  placeholder={value}
                                  defaultValue={row.defaultValue}
                                  autoFocus
                                  inputRef={inputField}
                              />
                          ) : (
                              <DrcInput
                                  InputProps={{
                                      inputProps: {
                                          min: 0,
                                          maxlength: 9
                                      }
                                  }}
                                  onChange={onEdit(row, value)}
                                  placeholder={value}
                                  value={getValue(value)}
                                  autoFocus
                                  inputRef={inputField}
                              />
                          );
                      },

            editable: editableField
                .map((obj) => {
                    return obj.label;
                })
                .includes(field)
        };
    });
};
//Used to add extra editable column in data and also add Qty field in the end of the data list
export const addEditableColumn = (data, newColName, qtyColName, type) =>
    data.map((row) => {
        let newRow = {};
        let originalQty = null;

        if (type) {
            let rowKeys = Object.keys(row);
            const columns = getColumnsOrder(type);
            columns.forEach((field) => {
                if (rowKeys.includes(field)) {
                    if (qtyColName && qtyColName === field) {
                        originalQty = row[field];
                    } else {
                        newRow[field] = row[field];
                    }
                }
            });
        } else {
            Object.keys(row).forEach((field) => {
                if (qtyColName && qtyColName === field) {
                    originalQty = row[field];
                } else {
                    newRow[field] = row[field];
                }
            });
        }
        newRow = { ...newRow, ...row };
        if (originalQty !== null) {
            newRow[qtyColName] = originalQty;
        }

        if (newColName !== '') {
            newRow[newColName] = originalQty;
        }
        return newRow;
    });

export const filterData = (data, filterValue) => {
    data.filter((row) => row['Barcode ID'] === filterValue);
};

export const getStoredTrim = (value) => {
    return (getLocalTrimAndLocation() || {}).trimSeason;
};

export const getStoredLocation = () => {
    return (getLocalTrimAndLocation() || {}).location || {};
};

export const getTrimSeasonId = (name) => {
    let searchTerm = name || getStoredTrim();
    let trimSeasons = getStoreData('masterReducer').trimSeasons || [];
    let found = trimSeasons.find((season) => season.TrimSeason === searchTerm);
    return found;
};

export const searchLineItem = (searchObj, data) => data.find((line) => '' + line[searchObj['key']] === searchObj['value']);

export const makeOption = (data, label, value, addAll = false) => {
    const formattedArr = data.map((itm) => ({ label: itm[label], value: itm[value], ...itm }));
    if (addAll) {
        formattedArr.unshift({ label: 'ALL', value: 'ALL' });
    }
    return formattedArr;
};
export const makeLocationOption = (data, label, value, addAll = false) => {
    const formattedArr = data.map((itm) => ({ label: `${itm[label]}-${itm[value]}`, value: itm[value], ...itm }));
    if (addAll) {
        formattedArr.unshift({ label: 'ALL', value: 'ALL' });
    }
    return formattedArr;
};

export const padToFour = (number) => (number <= 9999 ? `000${number}`.slice(-4) : number);

export const formatData = (key, value) => {
    let varietyCodeMap = JSON.parse(localStorage.getItem('varietyMap'));
    let locationMap = getStoreData('masterReducer').locationMap;
    let dateColumns = [
        'IssueDate',
        'LastChangedDateTime',
        'CreatedDateTime',
        'ExpectedArrivalDate',
        'ExpectedShipDate',
        'AuthorizedDate',
        'ReturnedDate'
    ];
    if (dateColumns.includes(key)) {
        let formatter = getFormatter('date');
        return formatter(value);
    } else if (key === 'Commodity') {
        let formatter = getFormatter('berryIcon');
        return formatter(value);
    } else if (key === 'VarietyCode') {
        return varietyCodeMap[value] || value;
    } else if (key === 'InventoryLocation') {
        return `${value} - ${locationMap[value]}`;
    } else if (key === 'defaultValue') {
        return null;
    }
    return value;
};

export const formatKey = (key, row) => {
    if (key === 'defaultValue') return null;
    return uiFieldMap[key] || startCase(key);
};

export const setColumnOrder = (data, type) => {
    return data.map((row) => {
        let newRow = {};
        let rowKeys = Object.keys(row);
        const columns = getColumnsOrder(type);
        columns.forEach((field) => {
            if (rowKeys.includes(field)) {
                newRow[field] = row[field];
            }
        });
        return { ...newRow, ...row };
    });
};

export const checkPermission = (securityKey, userAccesses) => {
    return userAccesses.includes(securityKey);
};

export const getGrowerDistrict = (entityName) => {
    let growers = getStoreData('masterReducer').growerEntities || [];
    return growers.find((itm) => itm.GrowerEntity === entityName) || {};
};

export const getGrowerEntities = (growerEntities) => {
    let trimSeason = getStoredTrim();
    let berry = getStoreData('masterReducer').trimSeasonMap[trimSeason];
    let growers = growerEntities || getStoreData('masterReducer').growerEntities || [];
    return growers.filter((itm) => itm.Commodity === berry) || {};
};

export const toISOdateString = (date) => {
    let localDate = new Date(Date.parse(date));
    return localDate.toISOString();
};

export const isDateValid = (dateObj) => {
    return dateObj !== null && dateObj instanceof Date && !isNaN(dateObj);
};
export const addTimeStampToDate = (dateObj) => {
    //DrcDatePicker does not return correct time
    if (isDateValid(dateObj)) {
        let date = dateObj.toISOString().split('T')[0];
        let currentTime = new Date().toISOString().split('T')[1];
        let correctIsoDate = date + 'T' + currentTime;
        return new Date(correctIsoDate);
    } else {
        return dateObj;
    }
};

export const isWholeNumber = (value) => {
    return !isNaN(value) && +value >= 0 && !JSON.stringify(value).includes('.') && !JSON.stringify(value).includes(' '); //!Number.isInteger(parseFloat(value));
};

export const isNumber = (n, tempCheck = false) => {
    let isNum = !isNaN(parseFloat(n)) && isFinite(n);
    return tempCheck && isNum ? isTemperature(n) : isNum;
};

export const isTemperature = (n) => {
    let regExp = /^([-,+])?[0-9]{1,3}(\.[0-9]{0,2})?$/g;
    return regExp.test(parseFloat(n));
};

export const validateTemperature = (n) => !n || isNumber(n, true) || n === '-';

export const isSuccessful = (message) => {
    return message.toLowerCase().includes('processed successfully');
};

export const getDefaultPrintServer = (printServerList, location, printFormat = 'PDF') => {
    const printServer = (printServerList || []).find((item) => item.PrintLocation === location && item.PrintFormat === printFormat);
    return printServer;
};

export const getLocalPrintServers = () => {
    return JSON.parse(localStorage.getItem('printServers'));
};

export const setLocalPrintServers = (printServers) => {
    localStorage.setItem('printServers', JSON.stringify({ printServers }));
};

export const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
};

export const hasEmptyInventory = (inventoryLines) => {
    return (inventoryLines || []).some((el) => el.Qty === 0);
};

export const isIdentical = (a, b) => {
    return isEqual(a, b);
};

export const getGrowerDumpLineIndex = (barcodeId, growerId) => {
    const growerPrefix = '#GR';
    return `${barcodeId}-${growerPrefix}${growerId || ''}`;
};

export const getGrowerStorageLineIndex = (barcodeId, storageLocation) => {
    return `${barcodeId}-${storageLocation || ''}`;
};

export const getGrowerId = (storageLocation) => {
    return storageLocation.replace('#GR', '');
};
