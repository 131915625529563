import React, { Component } from 'react';
import { DrcMain, DrcPanel, DrcButton } from '@driscollsinc/driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../../services/api';
import Divider from '@material-ui/core/Divider';
import TransferInventoryLines from './TransferInventoryLines';
import { setTagNbr, setBarcodeId } from '../../actions/barcodeSearchAction';
import { notify } from '../../actions/NotificationAction';
import HeaderPanel from '../../components/HeaderPanel';
import ConfirmDialog from '../../components/ConfirmDialog';
import { setTransferInventory, setTransferHeader, updateInventoryStatus } from '../../actions/Transfer';
import { setToNavBack } from '../../actions/MasterActions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { getStoredTrim, getTrimSeasonId, formatKey, isSuccessful } from '../../utils/helper';
import { cloneDeep } from 'lodash';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const styles = (theme) => ({
    root: {
        minWidth: '250px'
    },
    panel: {
        border: 0,
        marginBottom: '9vh'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelBtn: {
        color: theme.palette.error.light,
        // fill: theme.palette.error.light,
        border: '1px solid #c54',
        margin: '0 1em'
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            // width: '24vw',
            flex: '1',
            padding: '0 0.2rem'
            // flexBasis: '20%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    }
});

class ViewTransfer extends Component {
    state = {
        inventoryLines: [],
        openConfirmDialog: false,
        cancelTransfer: false,
        editedRow: {},
        updatedQty: null,
        pageType: ''
    };
    async componentDidMount() {
        this.setState({
            pageType: this.props.location.pathname.includes('Receive') ? 'Receive' : 'Ship'
        });
        try {
            if (this.props.tagNbr !== parseInt(this.props.match.params.TransferId)) {
                let token = this.props.authState.accessToken;
                let tagNbr = this.props.match.params.TransferId;
                let responseData = await Middleware.Send(
                    'TransferInventory',
                    token,
                    APIEndPoints.TRANSFER_INVENTORY(tagNbr, getTrimSeasonId().Id),
                    'GET',
                    {}
                );
                let inventoryLines = responseData.TransferLines;
                let transferDataObj = responseData.TransferHeader;

                this.props.setTransferInventory(inventoryLines);
                this.props.setTransferHeader(transferDataObj);
            }
        } catch (err) {
            console.log(err);
        }
    }

    setInventoryData = async (row, newQty) => {
        const { transferHeader } = this.props;
        const { pageType } = this.state;
        try {
            let inventoryLines = cloneDeep(this.props.inventoryLines).filter((line) => line.BarcodeID !== row.BarcodeID);
            if (row.PickQty) delete row.PickQty;
            if (row.ReceiveQty) delete row.ReceiveQty;
            row.UpdateQty = parseInt(newQty);
            row.Status = pageType === 'Receive' ? 'RECEIVED' : 'PICKED';
            transferHeader.TrimSeasonName = getStoredTrim();

            let token = this.props.authState.accessToken;
            let response = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                TransferHeader: transferHeader,
                TransferLines: [row]
            });

            if (isSuccessful(response.Message)) {
                if (response.TransferLines[0].Message === 'Insufficient quantity') {
                    this.props.notify('Insufficient quantity. Please enter New Amount', true);
                    this.props.setTransferInventory(this.props.inventoryLines);
                    this.props.setBarcodeId(row.BarcodeID);
                } else if (
                    response.TransferLines[0].Message.includes('PICKED successfully') ||
                    response.TransferLines[0].Message.includes('RECEIVED successfully')
                ) {
                    inventoryLines.push(row);
                    let tagNbr = this.props.match.params.TransferId;
                    let responseData = await Middleware.Send(
                        'TransferInventory',
                        token,
                        APIEndPoints.TRANSFER_INVENTORY(tagNbr, getTrimSeasonId().Id),
                        'GET',
                        {}
                    );
                    let updatedInventoryLines = responseData.TransferLines;
                    this.props.setTransferInventory(updatedInventoryLines);
                    this.props.setBarcodeId('');
                } else {
                    this.props.setTransferInventory(inventoryLines);
                    this.props.setBarcodeId('');
                }
            }
        } catch (err) {
            console.error(err);
            this.props.setBarcodeId('');
            this.props.notify('Failed to Pick Inventory', true);
        }
    };

    updateInventoryData = (row, newQty) => {
        if (row.ReceivedQty !== parseInt(newQty)) {
            this.setState({
                openConfirmDialog: true,
                message: `${this.state.pageType} Qty from ${row.Qty} to ${newQty}?`,
                editedRow: row,
                updatedQty: newQty
            });
        } else {
            this.setInventoryData(row, newQty);
        }
    };

    handleAction = async (actionType = '') => {
        const { pageType } = this.state;
        const { transferHeader, inventoryLines } = this.props;
        try {
            let updatedInventoryLines = cloneDeep(inventoryLines).map((line) => {
                line.Status = actionType === 'cancel' ? 'CANCELLED' : pageType === 'Receive' ? 'RECEIVED' : 'SHIPPED';
                if (!line.UpdateQty) {
                    line.UpdateQty = line.Qty;
                }
                return line;
            });
            transferHeader.Status = actionType === 'cancel' ? 'CANCELLED' : pageType === 'Receive' ? 'RECEIVED' : 'SHIPPED';
            transferHeader.TrimSeasonName = getStoredTrim();
            transferHeader.LastChangedDateTime = new Date().toISOString();
            transferHeader.ExpectedArrivalDate =
                pageType === 'Receive' ? transferHeader.ExpectedArrivalDate || new Date().toISOString() : transferHeader.ExpectedArrivalDate;
            let token = this.props.authState.accessToken;
            transferHeader.LastChangedBy = DuAuthenticationUtilities.GetUserId(token);
            let response = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                TransferHeader: transferHeader,
                TransferLines: updatedInventoryLines
            });

            if (isSuccessful(response.Message)) {
                if (response.Message.includes('CANCELLED')) {
                    this.props.notify('Transfer Cancelled Successfully', false);
                    this.props.setBarcodeId('');
                    this.props.history.push('/Transfer/Open');
                } else if (response.Message.includes('RECEIVED')) {
                    this.props.notify('Transfer Received Successfully', false);
                    this.props.setBarcodeId('');
                    this.props.history.push('/Transfer/ReceivingList');
                } else if (response.TransferLines[0].Message.includes('SHIPPED')) {
                    this.props.notify('Transfer Shipped Successfully', false);
                    this.props.setBarcodeId('');
                    this.props.history.push('/Transfer/Open');
                } else if (response.TransferLines[0].Message === 'Insufficient quantity') {
                    this.props.notify('Insufficient quantity. Please enter New Amount', true);
                } else {
                    this.props.setTransferInventory(inventoryLines);
                    this.props.setBarcodeId('');
                }
            }
        } catch (err) {
            console.error(err);
            this.props.notify('Failed to Pick Inventory', true);
        }
    };

    isActionEnabled = () => {
        const { pageType } = this.state;
        const { transferHeader, inventoryLines } = this.props;
        if (inventoryLines.length > 0) {
            switch (pageType) {
                case 'Ship':
                    return (
                        inventoryLines.length === inventoryLines.filter((line) => line.Status === 'PICKED').length &&
                        transferHeader.Status === 'RESERVED'
                    );
                case 'Receive':
                    return (
                        inventoryLines.length === inventoryLines.filter((line) => line.Status === 'RECEIVED').length &&
                        transferHeader.Status === 'SHIPPED'
                    );
                default:
                    return false;
            }
        }
        return false;
    };

    isPickEnabled = () => {
        const { pageType } = this.state;
        const { transferHeader, inventoryLines } = this.props;
        if (inventoryLines.length > 0) {
            switch (pageType) {
                case 'Ship':
                    return !(transferHeader.Status === 'SHIPPED' || transferHeader.Status === 'CANCELLED' || transferHeader.Status === 'RECEIVED');
                case 'Receive':
                    return !(transferHeader.Status === 'RECEIVED');
                default:
                    return true;
            }
        }
        return true;
    };

    confirmAction = () => {
        if (this.state.cancelTransfer) {
            this.handleAction('cancel');
        } else {
            this.setInventoryData({ ...this.state.editedRow, ReceivedQty: this.state.updatedQty }, this.state.updatedQty);
        }
    };

    getHeaderPanel = (value, dataObj) => {
        const { classes, locationMap } = this.props;
        let locationId = this.state.pageType === 'Receive' ? 'OriginLocationID' : 'DestinationLocationID';
        let labelValue = `${dataObj[locationId]} - ${locationMap[dataObj[locationId]]}`;

        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('TransferNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['TransferNbr']}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('ReferenceNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['ReferenceNbr']}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>{formatKey(locationId)}</span>
                    <span className={classes.labelValue}>{labelValue}</span>
                </div>
            </>
        );
    };

    render() {
        this.props.setToNavBack(true);
        const { pageType } = this.state;
        const { classes, transferHeader, isMasterDataInitialized } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        const isCancelEnabled = pageType === 'Ship' && (transferHeader.Status === 'OPEN' || transferHeader.Status === 'RESERVED');
        return (
            <DrcMain transparent handHeld>
                <DrcPanel className={classes.panel}>
                    <>
                        <HeaderPanel
                            data={[transferHeader]}
                            headerName={`Transfer`}
                            columnType={'transferDetailsHeader'}
                            headerFormatter={this.getHeaderPanel}
                            action={this.isActionEnabled() ? pageType : ''}
                            // cancel={isCancelEnabled}
                            onSelectAction={(type) => {
                                this.handleAction(type);
                            }}
                        />
                        <Divider />
                        <TransferInventoryLines
                            defaultEdit={true}
                            pageType={pageType}
                            isPickEnabled={this.isPickEnabled()}
                            updateInventoryData={this.updateInventoryData}
                        />

                        {isCancelEnabled ? (
                            <div className={classes.button}>
                                <DrcButton
                                    onClick={() => {
                                        this.setState({
                                            openConfirmDialog: true,
                                            cancelTransfer: true,
                                            message: 'Are you sure you want to cancel transfer?'
                                        });
                                    }}
                                    className={classes.cancelBtn}
                                    isSecondary
                                >
                                    Cancel Transfer
                                </DrcButton>
                            </div>
                        ) : null}

                        <ConfirmDialog
                            message={this.state.message}
                            open={this.state.openConfirmDialog}
                            closeConfirmDialog={() => {
                                this.setState({ openConfirmDialog: false });
                            }}
                            submitRequest={() => {
                                this.setState({ openConfirmDialog: false });
                                this.confirmAction();
                            }}
                        />
                    </>
                </DrcPanel>
            </DrcMain>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setTransferInventory: (data) => dispatch(setTransferInventory(data)),
    setTransferHeader: (dataObj) => dispatch(setTransferHeader(dataObj)),
    setToNavBack: (data) => dispatch(setToNavBack(data)),
    updateInventoryStatus: (data) => dispatch(updateInventoryStatus(data)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ masterReducer, barcodeSearchReducer, transfer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        tagNbr: barcodeSearchReducer.tagNbr,
        transferHeader: transfer.transferHeader,
        inventoryStatus: transfer.inventoryStatus,
        inventoryLines: transfer.inventoryLines,
        locationMap: masterReducer.locationMap
    };
}

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewTransfer)));
