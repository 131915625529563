import React, { Component } from 'react';
import { DrcButton, DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import Divider from '@material-ui/core/Divider';
import IssuanceHeader from './issuanceHeader';
import InventoryLines from '../../components/InventoryLines';
import { setIssuanceHeader, setIssuanceInventory } from '../../actions/Issuance';
import { connect } from 'react-redux';
import { setToNavBack } from '../../actions/MasterActions';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import { ReactComponent as StatusIndicator } from '../../assets/StatusIndicator.svg';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { formatData, formatKey, getGrowerDistrict, getStoredTrim, getTrimSeasonId, isSuccessful, getStoredLocation } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import ConfirmDialog from '../../components/ConfirmDialog';
import { getExcludedColumns, STATUS } from '../../data/constants';
import ZeroQtyDialog from '../../components/ZeroQtyDialog';
const styles = (theme) => ({
    root: {
        minWidth: '250px'
    },
    container: {
        margin: '48px 0 60px'
    },
    panel: {
        border: 0,
        marginBottom: '9vh'
    },
    headerLabel: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px !important'
    },
    labelStyle: {
        color: theme.palette.primary.light,
        fontWeight: 'bold'
    },
    labelIndicator: {
        fill: theme.palette.primary.light,
        marginLeft: '1em'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelBtn: {
        color: theme.palette.error.light,
        border: '1px solid #c54',
        display: 'flex',
        margin: '0 auto !important;'
    }
});

class ViewIssuance extends Component {
    state = {
        editedRow: {},
        showConfirmation: false,
        pickQty: null,
        storageType: this.props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY',
        isGrowerStorage: this.props.isGrowerStorageNavigation
    };

    async componentDidMount() {
        try {
            if (getTrimSeasonId()) {
                this.props.setToNavBack(true);
                if (!Object.keys(this.props.header).length) {
                    let tagNbr = this.props.match.params.IssuanceId;
                    let token = this.props.authState.accessToken;
                    let response = await Middleware.Send(
                        'Issuance',
                        token,
                        APIEndPoints.ISSUANCE_DETAIL(tagNbr, getTrimSeasonId().Id, this.state.storageType),
                        'GET',
                        {}
                    );
                    let inventoryLines = Array.from(response.InventoryLines);
                    let headerData = response.IssuanceHeader;
                    this.props.setIssuanceHeader(headerData);
                    this.props.setIssuanceInventory(inventoryLines);
                }
            }
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Issuance Details', true);
        }
    }
    getHeaderLabel = (value, row) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.headerLabel}>
                    <span className={classes.labelStyle}>
                        {row.BarcodeID}
                        {row.Status === 'PICKED' && (
                            <span className={classes.labelIndicator}>
                                <StatusIndicator style={{ width: '1em', height: '1em' }} />
                            </span>
                        )}
                    </span>
                    <span>{row.Qty}</span>
                </div>
            </>
        );
    };

    confirmEdit = (row, qty) => {
        if (qty !== undefined && +qty === 0) {
            this.setState({ showZeroQty: true });
        } else if (qty === undefined || row.Qty === +qty) {
            this.handleEditSave(row, qty);
        } else {
            this.setState({ showConfirmation: true, editedRow: row, pickQty: +qty });
        }
    };

    submitRequest = () => {
        this.state.cancelIssuance ? this.handleShip('CANCELLED') : this.handleEditSave(this.state.editedRow, this.state.pickQty);
        this.setState({ showConfirmation: false, cancelIssuance: false });
    };

    handleEditSave = async (row, qty) => {
        try {
            let inventoryLines = cloneDeep(this.props.inventoryLines).filter((line) => line.BarcodeID !== row.BarcodeID);
            row.Status = 'PICKED';
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send('Issuance', token, APIEndPoints.CREATE_ISSUANCE, 'POST', {
                IssuanceHeader: {
                    ...this.props.header,
                    TrimSeasonName: getStoredTrim(),
                    InventoryLocation: getStoredLocation().Code,
                    ...{
                        ...(this.props.header.IssueToType === 'Grower'
                            ? {
                                  GrowingDistrict: getGrowerDistrict(this.props.header.EntityName).GrowingDistrict,
                                  VendorInvoiceAccNum: getGrowerDistrict(this.props.header.EntityName).VendorInvoiceAccNum
                              }
                            : '')
                    }
                },
                InventoryLines: [{ ...row, Qty: row.Qty, UpdateQty: qty }]
            });
            if (response.InventoryLines[0].Message === 'Inventory Line PICKED successfully') {
                row.Qty = qty;
                row.UpdateQty = qty;
                inventoryLines.push(row);
                this.props.setIssuanceInventory(inventoryLines);
            } else {
                row.PickQty = row.Qty;
                return this.props.notify(response.InventoryLines[0].Message, true);
            }
        } catch (err) {
            console.error(err);
            this.props.notify('Failed to Pick Inventory', true);
        }
    };

    isPickEnabled = () => {
        const { header, inventoryLines } = this.props;
        if (inventoryLines.length > 0) {
            return !(header.Status === 'SHIPPED' || header.Status === 'CANCELLED');
        }
    };

    isShipEnabled = () => !!this.props.inventoryLines.filter((line) => line.Status !== 'PICKED').length;

    handleShip = async (status) => {
        try {
            let token = this.props.authState.accessToken;
            let header = {
                ...this.props.header,
                InventoryLocation: getStoredLocation().Code,
                LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                LastChangedDateTime: new Date().toISOString(),
                Status: status,
                TrimSeasonName: getStoredTrim(),
                ...{
                    ...(this.props.header.IssueToType === 'Grower'
                        ? {
                              GrowingDistrict: getGrowerDistrict(this.props.header.EntityName).GrowingDistrict,
                              VendorInvoiceAccNum: getGrowerDistrict(this.props.header.EntityName).VendorInvoiceAccNum
                          }
                        : '')
                }
            };
            let inventoryLines = cloneDeep(this.props.inventoryLines).map((line) => {
                line.Status = status;
                line.UpdateQty = line.Qty;
                return line;
            });
            let response = await Middleware.Send('Issuance', token, APIEndPoints.CREATE_ISSUANCE, 'POST', {
                IssuanceHeader: header,
                InventoryLines: inventoryLines
            });
            if (isSuccessful(response.Message) || response.InventoryLines[0].Message === 'Inventory Line SHIPPED successfully') {
                this.props.notify(`Issuance ${status}`, false);
                return this.props.isGrowerStorageNavigation
                    ? this.props.history.push('/GrowerStorage/Issuance/')
                    : this.props.history.push('/Issuance/');
            } else {
                this.props.notify(response.InventoryLines[0].Message, true);
            }
        } catch (err) {
            console.error(err);
            this.props.notify(`Failed to ${status === 'SHIPPED' ? 'Ship' : 'Cancel'} Issuance`, true);
        }
    };
    render() {
        const { barcodeId, classes, isMasterDataInitialized, inventoryLines } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcMain transparent handHeld>
                <DrcPanel className={classes.panel}>
                    <IssuanceHeader excludeColumns={getExcludedColumns('issuanceHeader')} dataFormatter={formatData} keyFormatter={formatKey}>
                        {!!this.props.inventoryLines.length && this.isPickEnabled() && (
                            <div className={classes.button}>
                                <DrcButton onClick={() => this.handleShip('SHIPPED')} isPrimary disabled={this.isShipEnabled()}>
                                    Ship
                                </DrcButton>
                            </div>
                        )}
                    </IssuanceHeader>
                    <Divider />
                    <InventoryLines
                        customExpand={(row = {}) => row['BarcodeID'] === barcodeId}
                        newEditableField="PickQty"
                        isPickEnabled={this.isPickEnabled()}
                        label="BarcodeID"
                        onPick={this.confirmEdit}
                        labelFormatter={this.getHeaderLabel}
                        inventoryLines={inventoryLines}
                    />
                    {[STATUS.OPEN, STATUS.RESERVED].includes(this.props.header.Status) && (
                        <DrcButton
                            isSecondary
                            className={classes.cancelBtn}
                            onClick={() => this.setState({ cancelIssuance: true, showConfirmation: true })}
                        >
                            Cancel Issuance
                        </DrcButton>
                    )}

                    <ConfirmDialog
                        message={
                            this.state.cancelIssuance
                                ? 'Are you sure you want to permanently Cancel Issuance?'
                                : `Change Pick Qty from ${this.state.editedRow.Qty} to ${this.state.pickQty}`
                        }
                        open={this.state.showConfirmation}
                        closeConfirmDialog={() => {
                            this.setState({ showConfirmation: false, row: {}, pickQty: null, cancelIssuance: false });
                        }}
                        submitRequest={this.submitRequest}
                    />
                    <ZeroQtyDialog onClose={() => this.setState({ showZeroQty: false })} showDialog={this.state.showZeroQty} />
                </DrcPanel>
            </DrcMain>
        );
    }
}
function mapStateToProps({ masterReducer, barcodeSearchReducer, issuance, growerStorage }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        barcodeId: barcodeSearchReducer.barcodeId,
        inventoryLines: issuance.inventoryLines,
        header: issuance.header,
        isGrowerStorageNavigation: growerStorage.isNavigated
    };
}
const mapDispatchToProps = (dispatch) => ({
    setIssuanceHeader: (header) => dispatch(setIssuanceHeader(header)),
    setIssuanceInventory: (data) => dispatch(setIssuanceInventory(data)),
    setToNavBack: (data) => dispatch(setToNavBack(data)),
    notify: (data, isError) => dispatch(notify(data, isError))
});
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewIssuance)));
