export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const notify = (notification, isError, timeToClose = 5000) => (dispatch) => {
    setTimeout(() => {
        dispatch(clearNotification());
    }, timeToClose);

    return dispatch({
        type: SHOW_NOTIFICATION,
        payload: { notification, isError }
    });
};

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const clearNotification = () => ({
    type: CLEAR_NOTIFICATION,
    payload: ''
});
