import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Container from '@material-ui/core/Container';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import DrcKeyValueTable from './KeyValueTable';
import OptionsIcon from './OptionsIcon';
import AccordianHeaderActions from './AccordianHeaderActions';
import { DrcInput, DrcButton } from '@driscollsinc/driscolls-react-components';

const styles = (theme) => ({
    container: {
        width: '100%',
        height: '75%',
        overflowY: 'auto'
    },
    actionLeft: {
        marginRight: '7%'
    },
    actionRight: {
        marginLeft: '5%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        flex: '3',
        // flexBasis: '75%',
        letterSpacing: '0.28px',
        alignItems: 'center',
        margin: 'auto 0'
        // flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '500',
        flexBasis: '20%',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        letterSpacing: '0.28px',

        color: theme.palette.common.black,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    key: {
        textAlign: 'right',
        wordWrap: 'break-word'
    },
    tableContainer: {
        tableLayout: 'fixed'
    },
    panelSummaryLeft: { flexDirection: 'row-reverse', marginLeft: '-12px', alignItems: 'center' },
    panelSummaryRight: { marginRight: '-12px', alignItems: 'center' },

    accordion: {
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        // width: '98%', //to hide horizontal scroll
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    icon: {
        margin: '0 0 0 -12',
        color: theme.palette.common.black,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    expanded: {
        color: 'red'
    }
});

class AccordianList extends React.Component {
    constructor(props) {
        super(props);
        this.focused = null;
        this.state = {
            expanded: false,
            rowUnderEdit: {},
            deletedRow: {}
        };
    }
    handleChange = (panel, row) => (event, isExpanded, editOnExpand) => {
        const { clearSearch } = this.props;
        let rowUnderEdit = editOnExpand || this.props.defaultEdit || isExpanded ? row : {};
        this.setState({
            expanded: isExpanded ? panel : false,
            rowUnderEdit
        });
        clearSearch && clearSearch(isExpanded, row);
    };

    getDetailsTable = (dataObj, excludeColumns, classes) => {
        const { columns = [], dataFormatter, keyFormatter } = this.props;
        let formattedDataArr = Object.keys(dataObj)
            .filter((key) => !excludeColumns.includes(key))
            .map((eachKey) => {
                const newObj = {};
                const fieldProps = columns.find((field) => field.key === eachKey) || {};
                newObj['key'] = keyFormatter ? keyFormatter(eachKey, dataObj) : eachKey;
                newObj['value'] =
                    fieldProps.editable && JSON.stringify(dataObj) === JSON.stringify(this.state.rowUnderEdit) ? (
                        typeof fieldProps.component === 'function' ? (
                            fieldProps.component(dataObj, dataObj[eachKey])
                        ) : (
                            <DrcInput value={this.state.editRow[eachKey]} onChange={() => {}} />
                        )
                    ) : dataFormatter ? (
                        dataFormatter(eachKey, dataObj[eachKey])
                    ) : (
                        dataObj[eachKey]
                    );
                return newObj;
            });
        return <DrcKeyValueTable children={formattedDataArr} />;
    };
    scrollList = (scrollRef) => {
        setTimeout(() => {
            if (scrollRef.current && !this.props.isUnderEdit) {
                window.scrollTo(0, scrollRef.current.offsetTop + scrollRef.current.scrollHeight - 60);
            }
        }, 1000);
        return true;
    };
    expandList = (isExpanded, indx, row) => {
        const { uniqueKey } = this.props;
        const uniquePanelIndex = uniqueKey ? `panel_${row[uniqueKey]}` : `panel_${indx + 1}`;

        this.focused = uniquePanelIndex;
        if (this.props.defaultEdit && isExpanded && JSON.stringify(row) !== JSON.stringify(this.state.rowUnderEdit))
            this.setState({ rowUnderEdit: row });
        return isExpanded;
    };
    onEditSelect = (row, index) => (e) => {
        const { uniqueKey } = this.props;
        const uniquePanelIndex = uniqueKey ? `panel_${row[uniqueKey]}` : `panel_${index + 1}`;

        let expandList = this.handleChange(uniquePanelIndex, row);
        expandList(e, true);
        this.setState({ rowUnderEdit: row });
    };
    onDeleteSelect = (row, index) => (e) => {
        e.stopPropagation();
        this.setState({ deletedRow: row, expanded: false });
        this.props.onDelete(row, index);
    };
    showCancelSaveAction = (row) => {
        const { children } = this.props;
        return !!children && JSON.stringify(row) === JSON.stringify(this.state.rowUnderEdit) ? true : false;
    };
    handleCancel = (row) => () => {
        this.setState({ expanded: false });
        this.props.onCancel(row);
    };
    handleSave = (row) => () => {
        this.setState({ expanded: false });
        this.props.onSave(row);
    };
    handleOnEdit = (eachObj) => (e) => {
        e.stopPropagation();
        this.setState({ expanded: false });
        this.props.onEdit(eachObj);
    };
    handleOnPrint = (eachObj) => (e) => {
        e.stopPropagation();
        this.setState({ expanded: false });
        this.props.onPrint(eachObj);
    };

    render() {
        const {
            classes,
            data = [],
            fetchMoreData = () => {},
            hasMoreData = false,
            label,
            value,
            action,
            actionOptions = [],
            checkEditable = () => '',
            checkPrintable = () => '',
            printable,
            deletable,
            labelStyle,
            labelPlaceholder,
            children,
            valuePlaceholder,
            columns,
            onLabelClick = () => {},
            customExpand = () => false,
            onEdit = () => {},
            onDelete = () => {},
            onPrint = () => {},
            component,
            labelFormatter,
            valueFormatter,
            disableAction = () => false,
            scrollable,
            expandRight,
            excludeColumns = [],
            uniqueKey,
            ...rest
        } = this.props;
        const { expanded } = this.state;
        return (
            <Container disableGutters={true} className={classes.container}>
                <InfiniteScroll
                    dataLength={data.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData}
                    // loader={data.length > 0 ? <h4>Loading...</h4> : null}
                    // endMessage={'You have reached the end'}
                    // refreshFunction={refreshData}
                    // pullDownToRefresh
                >
                    {data.map((eachObj, index) => {
                        const isExpanded = customExpand(eachObj);
                        const scrollRef = React.createRef();
                        const editable = checkEditable(eachObj);
                        const printableVal = checkPrintable(eachObj);
                        const uniquePanelIndex = uniqueKey ? `panel_${eachObj[uniqueKey]}` : `panel_${index + 1}`;
                        return (
                            <Accordion
                                className={classes.accordion}
                                key={index}
                                elevation={1}
                                ref={scrollRef}
                                expanded={expanded === uniquePanelIndex || this.expandList(isExpanded, index, eachObj)}
                                onChange={this.handleChange(uniquePanelIndex, eachObj)}
                                {...(isExpanded && scrollable && this.scrollList(scrollRef) && undefined)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${uniquePanelIndex}bh-content`}
                                    id={`${uniquePanelIndex}bh-header`}
                                    IconButtonProps={{ edge: expandRight ? 'end' : 'start' }}
                                    className={expandRight ? classes.panelSummaryRight : classes.panelSummaryLeft}
                                >
                                    <>
                                        <AccordianHeaderActions
                                            displaySide="left"
                                            deletable={deletable}
                                            editable={editable}
                                            printable={printableVal}
                                            onEdit={onEdit}
                                            onDelete={onDelete}
                                            onPrint={onPrint}
                                            row={eachObj}
                                        />
                                        <Typography className={`${classes.heading} ${labelStyle}`} onClick={() => onLabelClick(eachObj)}>
                                            {labelFormatter
                                                ? labelFormatter(
                                                      labelPlaceholder ? `${labelPlaceholder} \n ${eachObj[label]}` : eachObj[label],
                                                      eachObj,
                                                      index
                                                  )
                                                : labelPlaceholder
                                                ? `${labelPlaceholder} \n ${eachObj[label]}`
                                                : eachObj[label]}
                                        </Typography>
                                        {value && (
                                            <Typography className={classes.secondaryHeading}>
                                                {valueFormatter
                                                    ? valueFormatter(
                                                          valuePlaceholder ? `${valuePlaceholder} \n ${eachObj[label]}` : eachObj[label],
                                                          eachObj
                                                      )
                                                    : valuePlaceholder
                                                    ? `${valuePlaceholder} \n ${eachObj[value]}`
                                                    : eachObj[value]}
                                            </Typography>
                                        )}
                                        {action === 'menu' && (
                                            <OptionsIcon
                                                options={actionOptions}
                                                onEdit={this.onEditSelect(eachObj, index)}
                                                onDelete={this.onDeleteSelect(eachObj, index)}
                                            />
                                        )}
                                        <AccordianHeaderActions
                                            displaySide="right"
                                            deletable={deletable}
                                            editable={editable}
                                            printable={printableVal}
                                            onEdit={this.handleOnEdit(eachObj)}
                                            onDelete={this.onDeleteSelect(eachObj, index)}
                                            onPrint={this.handleOnPrint(eachObj)}
                                            row={eachObj}
                                        />
                                    </>
                                </AccordionSummary>
                                <AccordionDetails>{this.getDetailsTable(eachObj, excludeColumns, classes)}</AccordionDetails>
                                <Divider />
                                {this.showCancelSaveAction(eachObj) && (
                                    <AccordionActions>
                                        <>
                                            <DrcButton onClick={this.handleCancel(eachObj)}>
                                                {this.props.children === 'Accept' ? 'Reject' : 'Cancel'}
                                            </DrcButton>
                                            <DrcButton onClick={this.handleSave(eachObj)} isPrimary disabled={disableAction(eachObj)}>
                                                {this.props.children || 'Save'}
                                            </DrcButton>
                                        </>
                                    </AccordionActions>
                                )}
                            </Accordion>
                        );
                        // });
                    })}
                </InfiniteScroll>
            </Container>
        );
    }
}

export default withRouter(withStyles(styles)(AccordianList));
