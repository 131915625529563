import React, { useEffect } from 'react';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    navBtn: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        boxSizing: 'content-box',
        paddingLeft: '0.5em',
        paddingRight: '1em',
        '& svg': { width: '100%', height: '100%' }
    }
}));

function BackButton(props) {
    const classes = useStyles();

    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    const newRoute = useSelector((state) => state.masterReducer.route);

    useEffect(() => {
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    });

    return (
        <div isText className={classes.navBtn}>
            <ArrowBackIcon onClick={newRoute ? props.history.push(`${newRoute}`) : props.history.goBack} />
        </div>
    );
}

export default BackButton;
