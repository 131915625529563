import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateMapping('LocalTimeZone', 'LocalTimeZone'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Locations', 'GET')) {
        Middleware.RegisterMapping('Locations', 'GET', getListMapping);
    }
}

const LocationsMapping = {
    ConfigureMiddleware
};

export default LocationsMapping;
