import React, { Component } from 'react';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from '@driscollsinc/one-ring';
import Divider from '@material-ui/core/Divider';
import APIEndPoints from '../../services/api';
import { setReturnHeader, setReturnInventory } from '../../actions/Return';
import ReturnInventoryLines from './ReturnInventoryLines';
import HeaderPanel from '../../components/HeaderPanel';
import { getStoredTrim, setColumnOrder, formatKey, getStoredLocation, getTrimSeasonId, isSuccessful } from '../../utils/helper';
import { notify } from '../../actions/NotificationAction';
import { setToNavBack } from '../../actions/MasterActions';
import { cloneDeep } from 'lodash';
import { STATUS } from '../../data/constants';
import ZeroQtyDialog from '../../components/ZeroQtyDialog';
import { hasEmptyInventory } from './../../utils/helper';

const styles = (theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            // width: '24vw',
            flex: '1',
            padding: '0 0.2rem'
            // flexBasis: '20%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    },
    panel: {
        border: 0,
        marginBottom: '9vh'
    }
});

class ViewReturn extends Component {
    state = {
        headerData: {},
        inventoryLines: []
    };
    async componentDidMount() {
        try {
            this.props.setToNavBack(true);
            let token = this.props.authState.accessToken;
            let tagNbr = this.props.match.params.ReturnId;
            let responseData = await Middleware.Send(
                'Return',
                token,
                APIEndPoints.RETURN_INVENTORY(tagNbr, getTrimSeasonId().Id, getStoredLocation().Code),
                'GET',
                {}
            );
            let inventoryLines = responseData.ReturnLines;
            let totalInventoryCount = responseData.ReturnLines.length;
            let headerDataObj = responseData.ReturnHeader;

            this.setState({ totalInventoryCount });

            this.props.setReturnInventory(inventoryLines);
            this.props.setReturnHeader(headerDataObj);
            // }
        } catch (err) {
            console.log(err);
        }
    }

    updateInventoryData = () => {
        console.log('Update Return Inventory');
    };

    handleAction = async (actionType = '') => {
        const { headerData, inventoryLines } = this.props;
        const checkLineQty = inventoryLines.find((each) => each.Qty === 0);
        if (checkLineQty) {
            this.setState({ showZeroQty: true });
        } else {
            try {
                let updatedInventoryLines = cloneDeep(inventoryLines).map((line) => {
                    line.Status = 'POSTED';
                    if (!line.UpdateQty) {
                        line.UpdateQty = line.Qty;
                    }
                    return line;
                });
                headerData.PostStatus = 'POSTED';
                headerData.TrimSeasonName = getStoredTrim();

                let token = this.props.authState.accessToken;
                let response = await Middleware.Send('Return', token, APIEndPoints.RETURN_UPDATE, 'POST', {
                    ReturnHeader: headerData,
                    ReturnLogLines: updatedInventoryLines
                });

                if (isSuccessful(response.Message) || response.InventoryLines[0].Message.includes('POSTED')) {
                    this.props.notify(`Order Returned Successfully`, false);
                    return this.props.history.push('/Return/');
                } else {
                    this.props.notify(response.InventoryLines[0].Message, true);
                }
            } catch (err) {
                console.error(err);
                this.props.notify('Failed to Return', true);
            }
        }
    };

    isActionEnabled = () => {
        const { headerData, inventoryLines } = this.props;
        return headerData.PostStatus === STATUS.OPEN && inventoryLines.length !== 0 && !hasEmptyInventory(inventoryLines);
    };

    getHeaderPanel = (value, dataObj) => {
        const { classes } = this.props;
        let labelValue = `${dataObj['ReturnedFromNbr']}-${dataObj.growerEntity}`;

        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('LogNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['LogNbr']}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('RefNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['RefNbr']}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>{formatKey('ReturnedFromNbr')}</span>
                    <span className={classes.labelValue}>{labelValue}</span>
                </div>
            </>
        );
    };

    render() {
        const { classes, headerData } = this.props;

        return (
            <DrcMain handHeld>
                <DrcPanel className={classes.panel}>
                    <HeaderPanel
                        data={setColumnOrder([headerData], 'returnHeader')}
                        headerName={'Return'}
                        columnType={'returnHeader'}
                        headerFormatter={this.getHeaderPanel}
                        action={this.isActionEnabled() ? 'RETURN' : ''}
                        // cancel={isCancelEnabled}
                        onSelectAction={(type) => {
                            this.handleAction(type);
                        }}
                    />
                    <Divider />
                    <ReturnInventoryLines updateInventoryData={this.updateInventoryData} />
                    <ZeroQtyDialog onClose={() => this.setState({ showZeroQty: false })} showDialog={this.state.showZeroQty} />
                </DrcPanel>
            </DrcMain>
        );
    }
}
function mapStateToProps({ barcodeSearchReducer, returnLog }) {
    return {
        barcodeId: barcodeSearchReducer.barcodeId,
        headerData: returnLog.header,
        returnList: returnLog.returnList,
        inventoryLines: returnLog.inventoryLines
    };
}
const mapDispatchToProps = (dispatch) => ({
    setReturnHeader: (header) => dispatch(setReturnHeader(header)),
    setReturnInventory: (data) => dispatch(setReturnInventory(data)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setToNavBack: (data) => dispatch(setToNavBack(data))
});
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewReturn)));
