import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcInput, DrcButton, DrcKeyValueTable } from '@driscollsinc/driscolls-react-components';
import { Typography, Card, Grid } from '@material-ui/core';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { getLocalTrimAndLocation, formatKey, formatData } from '../../utils/helper';
import APIEndPoints from '../../services/api';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        minWidth: '0vw'
    },
    title: {
        color: theme.light.accent.primary,
        '& h2': {
            fontSize: '1.3rem',
            wordBreak: 'break-word'
        },
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    panel: {
        marginBottom: '8vh'
    },
    growerStorageStyle: {
        border: '0',
        padding: '0',
        marginTop: '2vh'
    },
    container: {
        // borderRadius: '2px',
        // boxShadow: '1px 1px 4px 1px #F6F5F4',
        // maxWidth: '98%'
    },
    action: {
        margin: '3% 0 2%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    labelStyle: {
        flex: 1,
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    viewList: {
        color: '#4A773C',
        fontFamily: 'Rubik',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '1rem',
        fontStyle: 'italic',
        gridColumnEnd: 'span 4'
    },
    card: {
        backgroundColor: '#E3E3E0',
        margin: '1rem 0',
        alignItems: 'right',
        shadow: 'none !important',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#0c0c0c',
            border: '1px solid white'
        }
    }
});

class AdjustmentRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AdjustmentData: []
        };
    }
    getDetailsTable = (dataObj, excludeColumns, classes) => {
        const { columns = [] } = this.props;
        let formattedDataArr = Object.keys(dataObj)
            .filter((key) => !excludeColumns.includes(key))
            .map((eachKey) => {
                const newObj = {};
                const fieldProps = columns.find((field) => field.key === eachKey) || {};
                newObj['key'] = formatKey ? formatKey(eachKey, dataObj) : eachKey;
                newObj['value'] =
                    fieldProps.editable && JSON.stringify(dataObj) === JSON.stringify(this.state.rowUnderEdit) ? (
                        typeof fieldProps.component === 'function' ? (
                            fieldProps.component(dataObj, dataObj[eachKey])
                        ) : (
                            <DrcInput value={this.state.editRow[eachKey]} onChange={() => {
                                //body
                            }} />
                        )
                    ) : formatData ? (
                        formatData(eachKey, dataObj[eachKey])
                    ) : (
                        dataObj[eachKey]
                    );
                return newObj;
            });
        return <DrcKeyValueTable children={formattedDataArr} />;
    };
    async componentDidMount() {
        this.getAdjustmentRequestData();
    }
    getAdjustmentRequestData = async () => {
        let token = this.props.authState.accessToken;
        const location = getLocalTrimAndLocation().location;
        let Result = await Middleware.Send('adjustmentRequests', token, APIEndPoints.ADJUSTMENT_REQUEST(location.Code), 'GET', {});
        let AdjustmentData = Result.Records;
        this.setState({ AdjustmentData });
    };
    navigateTo = (OriginLocationID) => {
        this.props.history.push(`/AdjustmentRequests/${OriginLocationID}`);
    };
    renderAdjustmentRequests = () => {
        return this.state.AdjustmentData.map((data) => {
            return (
                <Card className={this.props.classes.card}>
                    <Grid container>
                        <Grid item xs={9}>
                            {this.getDetailsTable(data, [
                                'LastChangedBy',
                                'CreatedBy',
                                'RequestID',
                                'RequestDetail',
                                'LastChangedDateTime',
                                'OriginLocationID',
                                'CreatedDateTime',
                                'Status'
                            ])}
                        </Grid>
                        <Grid item xs={3}>
                            <DrcButton isPrimary floatRight onClick={() => this.navigateTo(data.RequestID)}>
                                Review
                            </DrcButton>
                        </Grid>{' '}
                    </Grid>
                </Card>
            );
        });
    };
    render() {
        const location = getLocalTrimAndLocation().location;
        const trimSeason = `${location.Code}-${location.Descript}`;
        const { classes } = this.props;
        const { AdjustmentData } = this.state;

        return (
            <>
                <Typography variant="h6" gutterBottom>
                    Adjustment Requests
                </Typography>
                <div className={classes.subText}>{trimSeason}</div>
                {AdjustmentData.length ? (
                    this.renderAdjustmentRequests()
                ) : (
                    <Typography variant="body" gutterBottom>
                        No Requests
                    </Typography>
                )}
            </>
        );
    }
}

export default withOktaAuth(withRouter(withStyles(styles)(AdjustmentRequests)));
