import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [Middleware.CreateMapping('Id', 'Id'), Middleware.CreateMapping('Name', 'Name'), Middleware.CreateMapping('Result', 'Result')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('NurseryEntities', 'GET')) {
        Middleware.RegisterMapping('NurseryEntities', 'GET', getListMapping);
    }
}

const NurseryEntitiesMapping = {
    ConfigureMiddleware
};

export default NurseryEntitiesMapping;
