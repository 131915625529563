import { Middleware } from '@driscollsinc/one-ring';
const getReturnMapping = [
    Middleware.CreateMapping('ReturnHeader', 'ReturnHeader'),
    Middleware.CreateArrayMapping('ReturnLines', 'ReturnLines'),
    Middleware.CreateMapping('Results', 'Results')
];

const getListMapping = [Middleware.CreateMapping('ReturnHeader', 'ReturnHeader'), Middleware.CreateArrayMapping('ReturnLines', 'ReturnLines')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Return', 'GET')) {
        Middleware.RegisterMapping('Return', 'GET', getReturnMapping);
        Middleware.RegisterMapping('Return', 'POST', getListMapping);
    }
}

const ReturnMapping = {
    ConfigureMiddleware
};

export default ReturnMapping;
