import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import { DrcIcons } from '@driscollsinc/driscolls-react-components';
import { berryMap } from '../data/constants';
const useStyles = makeStyles(() => ({
    wrapper: {
        marginTop: '10px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    child: {
        flexBasis: '48%'
    },
    berryLabel: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            height: '15px',
            marginRight: '5px'
        }
    }
}));
export default function TrimSeason(props) {
    const [value, setValue] = React.useState(props.trimSeason);
    const classes = useStyles();
    useEffect(() => {
        setValue(props.trimSeason);
    }, [props.trimSeason]);
    const handleChange = (event) => {
        let val = event.target.value;
        setValue(val);
        props.onSelect(val, { name: 'TrimSeason' });
    };
    const makeBerryLabel = ({ label, Commodity }) => {
        return (
            <div className={classes.berryLabel}>
                <span>{DrcIcons.GetSmallBerryIcon(berryMap[Commodity])} </span>
                <span>{label} </span>
            </div>
        );
    };

    return (
        <FormControl component="fieldset" className={classes.wrapper}>
            <FormLabel component="legend">Trim Season</FormLabel>
            <RadioGroup aria-label="gender" name="trimSeason" value={value} onChange={handleChange} row>
                <div className={classes.container}>
                    {props.options.map((season) => (
                        <FormControlLabel
                            control={<Radio color="default" />}
                            value={season.label}
                            label={makeBerryLabel(season)}
                            className={classes.child}
                        />
                    ))}
                </div>
            </RadioGroup>
        </FormControl>
    );
}
