import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Variety', 'GET')) {
        Middleware.RegisterMapping('Variety', 'GET', getListMapping);
    }
}

const VarietyMapping = {
    ConfigureMiddleware
};

export default VarietyMapping;
