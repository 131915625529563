import React, { useState, useEffect } from 'react';
import { DrcMain, DrcPanel, DrcSelect } from '@driscollsinc/driscolls-react-components';
import { connect } from 'react-redux';
import CreateNewRecord from '../../components/CreateNewRecord';
import NewIssuanceHeader from '../Issuance/NewIssuanceHeader';
import { withStyles } from '@material-ui/styles';
import { useOktaAuth } from '@okta/okta-react';
import { getLocalTrimAndLocation, getStoredTrim, getTrimSeasonId, makeLocationOption } from '../../utils/helper';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../../services/api';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        },
        marginTop: '0.5rem'
    },
    main: {
        marginBottom: '9vh',
        borderRadius: '0'
    },
    panel: {
        border: '0'
    }
});

const GrowerIssuance = (props) => {
    const [grower, setGrower] = useState(null);
    const [growersList, setGrowerList] = useState([]);
    const { authState } = useOktaAuth();
    const onSelectChange = (row) => {
        setGrower(row);
    };
    useEffect(() => {
        const getData = async () => {
            try {
                let trimSeason = getStoredTrim();
                let berry = props.trimSeasonMap[trimSeason];
                let token = authState.accessToken;
                const resp = await Middleware.Send('GrowerEntities', token, APIEndPoints.GROWERS_LIST(berry, getTrimSeasonId().Id), 'GET', {});
                let list = makeLocationOption(resp.Result, 'Id', 'GrowerEntity');
                setGrowerList(list);
                if (props.issuanceHeader.EntityName) {
                    let entity = list.filter((each) => each.GrowerEntity === props.issuanceHeader.EntityName);
                    setGrower(entity[0] ? entity[0] : null);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getData();
    }, []);
    const location = getLocalTrimAndLocation().location;
    const trimSeason = `${location.label}`;
    const { classes } = props;

    const isOpenIssuance = () => {
        return !props.issuanceHeader.Status || props.issuanceHeader.Status === 'OPEN';
    };

    return (
        <DrcMain transparent handHeld className={`${classes.main}`}>
            <DrcPanel className={`${classes.panel}`}>
                {props.createNewIssuance ? (
                    <Typography variant="h6" gutterBottom>
                        New Issuance
                    </Typography>
                ) : (
                    <Typography variant="h6" gutterBottom>
                        Grower Storage Issuance
                    </Typography>
                )}
                <div className={classes.subText}>{trimSeason}</div>
                {grower && !props.createNewIssuance && (
                    <Typography gutterBottom variant="h6" className={classes.heading}>
                        Header
                    </Typography>
                )}

                {(props.createNewIssuance || props.issuanceEditMode) && (
                    <DrcSelect
                        options={growersList}
                        label="Select Grower"
                        onChange={onSelectChange}
                        value={grower}
                        required={true}
                        isWindowedSelect
                        ignoreAccents={true}
                        isDisabled={!isOpenIssuance()}
                    />
                )}
                {props.createNewIssuance ? (
                    <NewIssuanceHeader
                        onCancel={props.onCancel}
                        hasGrowerAccess={props.hasGrowerAccess}
                        hasNurseryAccess={props.hasNurseryAccess}
                        growerEntity={grower}
                    />
                ) : null}
                {grower && !props.createNewIssuance && <CreateNewRecord ignorePermissions growerEntity={grower || {}} isGrowerStorage={true} />}
            </DrcPanel>
        </DrcMain>
    );
};

const mapStateToProps = ({ masterReducer, issuance, growerStorage }) => ({
    trimSeasonMap: masterReducer.trimSeasonMap,
    issuanceHeader: issuance.header,
    inventoryLines: issuance.inventoryLines,
    issuanceEditMode: growerStorage.issuanceEditMode
});

export default connect(mapStateToProps, null)(withStyles(styles)(GrowerIssuance));
