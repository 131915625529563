import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcImage,
    DrcPageNotFound,
    DrcBackdrop,
    DrcDialog,
    DrcButton,
    DrcLoading,
    DrcMain,
    DrcPanel,
    Helmet,
    DrcVersionInfo,
    DrcPageLogin,
    DrcIcons,
    DrcPageWarning,
    DrcImplicitCallback
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import { hideErrorDialogAction, showLoadingScreenAction, hideLoadingScreenAction, addInfo, setErrorsAction } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import { setGrowerStorageNav } from './actions/GrowerStorage';
import MasterDataUtilities from './data/MasterDataUtilities';
import LoggingUtilities from './data/LoggingUtilities';
import LINKS from './data/links';
import BackButton from './pages/BackButton';
import MenuBar from './components/MenuBar';
import IsAuthenticated from './components/IsAuthenticated';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import Dashboard from './pages/Dashboard';
import Setup from './pages/Setup';
import DumpList from './pages/Dump/DumpList';
import ViewDump from './pages/Dump/ViewDump';
import Issuance from './pages/Issuance/Issuance';
import ViewIssuance from './pages/Issuance/viewIssuance';
import Return from './pages/Return/Return';
import ViewTransfer from './pages/Transfer/ViewTransfer';
import TransferHome from './pages/Transfer/TransferHome';
import TransferList from './pages/Transfer/TransferList';
import ReceivingList from './pages/Transfer/ReceivingList';
import AdjustTrim from './pages/AdjustTrim/AdjustTrim';
import AdjustmentRequestDetails from './pages/AdjustTrim/AdjustmentRequestDetails';
import ViewReturn from './pages/Return/ViewReturn';
import CreateNewRecord from './components/CreateNewRecord';
import EditTransfer from './pages/Transfer/EditTransfer';
import EditReturn from './pages/Return/EditReturn';
import GrowerStorage from './pages/GrowerStorage/GrowerStorage';
import GrowerIssuance from './pages/GrowerStorage/GrowerIssuance';

import { ReactComponent as PrintIcon } from './assets/Printer.svg';
import { getStoredTrim, getLocalTrimAndLocation, getOS, checkPermission } from './utils/helper';
import LogoPng from './Images/Logo_Large_Transparent_No_Motto.png';
import PBS_icon_small from './Images/PBS_icon_small.png';
import LogoWebP from './Images/Logo_Large_Transparent_No_Motto.webp';
import BackgroundPng from './Images/Bowl_Separate_3_bw.png';
import BackgroundWebP from './Images/Bowl_Separate_3_bw.webp';

const Print = lazy(() => import('./pages/Print'));
const Releases = lazy(() => import('./pages/Releases'));
const Downloads = lazy(() => import('./pages/Downloads'));
const HealthCheck = lazy(() => import('./pages/HealthCheck'));
const Maintenance = lazy(() => import('./pages/Maintenance/Maintenance'));
const NewPrinter = lazy(() => import('./pages/Maintenance/PrintServer/NewPrintServer'));

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super') || g.toLowerCase().includes('app'));

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const SITE_NAME = 'PBS';
var styles = (theme) => ({
    '@global': {
        scrollBehavior: 'smooth',
        /* width */
        '::-webkit-scrollbar': {
            // width: '10px',
            // height: '10px'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        }
    },
    logo: {
        height: 40
    },
    bigLogo: {
        height: 22
    },
    marginLeft: {
        margin: '3px 0px 0px 40px !important'
    },
    header: {
        // background: ' linear-gradient(180deg, #FCF4B9 0%, #FCE673 100%)',
        // boxShadow: 'none',
        '& .title': {
            display: 'none'
        },
        '& button.menuButton': {
            position: 'absolute',
            border: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            background: theme.palette.common.black,
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        }
    },
    headerBtnContainer: {
        float: 'right'
    },
    loader: {
        '& > div': {
            width: 320,
            left: 'calc(50vw - 160px)'
        }
    },
    berryBtn: {
        margin: '0 !important',
        border: 'none !important',
        padding: '0',
        top: 0,
        '& img': {
            height: 20
        },
        minWidth: '40px !important'
    },
    printBtn: {
        margin: '0 !important',
        border: 'none !important',
        padding: '0',
        top: 0,
        '& svg': {
            fontSize: 36,
            width: '20px',
            height: '20px'
        },
        minWidth: '40px !important'
    },
    icon: {
        fill: '#000',
        '@media (prefers-color-scheme: dark)': {
            fill: '#fff'
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.props.setInitializeRedirectUrl('/Setup');
                this.props.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.state = {
            RelevantLinks: [],
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Setup/');

        this.closeErrorDialog = this.closeErrorDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction, false);
    }

    componentDidMount() {
        this.getRelevantLinks();
        window.addEventListener('online', () => {
            this.setState({ notification: 'Online', isNotificationError: false }, () => {
                setTimeout(() => {
                    this.setState({ notification: '' });
                }, 5000);
            });
        });
        window.addEventListener('offline', () => {
            this.setState({ notification: 'Offline', isNotificationError: true });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.distinctAccessKeys !== this.props.distinctAccessKeys) {
            this.getRelevantLinks();
        }
    }

    closeErrorDialog() {
        this.props.hideErrorDialog();
    }

    getRelevantLinks = () => {
        var filteredLinks = LINKS.filter((link) => {
            return checkPermission(link.securitykey, this.props.distinctAccessKeys);
        });
        var otherLinks = [];
        LINKS.map((link) => {
            if (!link.securitykey) {
                otherLinks.push(link);
            }
            return otherLinks;
        });
        let RelevantLinks =
            getOS() === 'iOS' // hiding Downloads form the ios users
                ? [...filteredLinks, ...otherLinks].filter((linkObj) => linkObj.title !== 'Downloads')
                : [...filteredLinks, ...otherLinks];
        this.setState({ RelevantLinks });
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    render() {
        var { classes, trimToBerry } = this.props;
        this.props.setGrowerStorageNav(window.location.pathname.includes('GrowerStorage'));
        if (!getLocalTrimAndLocation() && window.location.pathname !== '/Setup/' && this.props.isInitialized) {
            return <Redirect to="/Setup/" />;
        }
        let localTrimSeason = getStoredTrim();
        return (
            <DrcThemeProvider theme={this.state.theme}>
                <DrcImage
                    src={BackgroundPng}
                    webp={BackgroundWebP}
                    style={{
                        position: 'fixed',
                        zIndex: -1,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover'
                    }}
                    alt=""
                />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <DrcSecureHeader
                            allLinks={this.props.toNavBack || !this.props.distinctAccessKeys.length ? [] : this.state.RelevantLinks}
                            fullWidth={true}
                            logo={
                                <>
                                    {this.props.toNavBack ? <Route component={BackButton} /> : null}
                                    <Box display={{ sm: 'block', xs: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                                        <DrcImage
                                            src={PBS_icon_small}
                                            webp={PBS_icon_small}
                                            className={`${classes.logo} ${!this.props.toNavBack ? classes.marginLeft : ''}`}
                                            alt="Driscoll's Logo"
                                        />
                                    </Box>
                                    <Box display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                                        <DrcImage
                                            src={LogoPng}
                                            webp={LogoWebP}
                                            className={`${classes.bigLogo} ${!this.props.toNavBack ? classes.marginLeft : ''}`}
                                            alt="Driscoll's Logo"
                                        />
                                    </Box>
                                </>
                            }
                            className={this.props.classes.header}
                            anchor="left"
                        >
                            <IsAuthenticated>
                                <div className={this.props.classes.headerBtnContainer}>
                                    {localTrimSeason && (
                                        <DrcButton isText to="/Setup/" className={this.props.classes.berryBtn}>
                                            {DrcIcons.GetBerryIcon(trimToBerry[localTrimSeason])}
                                        </DrcButton>
                                    )}
                                    {this.props.distinctAccessKeys.length ? (
                                        <DrcButton isText to="/Print/" className={this.props.classes.printBtn}>
                                            <PrintIcon className={this.props.classes.icon} />
                                        </DrcButton>
                                    ) : null}
                                </div>
                            </IsAuthenticated>
                        </DrcSecureHeader>
                        {!window.location.pathname.includes('Setup') ? <MenuBar /> : null}
                        <Suspense
                            fallback={
                                <DrcMain>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route path="/" exact render={(props) => <DrcPageLogin {...props} setPageTitle={this.props.setPageTitleAction} />} />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/GrowerStorage/Dump/Edit/:Nbr"
                                    exact
                                    component={CreateNewRecord}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/GrowerStorage/Dump/:Nbr/" exact component={ViewDump} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/GrowerStorage/Issuance/Edit/:Nbr" component={GrowerIssuance} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/GrowerStorage/Issuance/Grower/:Nbr" component={ViewIssuance} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/GrowerStorage/" component={GrowerStorage} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Print/" exact component={Print} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Print/New/" exact component={NewPrinter} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/AdjustTrim/" exact component={AdjustTrim} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/AdjustmentRequests/:requestNumber/"
                                    exact
                                    component={AdjustmentRequestDetails}
                                    groupsAllowed={allGroups}
                                />

                                <DrcSecureGroupRoute path="/Transfer/Edit/:TransferId" exact component={EditTransfer} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Transfer/" component={TransferHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Transfer/Open" exact component={TransferList} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Transfer/ReceivingList" exact component={ReceivingList} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/TransferInventory/:TransferId" exact component={ViewTransfer} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ReceiveInventory/:TransferId" exact component={ViewTransfer} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Issuance/:Nbr" exact component={CreateNewRecord} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Issuance/Edit/:Nbr" exact component={CreateNewRecord} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute
                                    path="/Issuance/:IssuanceType/:IssuanceId/"
                                    exact
                                    component={ViewIssuance}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Issuance/" exact component={Issuance} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Dump/" exact component={DumpList} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Dump/New/:Nbr/" exact component={CreateNewRecord} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Dump/Edit/:Nbr/" exact component={CreateNewRecord} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Dump/:Nbr/" exact component={ViewDump} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Return/Edit/:LogNbr" exact component={EditReturn} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Return/" exact component={Return} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Return/:ReturnType/:ReturnId/" exact component={ViewReturn} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Setup/" exact component={Setup} groupsAllowed={allGroups} />

                                <DrcSecureGroupRoute path="/Maintenance/" component={Maintenance} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Downloads/" component={Downloads} groupsAllowed={allGroups} />

                                {/* <DrcSecureGroupRoute
                                    path="/GrowerStorage/:GrowerStorageId/"
                                    exact
                                    component={GrowerStorage}
                                    groupsAllowed={allGroups}
                                /> */}

                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={superAdminGroups} />
                                <Route path="/HealthCheck/" component={HealthCheck} />
                                <Route path="/implicit/callback" render={(props) => <DrcImplicitCallback {...props} />} />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <Box display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block', xl: 'block' }} bgcolor="primary.main">
                        <DrcVersionInfo allowClick={this.props.isSuperAdmin} />
                    </Box>
                    <DrcBackdrop
                        className={this.props.classes.loader}
                        isLoading
                        loadingMessage={this.props.loadingScreenMessage}
                        show={this.props.showLoadingScreen}
                    />
                    <DrcDialog
                        isError
                        title={this.props.errorDialog.title}
                        open={this.props.errorDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeErrorDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.errorDialog.content}
                    </DrcDialog>
                </Router>
                {this.props.notification && (
                    <DrcPageWarning isError={this.props.isNotificationError} anchorVertical="top" message={this.props.notification} />
                )}
                {this.state.notification && (
                    <DrcPageWarning isError={this.state.isNotificationError} anchorVertical="top" message={this.state.notification} />
                )}
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isAdmin: state.masterReducer.isAdmin,
        isSuperAdmin: state.masterReducer.isSuperAdmin,
        isInitialized: state.masterReducer.isInitialized,
        isGrowerStorageNavigation: state.growerStorage.isNavigated,
        toNavBack: state.masterReducer.toNavBack,
        trimToBerry: state.masterReducer.trimSeasonMap,
        notification: state.notificationReducer.message,
        isNotificationError: state.notificationReducer.isError,
        distinctAccessKeys: state.userAccesses.distinctAccessKeys
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors)),
    setGrowerStorageNav: (isNavigated) => dispatch(setGrowerStorageNav(isNavigated))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App)));
