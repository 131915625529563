import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { DrcInput } from '@driscollsinc/driscolls-react-components';
import { setTagNbr } from '../actions/barcodeSearchAction';
import { ReactComponent as SearchIcon } from '../assets/Search.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
const styles = (theme) => ({
    container: {
        margin: '1rem 0',
        flex: '1'
    },
    outlinedInput: {
        '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        },
        '&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: theme.palette.primary.main
        }
    },
    icon: {
        fill: '#000',
        '@media (prefers-color-scheme: dark)': {
            fill: '#fff'
        }
    }
});

class TagNumberSearch extends Component {
    state = {
        tagNbr: ''
    };
    componentDidMount() {
        const { selectedTag = '' } = this.props;
        if (selectedTag === '') this.props.setTagNbr('');
    }
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.tagNbr || '') === (this.state.tagNbr || '') && this.props.tagNbr !== prevProps.tagNbr) {
            this.setState({ tagNbr: this.props.tagNbr || '' });
        }
    }
    handleChange = (e) => {
        if (this.props.isFreeText) {
            this.setState({ tagNbr: e.target.value }, () => this.props.setTagNbr(this.state.tagNbr));
        } else {
            if (parseInt(e.target.value.length) > 8 || isNaN(e.target.value) || e.target.value.includes('.')) {
                e.preventDefault();
                return;
            } else this.setState({ tagNbr: e.target.value }, () => this.props.setTagNbr(this.state.tagNbr));
        }
    };
    render() {
        const { classes, selectedTag = '', disabled = false, label = 'Search by Tag #', search = () => {}, ...other } = this.props;
        return (
            <div className={`${classes.container} ${this.props.className || ''}`}>
                <DrcInput
                    label={label}
                    onChange={this.handleChange}
                    value={selectedTag !== '' ? selectedTag : this.state.tagNbr}
                    disabled={disabled}
                    className={classes.outlinedInput}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="search tag" onClick={search} edge="end">
                                    <SearchIcon style={{ width: '18px', height: '18px' }} className={this.props.classes.icon} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    autoFocus
                    {...other}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr))
});

function mapStateToProps({ barcodeSearchReducer }) {
    return {
        tagNbr: barcodeSearchReducer.tagNbr
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TagNumberSearch));
