import React from 'react';
import AccordianList from '../../components/AccordianList';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { setColumnOrder } from '../../utils/helper';
import DumpNew from './DumpNew';
import { withRouter } from 'react-router-dom';
const styles = (theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerAction: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        width: '230px',
        '&>div': {
            flexBasis: '45%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    }
});

class DumpHeader extends React.Component {
    state = {
        editHeader: false,
        headerData: {}
    };

    headerFormatter = (value, dataObj) => {
        const { classes, locationMap } = this.props;
        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>Dump Nbr</span>
                        <span className={classes.labelValue}>{dataObj.LogNbr}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>Status</span>
                        <span className={classes.labelValue}>{dataObj.Status}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>Dump Warehouse</span>
                    <span className={classes.labelValue}>{`${dataObj.DumpWarehouse}-${locationMap[dataObj.DumpWarehouse]}`}</span>
                </div>
            </>
        );
    };
    render() {
        const { classes, dumpHeader = {}, location, ...rest } = this.props;
        const isEditMode = location.pathname.includes('/Dump/Edit');
        return (
            <>
                {this.state.editHeader ? (
                    <DumpNew cancelDump={() => this.setState({ editHeader: false, headerData: null })} />
                ) : (
                    <>
                        <div className={classes.headerAction}>
                            <Typography variant="h6" gutterBottom className={classes.heading}>
                                Header
                            </Typography>
                            {this.props.children}
                        </div>
                        <AccordianList
                            data={setColumnOrder([dumpHeader], 'dumpHeader')}
                            labelFormatter={this.headerFormatter}
                            checkEditable={(row) => (isEditMode && row.Status === 'OPEN' ? 'right' : null)}
                            onEdit={(row) => this.setState({ editHeader: true, headerData: row })}
                            {...{ ...rest, children: null }}
                        />
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps({ dump, masterReducer }) {
    return {
        dumpHeader: dump.dumpHeader,
        locationMap: masterReducer.locationMap
    };
}
export default withOktaAuth(connect(mapStateToProps, null)(withRouter(withStyles(styles)(DumpHeader))));
