export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const setUserSettings = (SettingsArray) => ({
    type: SET_USER_SETTINGS,
    payload: SettingsArray
});

export const SET_USER_LOCATIONS = 'SET_USER_LOCATIONS';
export const setUserLocations = (payload) => ({
    type: SET_USER_LOCATIONS,
    payload: payload
});
