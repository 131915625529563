import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setToNavBack } from '../../actions/MasterActions';
import { setAdjustTrimHeaderDetails, setReasonCodeList, setVariety } from '../../actions/AdjustTrim';
import { setPrintInventoryData, setPrintQuantity } from '../../actions/Print';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcMain, DrcPanel, DrcButton, DrcSelect, DrcDialog, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import AccordianList from '../../components/AccordianList';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Typography } from '@material-ui/core';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import AdjustmentRequests from './AdjustmentRequests';
import {
    makeColumns,
    getLocalTrimAndLocation,
    getStoredLocation,
    formatData,
    formatKey,
    makeLocationOption,
    getStoredTrim,
    setColumnOrder,
    getTrimSeasonId,
    toISOdateString,
    isWholeNumber,
    isSuccessful,
    checkPermission
} from '../../utils/helper';
import { getExcludedColumns, StockTypes } from '../../data/constants';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import BarcodeIDSearch from '../../components/BarcodeIDSearch';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const PAGE_KEY = 'ADJUSTINVENTORYKEY';
const pageTitle = 'Adjust Trim';

const styles = (theme) => ({
    root: {
        minWidth: '0vw'
    },
    title: {
        color: theme.light.accent.primary,
        '& h2': {
            fontSize: '1.3rem',
            wordBreak: 'break-word'
        },
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    panel: {
        marginBottom: '8vh'
    },
    growerStorageStyle: {
        border: '0',
        padding: '0',
        marginTop: '2vh'
    },
    container: {
        // borderRadius: '2px',
        // boxShadow: '1px 1px 4px 1px #F6F5F4',
        // maxWidth: '98%'
    },
    action: {
        margin: '3% 0 2%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    labelStyle: {
        flex: 1,
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    viewList: {
        color: '#4A773C',
        fontFamily: 'Rubik',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '1rem',
        fontStyle: 'italic',
        gridColumnEnd: 'span 4'
    }
});

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        position: 'absolute'
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        height: '35px',
        width: '100%',
        backgroundColor: 'transparent'
    }),
    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: '1400'
    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: '25vh'
    })
};

class AdjustTrim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reasonCode: null,
            openConfirmDialog: false,
            editableLabel: 'Qty',
            homePage: '',
            row: {},
            editedQuantity: null,
            originalQuantity: null,
            hasAccess: false,
            showPrintDialog: false,
            isGrowerStorage: this.props.location.pathname.includes('GrowerStorage'),
            variety: makeLocationOption(JSON.parse(localStorage.getItem('variety')), 'Code', 'Descript', true)[0],
            trimType: this.props.trimTypeList[0],
            stockType: StockTypes[0],
            inventoryList: []
        };
    }

    async componentDidMount() {
        this.props.setToNavBack(false);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (!this.props.ignorePermissions) {
            if (!checkPermission(PAGE_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasAccess: false });
            } else {
                this.setState({ hasAccess: true });
            }
        } else {
            this.setState({ hasAccess: true });
        }

        if (MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            try {
                let token = this.props.authState.accessToken;

                let reasonCodeResponse = await Middleware.Send('ReasonCode', token, APIEndPoints.REASON_CODES('INVENTORY'), 'GET', {});
                this.props.setReasonCodeList(reasonCodeResponse.Result);
                this.setState({ variety: makeLocationOption(this.props.varietyList, 'Descript', 'Code', true)[0] });
                this.props.setAdjustTrimHeaderDetails([]);
                this.props.setPrintInventoryData([]);
                this.props.setPrintQuantity(1);
            } catch (err) {
                console.log(err);
            }
        }
    }

    getInventoryDetails = async () => {
        const { variety, trimType, stockType, isGrowerStorage } = this.state;
        let token = this.props.authState.accessToken;
        let locationId = getStoredLocation().Code;
        let trimSeason = getStoredTrim();
        let berry = this.props.trimSeasonMap[trimSeason];
        this.props.setAdjustTrimHeaderDetails([]);

        try {
            const stocktype = stockType && stockType.label === 'ALL' ? null : stockType.value;
            const varietycode = variety && variety.label === 'ALL' ? null : variety && variety.Code ? variety.Code : null;
            const trimtype = trimType && trimType.label === 'ALL' ? null : trimType.TrimType;
            const barcodeId = this.props.barcodeId === '' ? null : this.props.barcodeId;
            let apiEndPoint = isGrowerStorage
                ? APIEndPoints.GET_GROWER_INVENTORY(
                      barcodeId,
                      getTrimSeasonId().Id,
                      berry,
                      locationId,
                      this.props.returnFrom.Id,
                      null,
                      stocktype,
                      varietycode,
                      trimtype
                  )
                : APIEndPoints.GET_PLANT_INVENTORY(locationId, barcodeId, berry, 'INVENTORY', stocktype, varietycode, trimtype);

            let inventoryLine = await Middleware.Send('PlantInventory', token, apiEndPoint, 'GET', {});

            if (isGrowerStorage) {
                if (inventoryLine.Result.length) {
                    this.props.setAdjustTrimHeaderDetails(inventoryLine.Result);
                } else {
                    this.props.notify('Inventory Not found', true);
                    this.props.setBarcodeId('');
                }
            } else if (inventoryLine && barcodeId) {
                this.props.setAdjustTrimHeaderDetails([inventoryLine[0]]);
            } else if (inventoryLine) {
                this.setState({ inventoryList: inventoryLine });
                this.props.setAdjustTrimHeaderDetails([]);
            } else {
                this.props.notify('Inventory Not found', true);
                this.props.setBarcodeId('');
            }
        } catch (err) {
            this.props.notify('Some error occurred', true);
            console.log(err);
        }
    };

    async componentDidUpdate(prevProps) {
        if (this.props.barcodeId.length === 10 && prevProps.barcodeId !== this.props.barcodeId) {
            const { inventoryList, isGrowerStorage } = this.state;
            let lineDetails = cloneDeep(inventoryList.filter((line) => line.BarcodeID === this.props.barcodeId));
            if (!isGrowerStorage && lineDetails.length) {
                this.props.setAdjustTrimHeaderDetails([lineDetails[0]]);
            } else {
                this.getInventoryDetails();
            }
        }
    }

    handleBidClick = (dataObj) => {
        this.props.setBarcodeId(dataObj.BarcodeID);
    };

    getHeaderLabel = (value, dataObj) => {
        const { classes } = this.props;
        return (
            <div className={classes.labelStyle} onClick={() => this.handleBidClick(dataObj)}>
                {dataObj['BarcodeID']}
            </div>
        );
    };

    printRequest = () => {
        this.props.history.push(`/Print/`);
    };

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: value, originalQuantity: originalVal });
        }
    };

    selectReasonCode = (row, value) => (selected) => {
        this.setState({ reasonCode: selected });
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    handleSave = (row) => {
        const { editedQuantity, originalQuantity } = this.state;
        const { headerDetails, barcodeId } = this.props;

        if (editedQuantity === null) {
            return this.props.notify('Please Edit Quantity', true);
        }

        const message = `Change Qty from ${originalQuantity} to ${editedQuantity}`;
        if (editedQuantity > originalQuantity) {
            const extraLabels = editedQuantity - originalQuantity;
            let updatedData = headerDetails.map((obj) => {
                if (obj['BarcodeID'] === barcodeId) {
                    obj['Qty'] = editedQuantity;
                }
                return obj;
            });
            this.props.setPrintInventoryData(updatedData);
            this.props.setPrintQuantity(extraLabels);
        }
        this.setState({ openConfirmDialog: true, message, isEdited: true, row });
    };

    handleCancel = () => {
        this.setState({ editedQuantity: null, originalQuantity: null, isEdited: false, openConfirmDialog: false, reasonCode: null });
        this.props.setBarcodeId('');
        this.props.setAdjustTrimHeaderDetails([]);
        this.props.setPrintInventoryData([]);
        this.props.setPrintQuantity(1);
    };

    handleSelectCancel = () => {
        this.setState({ isEdited: false, openConfirmDialog: false, reasonCode: null });
    };

    handleConfirm = () => {
        if (this.state.isEdited) this.adjustTrim();
    };

    adjustTrim = async () => {
        try {
            let token = this.props.authState.accessToken;
            let locationId = getStoredLocation().Code;
            let payload = {
                TransactionType: 'ADJUST',
                TransactionReason: this.state.reasonCode.label,
                InventoryLocation: this.props.headerDetails[0].InventoryLocation,
                StorageLocation: this.props.headerDetails[0].StorageLocation,
                BarcodeID: this.props.headerDetails[0].BarcodeID,
                Qty: this.state.editedQuantity,
                Status: null,
                SourceLoc: locationId || null,
                DestinationLoc: null,
                LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                TrimSeason: getStoredTrim(),
                LastChangedDateTime: toISOdateString(new Date()),
                ...{ ...(this.state.isGrowerStorage ? { StorageType: 'GROWERSTORAGE' } : null) }
            };
            let resp = await Middleware.Send('PlantInventory', token, APIEndPoints.ADJUST_TRIM, 'POST', {
                ...payload
            });
            if (isSuccessful(resp.Message)) {
                this.props.setBarcodeId('');
                if (this.state.inventoryList.length > 0) {
                    this.getInventoryDetails();
                }
                this.props.notify('Trim Adjusted', false);
                if (this.state.editedQuantity > this.state.originalQuantity) {
                    this.setState({ showPrintDialog: true, openConfirmDialog: false });
                } else {
                    this.handleCancel();
                }
            } else {
                this.setState({ isEdited: false });
                this.props.notify(resp.Message, true);
            }
            this.setState({ openConfirmDialog: false });
        } catch (err) {
            console.error(err);
        }
    };

    handleSelectChange = (option, props) => {
        this.setState({ [props.name]: option });
    };
    clearSearch = (bool, row) => {
        // this.props.setTagNbr(bool ? row.LogNbr : '');
    };
    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        const { classes, headerDetails, barcodeId, reasonCodeList } = this.props;
        const { reasonCode, editableLabel, isEdited, originalQuantity, editedQuantity, row, isGrowerStorage, inventoryList } = this.state;
        const editableField = [
            {
                label: editableLabel,
                editType: 'text'
            }
        ];
        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }
        const location = getLocalTrimAndLocation().location;
        const trimSeason = `${location.Code}-${location.Descript}`;

        if (this.state.hasAccess) {
            return (
                <DrcMain transparent handHeld className={`${isGrowerStorage ? classes.growerStorageStyle : null}`}>
                    <DrcPanel maxWidth="100%" className={`${classes.panel} ${isGrowerStorage ? classes.growerStorageStyle : null}`}>
                        {!isGrowerStorage && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Adjust Trim
                                </Typography>
                                <div className={classes.subText}>{trimSeason}</div>
                            </>
                        )}

                        <BarcodeIDSearch autoFocus />

                        <DrcSelect
                            options={makeLocationOption(JSON.parse(localStorage.getItem('variety')), 'Code', 'Descript', true)}
                            label="Select Variety"
                            name="variety"
                            onChange={this.handleSelectChange}
                            value={this.state.variety}
                            isWindowedSelect
                            ignoreAccents={true}
                            maxMenuHeight={window.innerHeight * 0.25}
                        />
                        <DrcSelect
                            options={this.props.trimTypeList}
                            label="Select Trim Type"
                            name="trimType"
                            onChange={this.handleSelectChange}
                            value={this.state.trimType}
                            isWindowedSelect
                            ignoreAccents={true}
                            styles={customStyles}
                        />
                        {!this.state.isGrowerStorage && (
                            <DrcSelect
                                options={StockTypes}
                                label="Select Stock Type"
                                name="stockType"
                                onChange={this.handleSelectChange}
                                value={this.state.stockType}
                                isWindowedSelect
                                ignoreAccents={true}
                                styles={customStyles}
                            />
                        )}

                        <DrcButton isPrimary floatRight onClick={this.getInventoryDetails}>
                            Search
                        </DrcButton>

                        {/* To Display List of Barcodes after Filter Search */}
                        {!isGrowerStorage && inventoryList.length !== 0 && headerDetails.length === 0 && (
                            <AccordianList
                                data={setColumnOrder(inventoryList, 'plantInventory')}
                                columns={makeColumns(inventoryList[0])}
                                labelFormatter={this.getHeaderLabel}
                                value={editableLabel}
                                excludeColumns={getExcludedColumns('adjustTrim')}
                                dataFormatter={formatData}
                                keyFormatter={formatKey}
                                scrollable
                                defaultEdit
                                classes={classes}
                                // customExpand={(row = {}) => row['BarcodeID'] === barcodeId}
                                clearSearch={this.clearSearch}
                            />
                        )}

                        <AccordianList
                            data={setColumnOrder(headerDetails, 'plantInventory')}
                            columns={makeColumns(headerDetails[0], editableField, this.editInput, this.getValue)}
                            labelFormatter={this.getHeaderLabel}
                            value={editableLabel}
                            excludeColumns={getExcludedColumns('adjustTrim')}
                            dataFormatter={formatData}
                            keyFormatter={formatKey}
                            scrollable
                            defaultEdit
                            classes={classes}
                            customExpand={(row = {}) => row['BarcodeID'] === barcodeId}
                            onSave={this.handleSave}
                            onCancel={this.handleCancel}
                            isUnderEdit={this.state.editedQuantity !== this.state.originalQuantity}
                            disableAction={(row = {}) => {
                                return this.getValue(row[editableLabel]) === '' || editedQuantity === null;
                            }}
                        >
                            Adjust
                        </AccordianList>
                        {!isGrowerStorage && <AdjustmentRequests />}
                        <DrcDialog
                            classes={classes}
                            title={`Change Qty from ${originalQuantity} to ${editedQuantity}`}
                            open={isEdited}
                            buttons={
                                <>
                                    <DrcButton onClick={this.handleSelectCancel}>Cancel</DrcButton>
                                    <DrcButton isPrimary onClick={this.handleConfirm} disabled={!this.state.reasonCode}>
                                        Confirm
                                    </DrcButton>
                                </>
                            }
                        >
                            <DrcSelect
                                options={makeLocationOption(reasonCodeList, 'ReasonCode', 'ReasonDescript')}
                                label="Select Reason Code"
                                onChange={this.selectReasonCode(row)}
                                value={reasonCode}
                                required={true}
                                isSearchable={false}
                                styles={customStyles}
                                maxMenuHeight={window.innerHeight * 2.15}
                            />
                        </DrcDialog>

                        <ConfirmDialog
                            message={`Do you want to print extra ${this.state.editedQuantity - this.state.originalQuantity} labels`}
                            open={this.state.showPrintDialog}
                            closeConfirmDialog={() => {
                                this.handleCancel();
                                this.setState({ showPrintDialog: false });
                            }}
                            submitRequest={this.printRequest}
                        />
                    </DrcPanel>
                </DrcMain>
            );
        } else {
            return <DrcPageNotAuthorized />;
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setAdjustTrimHeaderDetails: (data) => dispatch(setAdjustTrimHeaderDetails(data)),
    setReasonCodeList: (data) => dispatch(setReasonCodeList(data)),
    setPrintInventoryData: (data) => dispatch(setPrintInventoryData(data)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setPrintQuantity: (data) => dispatch(setPrintQuantity(data)),
    setVariety: (data) => dispatch(setVariety(data)),
    setToNavBack: (data) => dispatch(setToNavBack(data))
});

function mapStateToProps({ userAccesses, masterReducer, rootReducer, barcodeSearchReducer, adjustTrim, print, growerStorage }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        barcodeId: barcodeSearchReducer.barcodeId,
        headerDetails: adjustTrim.headerDetails,
        reasonCodeList: adjustTrim.reasonCodeList,
        printInventoryLines: print.printInventoryLines,
        trimSeasonMap: masterReducer.trimSeasonMap,
        trimTypeList: masterReducer.trimTypeList,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        varietyList: adjustTrim.varietyList
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AdjustTrim))));
