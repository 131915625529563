import PrintService from './../services/printService';
import { getDefaultPrintServer, getLocalPrintServers, getLocalTrimAndLocation } from './helper';
import { DeviceDetectionUtility } from './deviceDetectionUtility';

const _print = (device, data = '~wc', quantity = 1, notify, callback = () => {}) => {
    var count = 0;
    return sendData();
    function sendData() {
        device.send(
            data,
            function (success) {
                if (++count < quantity) {
                    notify(`Printed ${count} of ${quantity}`, false);
                    sendData();
                } else {
                    setTimeout(() => notify('Labels Printed', false), 1000);
                    callback();
                }
            },
            function (error) {
                if (count < quantity) {
                    notify(`Retrying ${count}`, true);
                    sendData();
                }
            }
        );
    }
};

const printBase64ToPDF = (deviceAddress, content, type, fileName, numberOfCopies = 1) => {
    if (!deviceAddress) {
        printPDFOnBrowser(content, fileName);
        return;
    }

    //prepare options for printer. If not, printService applies default options
    let options = {
        url: deviceAddress,
        onConnect: () => console.log('Connected'),
        onDisconnect: () => console.log('Disconnected'),
        onUpdate: () => console.log('Updated'),
        onError: (err) => printPDFOnBrowser(content, fileName)
    };

    let printService = new PrintService(options);

    //looking to print to a type like 0001-GROWERISSUANCE
    const typeWithLocation = `${getLocalTrimAndLocation().location.Code}-${type}`;

    printService.connect().then((data) => {
        for (let i = 1; i <= numberOfCopies; i++) {
            printService.submit({
                type: typeWithLocation,
                url: fileName,
                file_content: content
            });
        }
    });
};

const printPDFOnBrowser = (content, title) => {
    if (DeviceDetectionUtility.IsDesktop()) {
        let pdfWindow = window.open('');
        pdfWindow.document.write('<html><head><title>' + title + '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>');
        pdfWindow.document.write(
            "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(content) +
                "#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>"
        );
        return;
    }

    if (DeviceDetectionUtility.IsMobileDevice() || DeviceDetectionUtility.IsTablet()) {
        let a = document.createElement('a');
        a.href = 'data:application/octet-stream;base64,' + content;
        a.download = `${title}.pdf`;
        a.click();
        return;
    }
};

const getPrintServer = () => {
    const defaultPrinter = getDefaultPrintServer(getLocalPrintServers().printServers, getLocalTrimAndLocation().location.Code, 'PDF');
    return defaultPrinter;
};

export const PrintUtility = {
    print: _print,
    PrintBase64ToPDF: printBase64ToPDF,
    PrintPDFOnBrowser: printPDFOnBrowser,
    GetPrintServer: getPrintServer
};
