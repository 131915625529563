import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { DrcInput } from '@driscollsinc/driscolls-react-components';
import { setBarcodeId } from '../actions/barcodeSearchAction';
import { ReactComponent as BarcodeIcon } from '../assets/Barcode.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
const styles = () => ({
    container: {
        margin: '1rem 0'
    },
    icon: {
        fill: '#000',
        '@media (prefers-color-scheme: dark)': {
            fill: '#fff'
        }
    }
});
class BarcodeIdSearch extends Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
    }
    state = {
        groupID: ''
    };
    componentDidMount() {
        this.props.setBarcodeId('');
        this.inputField.current.focus();
    }
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.groupID || '') === (this.state.groupID || '') && this.props.groupID !== prevProps.groupID) {
            this.setState({ groupID: this.props.groupID || '' });
            this.inputField.current.focus();
        }
    }
    handleChange = (e) => {
        if (e.target.value.length < 11) {
            this.setState({ groupID: e.target.value }, () => this.props.setBarcodeId(this.state.groupID));
        }
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <DrcInput
                    autoFocus
                    inputRef={this.inputField}
                    label={'Search by Barcode'}
                    value={this.state.groupID}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <BarcodeIcon style={{ width: '18px', height: '18px' }} className={this.props.classes.icon} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (nbr) => dispatch(setBarcodeId(nbr))
});

function mapStateToProps({ barcodeSearchReducer }) {
    return {
        groupID: barcodeSearchReducer.barcodeId
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BarcodeIdSearch));
