const sortArrayInAscendingOrder = (array, sortProperty) => {
    if (array.length <= 0) {
        return [];
    }
    let propertyType = typeof array[0][sortProperty];
    if (propertyType === 'number') {
        return (array || []).sort((a, b) => a[sortProperty] - b[sortProperty]);
    }
    return (array || []).sort((a, b) => (a[sortProperty].toLocaleLowerCase() > b[sortProperty].toLocaleLowerCase() ? 1 : -1));
};
const sortArrayInDescendingOrder = (array, sortProperty) => {
    return sortArrayInAscendingOrder(array, sortProperty).reverse();
};
const DrcSortUtilities = {
    SortArrayInAscendingOrder: sortArrayInAscendingOrder,
    SortArrayInDescendingOrder: sortArrayInDescendingOrder
};
export default DrcSortUtilities;
