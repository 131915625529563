import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setTransferList, setTransferInventory, setTransferHeader } from '../../actions/Transfer';
import { setTagNbr } from '../../actions/barcodeSearchAction';
import { notify } from '../../actions/NotificationAction';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import TransferNew from './TransferNew';
import AccordianList from '../../components/AccordianList';
import FloatingAddBtn from '../../components/FloatingAddBtn';
import { DrcCheckbox } from '@driscollsinc/driscolls-react-components';
import TagNumberSearch from '../../components/TagNumberSearch';
import { Typography } from '@material-ui/core';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import APIEndPoints from '../../services/api';
import cloneDeep from 'lodash/cloneDeep';
import { getLocalTrimAndLocation, makeColumns, formatData, formatKey, setColumnOrder, getTrimSeasonId } from '../../utils/helper';
import { getExcludedColumns } from '../../data/constants';
import { ReportUtility } from './../../utils/reportUtility';
import PrintDialog from './../../components/PrintDialog';
import { PrintUtility } from './../../utils/printUtility';

const styles = (theme) => ({
    main: {
        height: '90vh',
        borderRadius: '0'
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    search: {
        width: '95% '
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.5rem'
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '0.9rem',
        gridColumnEnd: 'span 4'
    },
    checkbox: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '3'
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        letterSpacing: '0.5px',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            flexBasis: '20%'
        }
    },
    labelStyle: {
        flex: 1,
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    subLabel: {
        flex: 3,
        color: theme.palette.grey[600],
        paddingLeft: '1em',
        borderLeft: '1px solid ' + theme.palette.text.secondary,
        wordBreak: 'break-word'
    },
    label: {
        letterSpacing: '0.2px',
        fontWeight: 500
    }
});
const pageTitle = 'Transfer';
class TransferList extends React.Component {
    state = {
        createNewTransfer: false,
        currentPageName: 'All Transfers',
        list: [],
        checkedAllTransfers: false,
        hasMoreData: true,
        totalRowCount: 0,
        pageSize: 25,
        locationId: getLocalTrimAndLocation().location.Code,
        printDialogOpen: false,
        printRow: ''
    };
    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        try {
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send(
                'Transfer',
                token,
                APIEndPoints.OPEN_TRANSFER_LINES(
                    0,
                    this.state.pageSize,
                    this.state.locationId,
                    this.state.checkedAllTransfers ? null : 'OPEN,RESERVED',
                    getTrimSeasonId().Id
                ),
                'GET',
                {}
            );
            this.props.setTransferList(response.Records);
            this.props.setTransferInventory([]);
            this.setState({ list: response.Records, totalRowCount: response.TotalRowCount, hasMoreData: true });
        } catch (err) {
            console.log(err);
        }
    }

    fetchDetails = async (tagNbr) => {
        try {
            let token = this.props.authState.accessToken;
            let openTransfer = true;
            let responseData = await Middleware.Send(
                'TransferInventory',
                token,
                APIEndPoints.TRANSFER_INVENTORY(
                    tagNbr,
                    getTrimSeasonId().Id,
                    this.state.checkedAllTransfers || this.props.tagNbr ? null : 'OPEN,RESERVED',
                    openTransfer,
                    this.state.locationId
                ),
                'GET',
                {}
            );
            let inventoryLines = responseData.TransferLines;
            let totalInventoryCount = responseData.TransferLines.length;
            let transferDataObj = responseData.TransferHeader;

            if (transferDataObj['TransferNbr']) {
                this.setState({
                    totalInventoryCount,
                    // transferDataObj,
                    inventoryLines,
                    pageType: this.props.location.pathname.includes('Receive') ? 'Receive' : 'Ship'
                });
                this.props.setTransferInventory(inventoryLines);
                this.props.setTransferHeader(transferDataObj);
                this.props.history.push(`/TransferInventory/${tagNbr}`);
            } else {
                this.props.setTagNbr('');
                this.props.notify('Invalid Transfer Number', true);
            }
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Transfer', true);
        }
    };

    clearSearch = (bool, row) => {
        // this.props.setTagNbr(bool ? row.TransferNbr : '');
    };

    toggleCreateTransfer = () => {
        this.setState({ createNewTransfer: !this.state.createNewTransfer });
    };

    redirect = (TransferNbr) => {
        this.props.history.push(`/Transfer/Edit/${TransferNbr}`);
    };

    handleTagNbrClick = (row) => {
        this.props.setTagNbr(row.TransferNbr);
        this.fetchDetails(row.TransferNbr);
    };

    handleAllTransfer = () => {
        this.setState({ checkedAllTransfers: !this.state.checkedAllTransfers, list: [] }, () => {
            this.props.setTransferList([]);
            this.loadAndUpdateData(0, this.state.pageSize);
        });
    };

    getHeaderPanel = (value, dataObj) => {
        const { classes, locationMap } = this.props;
        let locationId = dataObj['DestinationLocationId'];
        return (
            <>
                <div className={classes.labelContainer}>
                    <span
                        className={`${classes.labelValue} ${classes.labelStyle}`}
                        onClick={() => {
                            this.handleTagNbrClick(dataObj);
                        }}
                    >
                        {dataObj['TransferNbr']}
                    </span>
                    <span className={classes.subLabel}>{`Reference # : ${dataObj['ReferenceNbr'] ? dataObj['ReferenceNbr'] : ''}`}</span>
                </div>
                <div className={classes.label}>{`${locationId} - ${locationMap[locationId]}`}</div>
            </>
        );
    };

    async loadAndUpdateData(offset, limit, toSearch = false) {
        const { tagNbr } = this.props;
        try {
            let token = this.props.authState.accessToken;
            const url = toSearch
                ? APIEndPoints.SEARCH_OPEN_TRANSFER(tagNbr, this.state.locationId, this.state.checkedAllTransfers ? null : 'OPEN,RESERVED')
                : APIEndPoints.OPEN_TRANSFER_LINES(
                      offset,
                      limit,
                      this.state.locationId,
                      this.state.checkedAllTransfers ? null : 'OPEN,RESERVED',
                      getTrimSeasonId().Id
                  );
            let response = await Middleware.Send('Transfer', token, url, 'GET', {});
            let data = cloneDeep(this.props.transferList).concat(response.Records);
            this.props.setTransferList(data);
            this.setState({ list: data, totalRowCount: response.TotalRowCount, hasMoreData: true });
        } catch (err) {
            console.log(err);
        }
    }

    fetchMoreData = () => {
        const { transferList } = this.props;
        const { totalRowCount } = this.state;
        const lastRowAdded = transferList.length;
        if (lastRowAdded >= totalRowCount) {
            this.setState({ hasMoreData: false });
            return;
        }

        this.loadAndUpdateData(lastRowAdded, this.state.pageSize);
    };

    togglePrintDialog = () => {
        this.setState({ printDialogOpen: !this.state.printDialogOpen });
    };

    handlePrint = (row) => {
        if (!PrintUtility.GetPrintServer()) {
            this.props.notify('Printing report...', false);
            this.setState({ printRow: row }, async () => this.printRow());
            return;
        }

        this.setState({ printRow: row });
        this.togglePrintDialog();
    };

    printRow = async (numberOfCopies, printerAddress) => {
        const { printRow } = this.state;
        let token = this.props.authState.accessToken;
        try {
            await ReportUtility.PrintTransfer(token, printRow && printRow.TransferNbr, numberOfCopies, printerAddress);
            if (printerAddress) {
                this.togglePrintDialog();
                this.props.notify(`Submitted ${numberOfCopies} ${numberOfCopies > 1 ? 'copies' : 'copy'} to printer`, false);
            }
        } catch (err) {
            console.log(err);
            this.props.notify(`Unable to print report`, true);
        }
    };

    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }

        const { classes, transferList } = this.props;
        const { checkedAllTransfers, totalRowCount, printDialogOpen } = this.state;
        const subtext = `${transferList.length} of ${totalRowCount}`;

        return (
            <>
                {this.state.createNewTransfer ? (
                    <TransferNew close={this.toggleCreateTransfer} redirect={this.redirect} />
                ) : (
                    <>
                        <div className={classes.action}>
                            <TagNumberSearch
                                autoFocus
                                className={classes.search}
                                search={() => {
                                    this.fetchDetails(this.props.tagNbr);
                                }}
                                label={'Search by Transfer #'}
                            />
                            <FloatingAddBtn onClick={this.toggleCreateTransfer} action={'add'} />
                        </div>
                        <div className={classes.container}>
                            <Typography variant="subtitle1" gutterBottom className={classes.subText}>
                                {subtext}
                            </Typography>

                            <div>
                                <DrcCheckbox
                                    checked={checkedAllTransfers}
                                    label="All Transfers"
                                    name="All Transfers"
                                    value={'All Transfers'}
                                    onChange={this.handleAllTransfer}
                                    style={{ padding: '0px 0.2em 0px 0px' }}
                                />
                                All Transfers
                            </div>
                        </div>

                        <AccordianList
                            data={setColumnOrder(transferList, 'transferDetailsHeader')}
                            columns={makeColumns(transferList[0], [])}
                            fetchMoreData={this.fetchMoreData}
                            hasMoreData={this.state.hasMoreData}
                            labelFormatter={this.getHeaderPanel}
                            scrollable
                            // customExpand={(row) => '' + row.TransferNbr === tagNbr}
                            expandRight={false}
                            dataFormatter={formatData}
                            keyFormatter={formatKey}
                            excludeColumns={getExcludedColumns('transferDetailsHeader')}
                            checkEditable={(obj) => {
                                return obj.Status === 'OPEN' || obj.Status === 'RESERVED' ? 'right' : '';
                            }}
                            onEdit={(obj) => {
                                this.redirect(obj.TransferNbr);
                            }}
                            clearSearch={this.clearSearch}
                            checkPrintable={(row) => (row.Status === 'SHIPPED' || row.Status === 'RECEIVED' ? 'right' : null)}
                            onPrint={(row) => this.handlePrint(row)}
                        />
                    </>
                )}
                {this.state.printDialogOpen && (
                    <PrintDialog reportType={'Transfer'} onCancel={this.togglePrintDialog} onPrint={this.printRow} open={printDialogOpen} />
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr)),
    setTransferList: (data) => dispatch(setTransferList(data)),
    setTransferHeader: (data) => dispatch(setTransferHeader(data)),
    setTransferInventory: (data) => dispatch(setTransferInventory(data)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ masterReducer, rootReducer, barcodeSearchReducer, transfer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        tagNbr: barcodeSearchReducer.tagNbr,
        transferList: transfer.transferList,
        locationMap: masterReducer.locationMap,
        inventoryLines: transfer.inventoryLines
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransferList)));
