import { SET_PRINT_INVENTORY_DATA, SET_PRINT_QUANTITY } from '../actions/Print';
import { SET_PRINT_SERVERS } from './../actions/Print';

const initialState = {
    printInventoryLines: [],
    printQuantity: '1',
    printServers: []
};

const Print = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRINT_INVENTORY_DATA:
            return Object.assign({}, state, {
                printInventoryLines: action.payload
            });
        case SET_PRINT_QUANTITY:
            return Object.assign({}, state, {
                printQuantity: action.payload
            });
        case SET_PRINT_SERVERS:
            return Object.assign({}, state, {
                printServers: [...action.payload]
            });
        default:
            return state;
    }
};

export default Print;
