import { Middleware } from '@driscollsinc/one-ring';
const getTransferMapping = [
    Middleware.CreateMapping('TagNbr', 'TagNbr'),
    Middleware.CreateMapping('RefNbr', 'RefNbr'),
    Middleware.CreateMapping('Destination', 'Destination'),
    Middleware.CreateMapping('Variety', 'Variety'),
    Middleware.CreateMapping('Plant Code', 'Plant Code'),
    Middleware.CreateMapping('TrimType', 'TrimType'),
    Middleware.CreateArrayMapping('StockType', 'StockType'),
    Middleware.CreateMapping('Grade', 'Grade'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('Qty', 'Qty'),
    Middleware.CreateMapping('RATE', 'RATE'),
    Middleware.CreateMapping('Storage Location', 'Storage Location'),
    Middleware.CreateMapping('Attributes', 'Attributes'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const getListMapping = [
    Middleware.CreateMapping('TransferHeader', 'TransferHeader'),
    Middleware.CreateArrayMapping('InventoryLines', 'InventoryLines')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Transfer', 'GET')) {
        Middleware.RegisterMapping('Transfer', 'GET', getTransferMapping);
        Middleware.RegisterMapping('Transfer', 'POST', getListMapping);
        Middleware.RegisterMapping('Transfer', 'DELETE', getTransferMapping);
    }
}

const Transfer = {
    ConfigureMiddleware
};

export default Transfer;
