import { Middleware } from '@driscollsinc/one-ring';
const getUserPermissionsMapping = [
    Middleware.CreateMapping('id', 'id'),
    Middleware.CreateMapping('roleName', 'roleName'),
    Middleware.CreateMapping('securityItem', 'securityItem'),
    Middleware.CreateArrayMapping('userPermissions', 'userPermissions')
];

const getUserLocationsMapping = [
    Middleware.CreateMapping('id', 'id'),
    Middleware.CreateMapping('username', 'userName'),
    Middleware.CreateMapping('location', 'DC'),
    Middleware.CreateArrayMapping('userLocations', 'userLocations')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('userPermissions', 'GET')) {
        Middleware.RegisterMapping('userPermissions', 'GET', getUserPermissionsMapping);
    }
    if (!Middleware.CheckMappingExists('userLocations', 'GET')) {
        Middleware.RegisterMapping('userLocations', 'GET', getUserLocationsMapping);
    }
}

const TrimSeasonMapping = {
    ConfigureMiddleware
};

export default TrimSeasonMapping;
