import React, { Component } from 'react';
import { DrcLegend, DrcButton, DrcSelect, DrcMain, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from './../services/api';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import {
    makeOption,
    getLocalTrimAndLocation,
    setLocalTrimAndLocation,
    makeLocationOption,
    setLocalPrintServers
} from '../utils/helper';
import TrimSeason from '../components/TrimSeason';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { setPageTitleAction } from '../actions/actions';
import { setToNavBack } from '../actions/MasterActions';
import { setVariety } from '../actions/AdjustTrim';
import { setPrintServers } from '../actions/Print';
import { MaintenanceUtility } from './../utils/maintenanceUtility';
import DrcSortUtilities from './../utils/sortUtility';
import { notify } from '../actions/NotificationAction';

const styles = (theme) => ({
    dialogWrapper: {
        '& .MuiDialogActions-root': {
            justifyContent: 'center'
        }
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        marginBottom: `${theme.spacing(2)}vh`
    }
});
class Setup extends Component {
    state = {
        seasons: [],
        locations: [],
        TrimSeason: null,
        Location: null
    };

    async componentDidMount() {
        try {
            let localStoredData = getLocalTrimAndLocation() || {};
            let seasonsOptions = makeOption(this.props.trimSeasons, 'TrimSeason', 'Id');
            let TrimSeason =
                localStoredData.trimSeason && this.props.trimSeasons.map((each) => each.TrimSeason).includes(localStoredData.trimSeason)
                    ? localStoredData.trimSeason
                    : (seasonsOptions[0] || {}).TrimSeason;
            let locationOptions = makeLocationOption(this.filterLocations(), 'Code', 'Descript');
            let Location = localStoredData.location || locationOptions[0];
            this.setState({
                seasons: seasonsOptions,
                locations: locationOptions,
                TrimSeason,
                Location
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleSelectChange = (option, props) => {
        this.setState({ [props.name]: option });
    };

    handleClose = () => {
        this.props.history.push('/AdjustTrim/');
    };

    fetchPrintServers = async (location) => {
        const token = this.props.authState.accessToken;

        let printServers = await MaintenanceUtility.FetchPrintServers(token, null, location);
        setLocalPrintServers(printServers.Records);
        this.props.setPrintServers(printServers.Records);
    };

    getVarietyDetails = async (trimSeason) => {
        const token = this.props.authState.accessToken;

        let berry = this.props.trimSeasonMap[trimSeason];
        let varietyResponse = await Middleware.Send('Variety', token, APIEndPoints.VARIETY(berry), 'GET', {});
        let sortedVarietyList = DrcSortUtilities.SortArrayInAscendingOrder(varietyResponse.Data, 'Descript');
        this.props.setVariety(makeLocationOption(sortedVarietyList, 'Descript', 'Code'));
        let varietyMap = makeLocationOption(sortedVarietyList, 'Descript', 'Code').reduce((map, val) => {
            map[val.Code] = val.Descript;
            return map;
        }, {});
        localStorage.setItem('variety', JSON.stringify(makeLocationOption(sortedVarietyList, 'Descript', 'Code')));
        localStorage.setItem('varietyMap', JSON.stringify(varietyMap));
    };

    setData = async () => {
        setLocalTrimAndLocation(this.state.TrimSeason, this.state.Location);
        try {
            await this.fetchPrintServers(this.state.Location.Code);
            await this.getVarietyDetails(this.state.TrimSeason);
        } catch (err) {
            this.props.notify('An error occurred');
            console.log(err);
        }
        this.handleClose();
    };

    filterLocations = () => {
        const { locationsList, userLocations } = this.props;
        return (locationsList || []).filter((i) => (userLocations || []).some((u) => u.DC === i.Code));
    };

    render() {
        const { isMasterDataInitialized, classes } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        if (!this.props.distinctAccessKeys.length) {
            return <DrcPageNotAuthorized />;
        }
        return (
            <DrcMain handheld className={classes.container}>
                <DrcLegend
                    style={{
                        borderBottom: 'none',
                        fontSize: '1.25rem'
                    }}
                >
                    Setup
                </DrcLegend>
                <DrcSelect
                    label="Warehouse Location"
                    name="Location"
                    onChange={this.handleSelectChange}
                    value={this.state.Location}
                    options={this.state.locations}
                    isClearable={true}
                    required={true}
                    placeholder={'Select Location'}
                />
                <TrimSeason onSelect={this.handleSelectChange} trimSeason={this.state.TrimSeason} options={this.state.seasons} />
                <div className={classes.btnContainer}>
                    <DrcButton onClick={this.setData} isPrimary disabled={!(this.state.TrimSeason && this.state.Location)}>
                        Done
                    </DrcButton>
                </div>
            </DrcMain>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setToNavBack: (data) => dispatch(setToNavBack(data)),
    setPrintServers: (data) => dispatch(setPrintServers(data)),
    setVariety: (data) => dispatch(setVariety(data)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ masterReducer, userAccesses }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        locationsList: masterReducer.locationsList,
        userLocations: userAccesses.userLocations,
        trimSeasons: masterReducer.trimSeasons,
        trimSeasonMap: masterReducer.trimSeasonMap,
        distinctAccessKeys: userAccesses.distinctAccessKeys
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Setup)));
