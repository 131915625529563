import { SET_GROWER_STORAGE_NAV } from '../actions/GrowerStorage';
import { SET_SELECTED_GROWER, SET_GROWER_INVENTORY_FETCHING, SET_GROWERSTORAGE_ISSUANCE_EDIT_MODE } from './../actions/GrowerStorage';

const initialState = {
    isNavigated: false,
    grower: '',
    growerInventoryFetching: false,
    issuanceEditMode: false
};

const growerStorage = (state = initialState, action) => {
    switch (action.type) {
        case SET_GROWER_STORAGE_NAV:
            return Object.assign({}, state, {
                isNavigated: action.payload
            });
        case SET_SELECTED_GROWER:
            return Object.assign({}, state, {
                grower: action.payload
            });
        case SET_GROWER_INVENTORY_FETCHING:
            return Object.assign({}, state, {
                growerInventoryFetching: action.payload
            });
        case SET_GROWERSTORAGE_ISSUANCE_EDIT_MODE:
            return Object.assign({}, state, {
                issuanceEditMode: action.payload
            });
        default:
            return state;
    }
};

export default growerStorage;
