import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcMain, DrcTabs, DrcPanel, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import { Typography } from '@material-ui/core';
import GrowerAdjustTrim from './GrowerAdjustTrim';
import Issuance from '../Issuance/Issuance';
import GrowerDump from './GrowerDump';
import { checkPermission } from '../../utils/helper';

const PAGE_TITLE = 'Grower Storage';
const PAGE_KEY = 'GROWERSTORAGEHOMEKEY';
const ISSUANCE_KEY = 'GROWERSTORAGEISSUANCEKEY';
const ADJUST_KEY = 'GROWERSTORAGEADJUSTINVENTORYKEY';
const DUMP_KEY = 'GROWERSTORAGEDUMPKEY';
const useStyles = makeStyles(() => ({
    activeTabItem: {
        borderRadius: '0'
    }
}));
const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || [],
    window.config.OKTA_ENTITY_ADMIN || []
);
function GrowerStorage(props) {
    const classes = useStyles();
    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    const pageTitle = useSelector((state) => state.rootReducer.pageTitle);

    const dispatch = useDispatch();
    const [hasAccess, setAccess] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        var items = menuItems;
        if (checkPermission(ADJUST_KEY, props.distinctAccessKeys)) {
            items.push({
                tabName: 'Adjust Trim',
                component: GrowerAdjustTrim,
                route: 'AdjustTrim',
                groupsAllowed: allGroups
            });
        }
        if (checkPermission(ISSUANCE_KEY, props.distinctAccessKeys)) {
            items.push({
                tabName: 'Issuance',
                component: Issuance,
                route: 'Issuance',
                groupsAllowed: allGroups
            });
        }
        if (checkPermission(DUMP_KEY, props.distinctAccessKeys)) {
            items.push({
                tabName: 'Dump',
                component: GrowerDump,
                route: 'Dump',
                groupsAllowed: allGroups
            });
        }
        let menuItemsEnabled = [];
        if (items.length) {
            items[0].isDefault = true;
        }
        menuItemsEnabled = items;
        setMenuItems(menuItemsEnabled);
    }, []);
    useEffect(() => {
        if (pageTitle !== PAGE_TITLE) {
            dispatch(setPageTitleAction(PAGE_TITLE));
        }
        if (!checkPermission(PAGE_KEY, props.distinctAccessKeys)) {
            setAccess(false);
        } else {
            setAccess(true);
        }
    });

    if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    if (!hasAccess) {
        return <DrcPageNotAuthorized />;
    }
    return (
        <DrcMain transparent handHeld>
            <DrcPanel maxWidth="100%" style={{ border: '0', margin: '0' }}>
                <Typography variant="h6">Grower Storage</Typography>
                {menuItems.length ? <DrcTabs menuItems={menuItems} registerRoutes classes={classes} /> : "You don't have sufficient access"}
            </DrcPanel>
        </DrcMain>
    );
}

function mapStateToProps(state) {
    return {
        distinctAccessKeys: state.userAccesses.distinctAccessKeys
    };
}
export default connect(mapStateToProps)(GrowerStorage);
