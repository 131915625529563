import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('TagNbr', 'TagNbr'),
    Middleware.CreateMapping('ReferenceNbr', 'ReferenceNbr'),
    Middleware.CreateMapping('IssueToType', 'IssueToType'),
    Middleware.CreateMapping('IssueToNbr', 'IssueToNbr'),
    Middleware.CreateMapping('IssueDate', 'IssueDate'),
    Middleware.CreateMapping('TrimSeasonID', 'TrimSeasonID'),
    Middleware.CreateMapping('StorageType', 'StorageType'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('LastChangedBy', 'LastChangedBy'),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('TotalRowCount', 'TotalRowCount'),
    Middleware.CreateArrayMapping('Records', 'Records')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('IssuanceList', 'GET')) {
        Middleware.RegisterMapping('IssuanceList', 'GET', getListMapping);
    }
}

const IssuancePrintListMapping = {
    ConfigureMiddleware
};

export default IssuancePrintListMapping;
