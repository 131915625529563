import React from 'react';
import AccordianList from '../../components/AccordianList';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import FloatingAddBtn from '../../components/FloatingAddBtn';
import TagNumberSearch from '../../components/TagNumberSearch';
import { setTagNbr } from '../../actions/barcodeSearchAction';
import { setIssuanceHeader, setIssuanceList, setIssuanceInventory } from '../../actions/Issuance';
import NewIssuanceHeader from './NewIssuanceHeader';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import Avatar from '@material-ui/core/Avatar';
import { DrcCheckbox, DrcMain, DrcPanel, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import { setToNavBack } from '../../actions/MasterActions';
import { setGrowerStorageNav } from '../../actions/GrowerStorage';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import APIEndPoints from '../../services/api';
import cloneDeep from 'lodash/cloneDeep';
import { notify } from '../../actions/NotificationAction';
import { makeColumns, formatData, formatKey, setColumnOrder, getTrimSeasonId, getStoredLocation, checkPermission } from '../../utils/helper';
import { ReactComponent as StatusIndicator } from '../../assets/StatusIndicator.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import EcoIcon from '@material-ui/icons/Eco';
import { getExcludedColumns } from '../../data/constants';
import { setPageTitleAction } from '../../actions/actions';
import GrowerIssuance from '../GrowerStorage/GrowerIssuance';
import { ReportUtility } from '../../utils/reportUtility';
import { PrintUtility } from './../../utils/printUtility';
import PrintDialog from '../../components/PrintDialog';

const PAGE_KEY = 'ISSUANCEADMINKEY';
const GROWER_KEY = 'GROWERISSUANCEADMINKEY';
const NURSERY_KEY = 'NURSERYISSUANCEADMINKEY';
const styles = (theme) => ({
    panel: {
        marginBottom: '8vh',
        border: 0
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    search: {
        width: '95% '
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '0.9rem',
        paddingTop: '1rem',
        gridColumnEnd: 'span 4'
    },
    growerStorageStyle: {
        border: '0',
        padding: '0',
        marginTop: '-2vh'
    },
    statusIndicator: {
        fill: theme.palette.primary.light
    },
    cancelIndicator: {
        fill: theme.palette.error.main,
        color: theme.palette.error.main
    },
    reserveIndicator: {
        fill: theme.palette.grey[600],
        color: theme.palette.grey[600]
    },
    openIndicator: {
        fill: theme.palette.primary.light,
        color: theme.palette.primary.light
    },
    avtr: {
        backgroundColor: theme.palette.common.white,
        height: '17px',
        width: '17px',
        fontSize: '10px !important',
        lineHeight: '13px',
        marginLeft: '1rem'
    },
    avtrRed: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main
    },
    avtrGreen: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
    },
    labelContainer: {
        letterSpacing: '0.5px',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1)
    },
    labelStyle: {
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    label: {
        letterSpacing: '0.2px',
        fontWeight: 500,
        color: theme.palette.common.black,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    status: {
        color: theme.palette.grey[600],
        margin: '0 1em',
        paddingLeft: '1em',
        borderLeft: '1px solid ' + theme.palette.text.secondary
    },
    subLabel: {
        flex: 3,
        color: theme.palette.grey[600],
        paddingLeft: '1em',
        borderLeft: '1px solid ' + theme.palette.text.secondary,
        wordBreak: 'break-word'
    }
});

const pageTitle = 'Issuance';
class Issuance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createIssuance: false,
            currentPageName: '',
            hasMoreData: true,
            pageSize: 25,
            startIndex: 0,
            allChecked: false,
            hasAccess: false,
            hasGrowerAccess: false,
            hasNurseryAccess: false,
            isGrowerStorage: this.props.location.pathname.includes('GrowerStorage'),
            storageType: this.props.location.pathname.includes('GrowerStorage') ? 'GROWERSTORAGE' : 'INVENTORY',
            ignorePermissions: this.props.location.pathname.includes('GrowerStorage'),
            printDialogOpen: false,
            printRow: ''
        };
    }

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (!this.state.isGrowerStorage) {
            if (!checkPermission(PAGE_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasAccess: false });
            } else {
                this.setState({ hasAccess: true });
            }
            if (!checkPermission(GROWER_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasGrowerAccess: false });
            } else {
                this.setState({ hasGrowerAccess: true });
            }
            if (!checkPermission(NURSERY_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasNurseryAccess: false });
            } else {
                this.setState({ hasNurseryAccess: true });
            }
        } else {
            this.setState({ hasAccess: true });
            this.setState({ hasGrowerAccess: true });
            this.setState({ hasNurseryAccess: true });
        }
        this.props.setToNavBack(false);
        this.props.setGrowerStorageNav(this.props.location.pathname.includes('GrowerStorage'));

        try {
            if (getTrimSeasonId()) {
                let token = this.props.authState.accessToken;
                let response = await Middleware.Send(
                    'IssuanceList',
                    token,
                    APIEndPoints.ISSUANCE_LIST(
                        getTrimSeasonId().Id,
                        this.state.startIndex,
                        this.state.pageSize,
                        false,
                        this.state.storageType,
                        getStoredLocation().Code
                    ),
                    'GET',
                    {}
                );
                this.props.setIssuanceList(response.Records);
                this.props.setIssuanceHeader({});
                this.props.setIssuanceInventory([]);
                this.setState({ totalRowCount: response.TotalRowCount, hasMoreData: true });
            }
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Issuances', true);
        }
    }
    // async componentDidUpdate(prevProps, prevState) {
    //     const { tagNbr } = this.props;
    //     if (('' + tagNbr).length === 5 && prevProps.tagNbr !== this.props.tagNbr) {
    //         await this.fetchDetails(tagNbr);
    //     }
    // }

    fetchDetails = async (tagNbr) => {
        try {
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send(
                'Issuance',
                token,
                APIEndPoints.ISSUANCE_DETAIL(tagNbr, getTrimSeasonId().Id, this.state.storageType, getStoredLocation().Code),
                'GET',
                {}
            );
            let inventoryLines = Array.from(response.InventoryLines);
            let headerData = response.IssuanceHeader;
            if (!headerData) {
                return this.props.notify('TagNbr not found', true);
            }
            this.props.setIssuanceHeader(headerData);
            this.props.setIssuanceInventory(inventoryLines);
            if (this.state.isGrowerStorage) {
                this.props.history.push(`/GrowerStorage/Issuance/Grower/${headerData.TagNbr}`);
            } else {
                this.props.history.push(`/Issuance/${headerData.IssueToType}/${headerData.TagNbr}`);
            }
        } catch (err) {
            console.log(err);
            this.props.notify('Failed to fetch Issuances', true);
        }
    };
    async loadAndUpdateData(startIndex, pageSize) {
        const statusFlag = this.state.allChecked;
        try {
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send(
                'IssuanceList',
                token,
                APIEndPoints.ISSUANCE_LIST(getTrimSeasonId().Id, startIndex, pageSize, statusFlag, this.state.storageType, getStoredLocation().Code),
                'GET',
                {}
            );
            let data = cloneDeep(this.props.issuanceList).concat(response.Records);
            this.props.setIssuanceList(data);
            this.setState({ totalRowCount: response.TotalRowCount, hasMoreData: true });
        } catch (err) {
            console.log(err);
        }
    }

    toggleCreateIssuance = () => {
        this.setState({ createIssuance: !this.state.createIssuance, currentPageName: !this.state.createIssuance ? '' : 'ISSUANCE HEADER' });
    };

    clearSearch = (bool, row) => {
        if (!bool) this.props.setTagNbr('');
    };

    redirect = (row) => {
        const tagNbr = row.TagNbr;
        if (this.state.isGrowerStorage) {
            this.props.setIssuanceHeader(row);
            this.props.history.push(`/GrowerStorage/Issuance/Edit/${tagNbr}`);
        } else {
            this.props.history.push(`/Issuance/Edit/${tagNbr}`);
        }
    };

    headerFormatter = (value, row) => {
        const { classes } = this.props;
        const isGrower = row.IssueToType === 'Grower';
        return (
            <>
                <div className={classes.labelContainer}>
                    <span className={classes.labelStyle}>{`${value}`}</span>
                    <span className={classes.subLabel}>{`${row.Status}`}</span>
                    <Avatar className={`${classes.avtr} ${isGrower ? classes.avtrGreen : classes.avtrRed}`}>{isGrower ? 'G' : 'N'}</Avatar>
                </div>
                <div className={classes.label}>{`${row.IssueToNbr} - ${row.EntityName}`}</div>
            </>
        );
    };
    headerStatusFormatter = (val, row) => {
        const { classes } = this.props;
        switch (row.Status) {
            case 'SHIPPED':
                return (
                    <>
                        <span className={classes.statusIndicator}>
                            <StatusIndicator style={{ width: '1em', height: '1em' }} />
                        </span>
                    </>
                );
            case 'CANCELLED':
                return (
                    <>
                        <span className={classes.cancelIndicator}>
                            <CancelIcon style={{ width: '1em', height: '1em' }} />
                        </span>
                    </>
                );
            case 'RESERVED':
                return (
                    <>
                        <span className={classes.reserveIndicator}>
                            <ErrorIcon style={{ width: '1em', height: '1em' }} />
                        </span>
                    </>
                );
            case 'OPEN':
                return (
                    <>
                        <span className={classes.openIndicator}>
                            <EcoIcon style={{ width: '1em', height: '1em' }} />
                        </span>
                    </>
                );
            default:
                return null;
        }
    };

    handleAllIssuance = () => {
        this.props.setIssuanceList([]);
        this.setState({ allChecked: !this.state.allChecked }, () => {
            this.loadAndUpdateData(0, this.state.pageSize);
        });
    };

    fetchMoreData = async () => {
        const { issuanceList } = this.props;
        const { totalRowCount } = this.state;
        const lastRowAdded = issuanceList.length;
        if (lastRowAdded >= totalRowCount) {
            this.setState({ hasMoreData: false });
            return;
        }
        await this.loadAndUpdateData(lastRowAdded, this.state.pageSize);
    };

    handleTagNbrClick = (tagNbr) => {
        this.fetchDetails(tagNbr);
    };

    togglePrintDialog = () => {
        this.setState({ printDialogOpen: !this.state.printDialogOpen });
    };

    handlePrint = (row) => {
        if (!PrintUtility.GetPrintServer()) {
            this.props.notify('Printing report...', false);
            this.setState({ printRow: row }, async () => this.printRow());
            return;
        }

        this.setState({ printRow: row });
        this.togglePrintDialog();
    };

    printRow = async (numberOfCopies, printerAddress) => {
        const { printRow } = this.state;
        const issueToType = printRow.IssueToType;

        let token = this.props.authState.accessToken;

        try {
            if (this.state.isGrowerStorage) {
                await ReportUtility.PrintGrowerStorageIssuance(token, printRow && printRow.TagNbr, numberOfCopies, printerAddress);
            } else {
                if (issueToType === 'Grower') {
                    await ReportUtility.PrintGrowerIssuance(token, printRow && printRow.TagNbr, numberOfCopies, printerAddress);
                }
                if (issueToType === 'Nursery') {
                    await ReportUtility.PrintNurseryIssuance(token, printRow && printRow.TagNbr, numberOfCopies, printerAddress);
                }
            }

            if (printerAddress) {
                this.togglePrintDialog();
                this.props.notify(`Submitted ${numberOfCopies} ${numberOfCopies > 1 ? 'copies' : 'copy'} to printer`, false);
            }
        } catch (err) {
            console.log(err);
            this.props.notify(`Unable to print report`, true);
        }
    };

    render() {
        const { classes, isGrowerStorageNavigation, issuanceList, isMasterDataInitialized } = this.props;
        const { isGrowerStorage, printDialogOpen } = this.state;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }

        if (this.state.hasAccess) {
            return (
                <DrcMain transparent handHeld className={`${isGrowerStorageNavigation ? classes.growerStorageStyle : null}`}>
                    <DrcPanel className={`${classes.panel} ${isGrowerStorageNavigation ? classes.growerStorageStyle : null}`}>
                        {!isGrowerStorage ? <Typography variant="h6">Issuance</Typography> : null}
                        {this.state.createIssuance ? (
                            isGrowerStorage ? (
                                <GrowerIssuance
                                    createNewIssuance={true}
                                    onCancel={this.toggleCreateIssuance}
                                    hasGrowerAccess={this.state.hasGrowerAccess}
                                    hasNurseryAccess={this.state.hasNurseryAccess}
                                />
                            ) : (
                                <NewIssuanceHeader
                                    hasGrowerAccess={this.state.hasGrowerAccess}
                                    hasNurseryAccess={this.state.hasNurseryAccess}
                                    onCancel={this.toggleCreateIssuance}
                                    isGrowerStorage={isGrowerStorage}
                                />
                            )
                        ) : (
                            <>
                                <div className={classes.action}>
                                    <TagNumberSearch autoFocus className={classes.search} search={() => this.handleTagNbrClick(this.props.tagNbr)} />
                                    {(this.state.hasNurseryAccess || this.state.hasGrowerAccess) && (
                                        <FloatingAddBtn onClick={this.toggleCreateIssuance} />
                                    )}
                                </div>
                                <div className={classes.container}>
                                    <Typography variant="subtitle1" gutterBottom className={classes.subText}>
                                        {`${issuanceList.length} of ${this.state.totalRowCount || 0}`}
                                    </Typography>
                                    <div>
                                        <DrcCheckbox checked={this.state.allChecked} onChange={this.handleAllIssuance} />
                                        All Issuance
                                    </div>
                                </div>
                                <AccordianList
                                    columns={makeColumns(issuanceList[0], [])}
                                    data={setColumnOrder(issuanceList, 'issuanceHeader')}
                                    label="TagNbr"
                                    // value="Status"
                                    onLabelClick={(row) => this.handleTagNbrClick(row.TagNbr)}
                                    labelFormatter={this.headerFormatter}
                                    // valueFormatter={this.headerStatusFormatter}
                                    // customExpand={(row) => '' + row.TagNbr === tagNbr}
                                    clearSearch={this.clearSearch}
                                    fetchMoreData={this.fetchMoreData}
                                    hasMoreData={this.state.hasMoreData}
                                    dataFormatter={formatData}
                                    keyFormatter={formatKey}
                                    excludeColumns={getExcludedColumns('issuanceHeader')}
                                    expandRight={false}
                                    checkEditable={(row) => (row.Status === 'OPEN' || row.Status === 'RESERVED' ? 'right' : null)}
                                    onEdit={(row) => {
                                        this.redirect(row);
                                    }}
                                    scrollable
                                    checkPrintable={(row) => (row.Status === 'SHIPPED' ? 'right' : null)}
                                    onPrint={(row) => this.handlePrint(row)}
                                />
                            </>
                        )}
                        {this.state.printDialogOpen && (
                            <PrintDialog reportType={'Issuance'} onCancel={this.togglePrintDialog} onPrint={this.printRow} open={printDialogOpen} />
                        )}
                    </DrcPanel>
                </DrcMain>
            );
        } else {
            return <DrcPageNotAuthorized />;
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setToNavBack: (data) => dispatch(setToNavBack(data)),
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr)),
    setIssuanceHeader: (row) => dispatch(setIssuanceHeader(row)),
    setIssuanceInventory: (row) => dispatch(setIssuanceInventory(row)),
    setIssuanceList: (row) => dispatch(setIssuanceList(row)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setGrowerStorageNav: (isNavigated) => dispatch(setGrowerStorageNav(isNavigated))
});

function mapStateToProps({ userAccesses, masterReducer, rootReducer, barcodeSearchReducer, growerStorage, issuance }) {
    return {
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        tagNbr: barcodeSearchReducer.tagNbr,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        issuanceList: issuance.issuanceList
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Issuance)));
