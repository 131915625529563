import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('GrowerEntity', 'GrowerEntity'),
    Middleware.CreateMapping('GrowerEntity', 'GrowerEntity'),
    Middleware.CreateMapping('GrowingArea', 'GrowingArea'),
    Middleware.CreateMapping('GrowingDistrict', 'GrowingDistrict'),
    Middleware.CreateMapping('PickUpLocationId', 'PickUpLocationId'),
    Middleware.CreateMapping('VendorInvoiceAccNum', 'VendorInvoiceAccNum'),
    Middleware.CreateMapping('FieldType', 'FieldType'),
    Middleware.CreateMapping('Result', 'Result')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('GrowerEntities', 'GET')) {
        Middleware.RegisterMapping('GrowerEntities', 'GET', getListMapping);
    }
}

const GrowerEntitiesMapping = {
    ConfigureMiddleware
};

export default GrowerEntitiesMapping;
