import { DrcDialog, DrcButton } from '@driscollsinc/driscolls-react-components';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonCounter from './Counter';
import { connect } from 'react-redux';
import { isWholeNumber } from './../utils/helper';
import { notify } from '../actions/NotificationAction';
import { PrintUtility } from './../utils/printUtility';

let styles = () => ({
    root: {},
    dialog: {
        '& .MuiDialogActions-root': {
            width: '100%',
            justifyContent: 'center !important'
        }
    }
});

const PrintDialog = (props) => {
    const { onCancel, onPrint, open, reportType } = props;
    const [quantity, setQuantity] = useState(1);

    const handleSubmit = () => {
        const defaultPrinter = PrintUtility.GetPrintServer();
        if (!defaultPrinter) {
            props.notify('Printing report in new tab...', false);
            onPrint(quantity, null);
            return;
        }

        const printerAddress = `${defaultPrinter.PrintServer}:${defaultPrinter.Port}`;
        onPrint(quantity, printerAddress);
    };

    const handleQuantityChange = (val) => {
        setQuantity(isWholeNumber(val) && parseInt(val) !== 0 ? val : quantity);
    };

    return (
        <DrcDialog
            className={props.classes.dialog}
            title={`Print ${reportType}`}
            open={open}
            buttons={
                <>
                    <DrcButton onClick={onCancel}>Cancel</DrcButton>
                    <DrcButton isPrimary onClick={handleSubmit}>
                        Print
                    </DrcButton>
                </>
            }
        >
            <ButtonCounter counterLabel={'Number of Copies'} quantity={quantity} handleQuantityChange={handleQuantityChange} />
        </DrcDialog>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        notify: (data, isError) => dispatch(notify(data, isError))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrintDialog));
