import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('TrimType', 'TrimType'),
    Middleware.CreateArrayMapping('Result', 'Result')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('TrimType', 'GET')) {
        Middleware.RegisterMapping('TrimType', 'GET', getListMapping);
    }
}

const TrimTypeMapping = {
    ConfigureMiddleware
};

export default TrimTypeMapping;
