import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AccordianList from '../../components/AccordianList';
import { Typography } from '@material-ui/core';
import BarcodeIDSearch from '../../components/BarcodeIDSearch';
import { makeColumns, addEditableColumn, formatData, formatKey, setColumnOrder, isWholeNumber } from '../../utils/helper';
import { getExcludedColumns } from '../../data/constants';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import { ReactComponent as StatusIndicator } from '../../assets/StatusIndicator.svg';
import { DrcDialog, DrcButton } from '@driscollsinc/driscolls-react-components';

const styles = (theme) => ({
    root: {},
    container: {
        borderRadius: '2px',
        boxShadow: '1px 1px 4px 1px #F6F5F4',
        height: '40px',
        maxWidth: '98%',
        margin: '10px'
    },
    action: {
        margin: '3% 0 2%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    labelStyle: {
        flex: 1,
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    labelIndicator: {
        fill: theme.palette.primary.light
    },
    viewList: {
        color: '#4A773C',
        fontFamily: 'Rubik',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    }
});
class TransferInventoryLines extends Component {
    state = { showDialog: false, editedQuantity: null, originalQuantity: null };

    async componentDidMount() {
        try {
            // let token = await this.props.oktaAuth.getAccessToken();
            // let response = await Middleware.Send('Transfer', token, '', 'GET', {});
            // this.setState({ list: response.Result });
        } catch (err) {
            console.log(err);
        }
    }

    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool && row['BarcodeID'] ? row['BarcodeID'] : '');
    };

    getHeaderLabel = (value, dataObj) => {
        const { classes } = this.props;
        return (
            <>
                <span className={classes.labelStyle}>{dataObj['BarcodeID']}</span>
                {(dataObj.Status === 'PICKED' || dataObj.Status === 'RECEIVED') && (
                    <span className={classes.labelIndicator}>
                        <StatusIndicator style={{ width: '1em', height: '1em' }} />
                    </span>
                )}
            </>
        );
    };

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: value, originalQuantity: originalVal });
        }
    };

    handleSave = (row) => {
        let newQty = this.state.editedQuantity ?? row.ReceivedQty;
        this.setState({ editedQuantity: null, originalQuantity: null });
        if (parseInt(newQty) === 0 && this.props.pageType !== 'Receive') {
            this.setState({
                showDialog: true,
                message: 'Cannot pick when inventory quantity is 0'
            });
        } else {
            this.props.updateInventoryData(row, newQty);
        }
    };

    handleCancel = () => {
        this.setState({ editedQuantity: this.props.originalQuantity });
        this.clearSearch(false, {});
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    render() {
        const { classes, barcodeId, inventoryLinesData, isPickEnabled, ...rest } = this.props;
        const newQty = { ...rest }.pageType === 'Receive' ? 'ReceiveQty' : 'PickQty';
        const inventoryData = isPickEnabled
            ? addEditableColumn(inventoryLinesData, newQty, 'ReceivedQty', 'transferInventory')
            : setColumnOrder(inventoryLinesData, 'transferInventory');

        const editableField = isPickEnabled
            ? [
                  {
                      label: newQty,
                      editType: 'text'
                  }
              ]
            : [];

        return (
            <>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {inventoryData.length ? 'Inventory' : 'No Inventory'}
                </Typography>
                {inventoryData.length ? <BarcodeIDSearch /> : null}
                <AccordianList
                    data={barcodeId === '' ? inventoryData : inventoryData.filter((row) => '' + row['BarcodeID'] === '' + barcodeId)}
                    customExpand={(row = {}) => '' + row['BarcodeID'] === '' + barcodeId}
                    columns={makeColumns(inventoryData[0], editableField, this.editInput, this.getValue)}
                    labelFormatter={this.getHeaderLabel}
                    value={'ReceivedQty'}
                    scrollable
                    clearSearch={this.clearSearch}
                    onSave={this.handleSave}
                    onCancel={this.handleCancel}
                    defaultEdit={{ ...rest }.defaultEdit}
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    excludeColumns={getExcludedColumns('transferInventory')}
                >
                    {isPickEnabled ? ({ ...rest }.pageType === 'Receive' ? 'Receive' : 'Pick') : null}
                </AccordianList>
                <DrcDialog
                    classes={classes}
                    title={<>{this.state.message}</>}
                    open={this.state.showDialog}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showDialog: false });
                                }}
                            >
                                Ok
                            </DrcButton>
                        </>
                    }
                ></DrcDialog>
            </>
        );
    }
}

function mapStateToProps({ barcodeSearchReducer, transfer }) {
    return {
        barcodeId: barcodeSearchReducer.barcodeId,
        inventoryLinesData: transfer.inventoryLines,
        transferHeaderData: transfer.transferHeader
    };
}

const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (id) => dispatch(setBarcodeId(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransferInventoryLines));
