import React from 'react';
import { Redirect } from 'react-router-dom';
import { Middleware } from '@driscollsinc/one-ring';
import LoggingUtilities from './LoggingUtilities';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import APIEndPoints from '../services/api';
import { getLocalTrimAndLocation, makeLocationOption, makeOption } from '../utils/helper';

const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;

const masterDataCalls = [
    {
        name: 'Locations',
        endpoint: APIEndPoints.WAREHOUSE + '?isactive=true',
        weight: 1,
        returnFunction: (data, props) => {
            props.setLocationsList(data.Data);
        },
        method: 'GET'
    },
    {
        name: 'TrimSeasons',
        endpoint: APIEndPoints.TRIM_SEASONS,
        weight: 1,
        returnFunction: (data, props) => {
            props.setTrimSeasonList(data.Result);
        },
        method: 'GET'
    },
    {
        name: 'TrimType',
        endpoint: APIEndPoints.TRIM_TYPE,
        weight: 1,
        returnFunction: (data, props) => {
            props.setTrimTypeList(makeOption(data.Result, 'TrimType', 'Id', true));
        },
        method: 'GET'
    },
    {
        name: 'GrowerEntities',
        endpoint: APIEndPoints.GROWER_ENTITIES,
        weight: 1,
        returnFunction: (data, props) => {
            props.setGrowerEntities(makeLocationOption(data.Result, 'Id', 'GrowerEntity'));
        },
        method: 'GET'
    },
    {
        name: 'NurseryEntities',
        endpoint: APIEndPoints.NURSERY_ENTITIES,
        weight: 1,
        returnFunction: (data, props) => {
            props.setNurseryEntities(makeLocationOption(data.Result, 'Id', 'Name'));
        },
        method: 'GET'
    }
];

const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

var lastMasterDataLoadTime = null;
var hasLoggedMasterDataWarning = false;
var hasLoggedMasterDataInfo = false;
var redirectLocationReducerFunction = () => {
    // write functionality
};

var getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    // TODO: debug columnNamesMapping
    var apiCalls = [];

    const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
    props.setIsAdmin(DuAuthenticationUtilities.IsInGroup(token, adminGroups));

    const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super') || g.toLowerCase().includes('app'));
    props.setIsSuperAdmin(DuAuthenticationUtilities.IsInGroup(token, superAdminGroups));

    masterDataCalls.forEach((call) => {
        var middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);

        middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

var checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

var redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Redirect to="/InitializeApplication/" />;
};

var registerMasterDataReducerVariables = (setMasterDataRedirectLocation) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
};

var getSetup = () => {
    return getLocalTrimAndLocation().location;
};

var redirectToSetup = () => {
    return <Redirect to="/Setup/" />;
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables,
    GetSetup: getSetup,
    RedirectToSetup: redirectToSetup
};

export default MasterDataUtilities;
