import { Middleware } from '@driscollsinc/one-ring';
const getTransferMapping = [
    Middleware.CreateMapping('Group ID', 'Group ID'),
    Middleware.CreateMapping('Variety', 'Variety'),
    Middleware.CreateMapping('Plant Code', 'Plant Code'),
    Middleware.CreateMapping('TrimType', 'TrimType'),
    Middleware.CreateMapping('StockType', 'StockType'),
    Middleware.CreateMapping('Grade', 'Grade'),
    Middleware.CreateMapping('UOM', 'UOM'),
    Middleware.CreateMapping('Qty', 'Qty'),
    Middleware.CreateMapping('RATE', 'RATE'),
    Middleware.CreateMapping('Storage Location', 'Storage Location'),
    Middleware.CreateMapping('Attributes', 'Attributes'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Transfer', 'GET')) {
        Middleware.RegisterMapping('Transfer', 'GET', getTransferMapping);
    }
}

const TransferInventory = {
    ConfigureMiddleware
};

export default TransferInventory;
