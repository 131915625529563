import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

var styles = () => ({
    key: {
        textAlign: 'right',
        padding: '4px',
        width: '50%'
    },
    value: {
        fontWeight: 'bold',
        padding: '4px',
        wordBreak: 'break-word'
    },
    tooltip: {
        fontSize: '1rem'
    },
    tableContainer: {
        width: '100%'
    }
});

class DrcKeyValueTable extends React.Component {
    renderRow = (row) => {
        var { classes } = this.props;

        return (
            <tr key={row.key}>
                <td className={classes.key}>{row.key} </td>

                <td>
                    {row.tooltip ? (
                        <Tooltip title={row.tooltip ? <React.Fragment>{row.tooltip} </React.Fragment> : null} className={classes.tooltip}>
                            <Typography style={{ cursor: 'auto' }}>{row.value}</Typography>
                        </Tooltip>
                    ) : (
                        <td className={classes.value}>{row.value}</td>
                    )}
                </td>
            </tr>
        );
    };

    render() {
        const { children, classes } = this.props;

        return (
            <table className={classes.tableContainer}>
                <tbody>{(children || []).map((row) => this.renderRow(row))}</tbody>
            </table>
        );
    }
}

export default withStyles(styles)(DrcKeyValueTable);
