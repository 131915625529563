export const SET_DUMP_LIST = 'SET_DUMP_LIST';
export const setDumpList = (data) => ({
    type: SET_DUMP_LIST,
    payload: data
});

export const SET_DUMP_HEADER = 'SET_DUMP_HEADER';
export const setDumpHeader = (data) => ({
    type: SET_DUMP_HEADER,
    payload: data
});

export const SET_DUMP_INVENTORY = 'SET_DUMP_INVENTORY';
export const setDumpInventory = (data) => ({
    type: SET_DUMP_INVENTORY,
    payload: data
});

export const UPDATE_INVENTORY_STATUS = 'UPDATE_INVENTORY_STATUS';
export const updateInventoryStatus = (data) => ({
    type: UPDATE_INVENTORY_STATUS,
    payload: data
});
