import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcInput, DrcButton, DrcDatePicker, DrcSelect, DrcDialog } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { Middleware } from '@driscollsinc/one-ring';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { setIssuanceHeader, setIssuanceInventory } from '../../actions/Issuance';
import { setFields } from '../../actions/MasterActions';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import APIEndPoints from '../../services/api';
import {
    getStoredLocation,
    getTrimSeasonId,
    getGrowerDistrict,
    toISOdateString,
    addTimeStampToDate,
    getGrowerEntities,
    isSuccessful
} from '../../utils/helper';
import { notify } from '../../actions/NotificationAction';

const pageTitle = 'Issuance';
const styles = (theme) => ({
    root: {
        minWidth: '0'
    },
    title: {
        '& h2': {
            fontSize: '1rem'
        }
    },
    wrapper: {
        marginTop: '5px'
    },
    buttonContainer: {
        width: '100%',
        marginTop: '1rem',
        background: 'transparent',
        height: '50px',
        bottom: '0px'
    },
    flexContainer: {
        display: 'flex'
    },
    spaceContainer: {
        paddingRight: '10px'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        },
        fontSize: 'medium'
    }
});

class NewIssuanceHeader extends React.Component {
    constructor(props) {
        super(props);
        const { IssueToType, IssueDate, EntityName, IssueToNbr, ReferenceNbr, Comments, TagNbr, Status } = {
            headerData: {},
            ...this.props.headerData
        };
        this.state = {
            entity: IssueToType ? IssueToType : 'Grower',
            issueDate: IssueDate ? new Date(IssueDate) : new Date(),
            destination: EntityName
                ? { label: `${IssueToNbr}-${EntityName}`, value: IssueToNbr, Id: IssueToNbr, GrowerEntity: EntityName, Name: EntityName }
                : null,
            source: getStoredLocation(),
            headerData: [],
            tag: TagNbr || null,
            reference: ReferenceNbr || null,
            comment: Comments || null,
            status: Status || 'OPEN',
            showConfirmationDialog: false,
            growerEntity: props.isGrowerStorageNavigation ? props.growerEntity : null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.growerEntity !== this.props.growerEntity) {
            this.setState({ growerEntity: this.props.growerEntity, entity: 'Grower' });
        }
    }
    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (this.props.hasGrowerAccess && this.props.hasNurseryAccess) {
            this.setState({ entity: 'Grower' });
        } else if (this.props.hasGrowerAccess) {
            this.setState({ entity: 'Grower' });
        } else if (this.props.hasNurseryAccess) {
            this.setState({ entity: 'Nursery' });
        }
    }

    handleInput = (fieldName) => (event) => {
        this.setState({ [fieldName]: event.target.value });
    };

    handleEntity = async (event) => {
        let entity = event.target.value;
        this.setState({ entity, destination: null });
    };

    handleSelectChange = (name) => (option) => {
        if (this.state.entity === 'Nursery' || option.PickUpLocationId === this.state.source.Code) {
            this.setState({ [name]: option });
        } else {
            this.setState({ showConfirmationDialog: true, [name]: option });
        }
    };

    getHeaderData = async () => {
        const { tag, reference, entity, destination, issueDate, comment, status } = this.state;
        let destinationDetails = destination;
        if (this.props.isGrowerStorageNavigation && destinationDetails) {
            destinationDetails.Id = this.state.growerEntity.Id;
            destinationDetails.GrowerEntity = this.state.growerEntity.GrowerEntity;
        }

        if (!destinationDetails && this.state.growerEntity) {
            destinationDetails = this.state.growerEntity;
        }
        let token = this.props.authState.accessToken;
        return {
            IssueToType: entity,
            LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
            Comments: comment,
            TrimSeasonID: getTrimSeasonId().Id,
            IssueToNbr: destinationDetails.Id,
            EntityName: this.state.entity === 'Grower' ? destinationDetails.GrowerEntity : destinationDetails.Name,
            IssueDate: toISOdateString(issueDate),
            StorageType: this.props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY',
            CreatedBy: DuAuthenticationUtilities.GetUserId(token),
            LastChangedDateTime: new Date().toISOString(),
            TagNbr: tag,
            CreatedDateTime: new Date().toISOString(),
            ReferenceNbr: reference,
            Status: status,
            InventoryLocation: getStoredLocation().Code,
            ...{
                ...(this.state.entity === 'Grower'
                    ? {
                          PickUpLocationId: getGrowerDistrict(destinationDetails.GrowerEntity).PickUpLocationId,
                          GrowingDistrict: getGrowerDistrict(destinationDetails.GrowerEntity).GrowingDistrict,
                          VendorInvoiceAccNum: getGrowerDistrict(destinationDetails.GrowerEntity).VendorInvoiceAccNum
                      }
                    : {})
            }
        };
    };
    createIssuanceHeader = async () => {
        if (getTrimSeasonId()) {
            try {
                let token = this.props.authState.accessToken;
                let IssuanceHeader = await this.getHeaderData();
                //API call to save the header details
                let response = await Middleware.Send('Issuance', token, APIEndPoints.CREATE_ISSUANCE, 'POST', {
                    IssuanceHeader,
                    InventoryLines: []
                });
                if (isSuccessful(response.Message)) {
                    this.props.setIssuanceHeader(IssuanceHeader);
                    this.props.setFields({
                        label: 'BarcodeID',
                        value: 'Qty',
                        newEditableField: 'Qty'
                    });

                    if (this.props.isGrowerStorageNavigation) {
                        this.props.notify(`Issuance Header ${IssuanceHeader.TagNbr ? 'Updated' : 'Created'}`, false);
                        this.props.history.push(`/GrowerStorage/Issuance/Edit/${response.TagNbr}`);
                    } else {
                        this.props.notify(`Issuance Header ${(this.props.data || {}).TagNbr ? 'Updated' : 'Created'}`, false);
                        this.props.history.push(`/Issuance/Edit/${response.TagNbr}`);
                    }
                } else {
                    if (response.Message) {
                        this.props.notify(response.Message, true);
                        this.setState({ destination: null });
                    } else {
                        this.props.notify(`Failed to ${(this.props.data || {}).TagNbr ? 'Update' : 'Create'} Issuance Header`, true);
                    }
                }
                this.props.onSave && this.props.onSave();
            } catch (err) {
                console.error(err);
                this.props.notify(`Failed to ${(this.props.data || {}).TagNbr ? 'Update' : 'Create'} Issuance Header`, true);
            }
        }
    };

    handleDateChange = (dateObj) => {
        this.setState({ issueDate: dateObj });
    };
    isFormValid = () => (this.props.isGrowerStorageNavigation ? !this.state.growerEntity : !(this.state.entity && this.state.destination));
    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        const { data = {}, classes, isGrowerStorageNavigation } = this.props;
        const isEditable = data.TagNbr;
        return (
            <>
                {!isGrowerStorageNavigation ? (
                    <>
                        {this.props.location.pathname.includes(`/Edit`) ? (
                            <>
                                <Typography gutterBottom variant="h6" className={classes.heading}>
                                    Header
                                </Typography>
                                <div>Entity Type: {data.IssueToType}</div>
                            </>
                        ) : (
                            <>
                                <Typography gutterBottom variant="h6" className={classes.heading}>
                                    New Issuance
                                </Typography>
                                <FormControl component="fieldset" className={classes.wrapper} required>
                                    <FormLabel component="legend">Entity Type</FormLabel>

                                    <RadioGroup aria-label="gender" name="trimSeason" value={this.state.entity} onChange={this.handleEntity} row>
                                        {this.props.hasGrowerAccess && (
                                            <FormControlLabel
                                                control={<Radio color="default" />}
                                                value="Grower"
                                                label="Grower"
                                                disabled={isEditable}
                                            />
                                        )}
                                        {this.props.hasNurseryAccess && (
                                            <FormControlLabel
                                                control={<Radio color="default" />}
                                                value="Nursery"
                                                label="Nursery"
                                                disabled={isEditable}
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </>
                        )}
                        <div className={classes.flexContainer}>
                            <DrcInput
                                label={'Tag #'}
                                // onChange={this.handleInput('tag')}
                                className={classes.spaceContainer}
                                value={this.state.tag}
                                variant="filled"
                                disabled
                                // required
                            />
                            <DrcInput
                                label={'Ref #'}
                                onChange={this.handleInput('reference')}
                                value={this.state.reference}
                                disabled={!this.state.entity}
                                inputProps={{ maxLength: 50 }}
                            />
                        </div>
                        <DrcInput label={'Source'} value={`${this.state.source.Code}-${this.state.source.Descript}`} disabled={true} />
                        <DrcSelect
                            label={this.state.entity}
                            onChange={this.handleSelectChange('destination')}
                            value={this.state.destination}
                            options={this.state.entity === 'Grower' ? getGrowerEntities(this.props.growerEntities) : this.props.nurseryEntities}
                            isDisabled={!this.state.entity}
                            isWindowedSelect
                            ignoreAccents={true}
                            required
                            maxMenuHeight={window.innerHeight * 0.35}
                        />
                        <DrcDatePicker
                            clearable={false}
                            value={this.state.issueDate || new Date()}
                            label={'Issue Date'}
                            onChange={(date) => {
                                this.handleDateChange(addTimeStampToDate(date), 'issueDate');
                            }}
                            disabled={!this.state.entity}
                            InputProps={{ readOnly: true }}
                        />
                    </>
                ) : (
                    <DrcInput label={'Ref #'} onChange={this.handleInput('reference')} value={this.state.reference} inputProps={{ maxLength: 50 }} />
                )}
                <DrcInput
                    label={'Comment'}
                    onChange={this.handleInput('comment')}
                    value={this.state.comment}
                    disabled={!this.state.entity && !this.props.isGrowerStorageNavigation}
                    inputProps={{ maxLength: 255 }}
                />
                <div className={`${classes.flexContainer} ${classes.buttonContainer}`}>
                    <DrcButton onClick={this.props.onCancel} isSecondary>
                        Cancel
                    </DrcButton>
                    <DrcButton onClick={this.createIssuanceHeader} isPrimary disabled={this.isFormValid()}>
                        {isEditable ? 'Update' : 'Create'}
                    </DrcButton>
                </div>
                <DrcDialog
                    classes={classes}
                    title="Grower Entity Pickup Location is different from your login warehouse."
                    open={this.state.showConfirmationDialog}
                    buttons={
                        <DrcButton isPrimary onClick={() => this.setState({ showConfirmationDialog: false })}>
                            Ok
                        </DrcButton>
                    }
                ></DrcDialog>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setIssuanceHeader: (header) => dispatch(setIssuanceHeader(header)),
    setIssuanceInventory: (data) => dispatch(setIssuanceInventory(data)),
    setFields: (obj) => dispatch(setFields(obj)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ issuance, growerStorage, masterReducer, rootReducer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        headerData: issuance.header,
        growerEntities: masterReducer.growerEntities,
        nurseryEntities: masterReducer.nurseryEntities,
        isGrowerStorageNavigation: growerStorage.isNavigated
    };
}
export default withOktaAuth(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewIssuanceHeader))));
