import { Middleware } from '@driscollsinc/one-ring';

const printServerListMapping = [
    Middleware.CreateMapping('ID', 'ID'),
    Middleware.CreateMapping('PrinterLocation', 'PrinterLocation'),
    Middleware.CreateMapping('PrintFormat', 'PrintFormat'),
    Middleware.CreateMapping('PrintServer', 'PrintServer'),
    Middleware.CreateMapping('Port', 'Port'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreationDateTime', 'CreationDateTime'),
    Middleware.CreateMapping('LastChangedBy', 'LastChangedBy'),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime'),
    Middleware.CreateArrayMapping('Records', 'Records'),
    Middleware.CreateMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PrintServerList', 'GET')) {
        Middleware.RegisterMapping('PrintServerList', 'GET', printServerListMapping);
    }
    if (!Middleware.CheckMappingExists('PrintServer', 'POST')) {
        Middleware.RegisterMapping('PrintServer', 'POST', printServerListMapping);
    }
}

const MaintenanceMapping = {
    ConfigureMiddleware
};

export default MaintenanceMapping;
