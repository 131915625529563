import React, { Component } from 'react';
import AccordianList from './AccordianList';
import BarcodeIdSearch from './BarcodeIDSearch';
import APIEndPoints from '../services/api';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setBarcodeId } from '../actions/barcodeSearchAction';
import { Middleware } from '@driscollsinc/one-ring';
import {
    makeColumns,
    getEditableFieldType,
    addEditableColumn,
    getStoredLocation,
    formatData,
    formatKey,
    getStoredTrim,
    makeLocationOption,
    isWholeNumber
} from '../utils/helper';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { withStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { getExcludedColumns } from '../data/constants';
import { notify } from '../actions/NotificationAction';
import { DrcDialog, DrcButton, DrcSelect } from '@driscollsinc/driscolls-react-components';
import { setReturnInventory } from '../actions/Return';
import { setTransferInventory } from '../actions/Transfer';
import InventorySearch from './InventorySearch';
import InventoryUtility from './../utils/inventoryUtility';

const styles = () => ({
    root: {
        minWidth: '250px'
    },
    title: {
        fontSize: '1rem'
    }
});

class AddInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDialog: false,
            showReasonCodeDialog: false,
            showDelete: false,
            message: '',
            inventory: {},
            updateQty: '',
            pathName: props.pathName,
            inventoryLines: [],
            editedQuantity: null,
            originalQuantity: null,
            barcodeId: '',
            reasonCode: null,
            inventorySearchLines: []
        };
    }

    componentDidMount() {
        let inventoryLines = this.state.pathName === 'Transfer' ? this.props.transferLines : this.props.returnLines;
        this.setState({ inventoryLines: inventoryLines });
    }

    getInventoryData = async (barcodeId) => {
        try {
            let token = this.props.authState.accessToken;
            let locationId = getStoredLocation().Code;
            switch (this.state.pathName) {
                case 'Transfer':
                    let trimSeason = getStoredTrim();
                    let berry = this.props.trimSeasonMap[trimSeason];
                    let responseData = await Middleware.Send(
                        'PlantInventory',
                        token,
                        APIEndPoints.GET_PLANT_INVENTORY(locationId, barcodeId, berry, 'INVENTORY'),
                        'GET',
                        {}
                    );
                    return responseData;
                case 'Return':
                    let { ReturnedFromNbr, TrimSeasonID } = this.props.returnHeader;
                    let issuanceDetails = await Middleware.Send(
                        'PlantInventory',
                        token,
                        APIEndPoints.GET_ISSUANCE_DETAILS(barcodeId, ReturnedFromNbr, TrimSeasonID, 'INVENTORY', 'Grower', 'SHIPPED', locationId),
                        'GET',
                        {}
                    );
                    return issuanceDetails.Records;
                default:
                    return [];
            }
        } catch (err) {
            console.log(err);
        }
    };

    async componentDidUpdate(prevProps, prevState) {
        const { barcodeId } = this.props;
        let inventoryLines = this.state.pathName === 'Transfer' ? this.props.transferLines : this.props.returnLines;
        if (this.props.transferLines !== prevProps.transferLines || this.props.returnLines !== prevProps.returnLines) {
            this.setState({ inventoryLines: inventoryLines });
        }
        const isPresent = this.state.inventoryLines.find((itm) => itm['BarcodeID'] === barcodeId);
        if (barcodeId.length === 10 && prevProps.barcodeId !== barcodeId && !isPresent) {
            let inventoryArr = [...inventoryLines];
            let inventoryData = await this.getInventoryData(barcodeId);
            if (Array.isArray(inventoryData) && inventoryData.length) {
                inventoryArr.push(inventoryData[0]);
                this.setState({ inventoryLines: inventoryArr, barcodeId: barcodeId });
            } else {
                this.setState({ barcodeId: '' });
                this.props.setBarcodeId('');
                this.props.notify(`No data available for ${barcodeId} Inventory.`, true);
            }
        }
    }

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: value, originalQuantity: originalVal });
        }
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    handleSave = (row) => {
        let updateQty =
            (this.state.editedQuantity !== null && +this.state.editedQuantity === 0) || (this.state.editedQuantity === null && row.Qty === 0)
                ? 0
                : +(this.state.editedQuantity ?? row.Qty);

        switch (this.state.pathName) {
            case 'Transfer':
                if (parseInt(updateQty) === 0) {
                    this.setState({
                        showConfirmDialog: true,
                        showDelete: true,
                        message: 'Ordered Qty: 0. Do you want to Save or Delete?',
                        inventory: row,
                        updateQty: updateQty
                    });
                } else {
                    this.props.updateInventoryData(row, updateQty);
                    this.setState({ editedQuantity: null, originalQuantity: null });
                    this.clearSearch(false, {});
                }
                break;
            case 'Return':
                if (parseInt(updateQty) === 0) {
                    this.setState({
                        showConfirmDialog: true,
                        showReasonCodeDialog: true,
                        showDelete: true,
                        message: 'Ordered Qty: 0. Do you want to Save or Delete?',
                        inventory: row,
                        updateQty: updateQty
                    });
                } else {
                    this.setState({
                        showConfirmDialog: true,
                        showReasonCodeDialog: true,
                        message: 'Please Select Reason Code',
                        inventory: row,
                        updateQty: updateQty
                    });
                }

                break;
            default:
                this.props.updateInventoryData(row, updateQty);
                this.setState({ editedQuantity: null, originalQuantity: null });
                this.clearSearch(false, {});
        }
    };

    handleSelect = (row) => {
        this.setState({ reasonCode: row });
    };

    handleCancel = (row) => {
        const { inventoryLines, pathName } = this.state;
        if ((pathName === 'Transfer' && !row.TransferNbr) || (pathName === 'Return' && !row.LogNbr)) {
            let inventoryItems = cloneDeep(inventoryLines).filter((data) => data.BarcodeID !== row.BarcodeID);
            this.setState({ inventoryLines: inventoryItems });
            if (pathName === 'Return') {
                this.props.setReturnInventory(inventoryItems);
            }
            if (pathName === 'Transfer') {
                this.props.setTransferInventory(inventoryItems);
            }
        }
        this.setState({ editedQuantity: null, originalQuantity: null });
        this.clearSearch(false, {});
    };

    handleDelete = (dataObj) => {
        const { pathName } = this.state;

        this.setState({ editedQuantity: null, originalQuantity: null });
        this.clearSearch(false, {});

        if (pathName === 'Transfer' || pathName === 'Return') {
            this.props.handleDelete(dataObj);
        }
    };

    handleCloseDialog = () => {
        const { showDelete, inventory, updateQty } = this.state;
        this.toggleConfirmDialog();
        if (showDelete && (inventory.TransferNbr || inventory.LogNbr)) {
            this.handleCancel(inventory);
            this.props.handleDelete(inventory, updateQty, false);
        }
    };

    handleConfirm = () => {
        const { inventory, updateQty, reasonCode } = this.state;
        this.toggleConfirmDialog();
        this.props.updateInventoryData(inventory, updateQty, reasonCode);
        this.setState({ editedQuantity: null, originalQuantity: null });
        this.clearSearch(false, {});
    };

    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool ? row['BarcodeID'] : '');
    };

    toggleConfirmDialog = () => {
        this.setState({ showConfirmDialog: !this.state.showConfirmDialog });
    };

    handleInventorySearch = (options) => {
        const { variety, trimType, stockType } = options;
        const locationId = getStoredLocation().Code;
        const trimSeason = getStoredTrim();
        const berry = this.props.trimSeasonMap[trimSeason];
        let { ReturnedFromNbr, TrimSeasonID } = this.props.returnHeader;
        const filterObj = {
            variety: variety ? variety.Code : null,
            trimtype: trimType ? trimType.TrimType : null,
            stocktype: this.state.pathName === 'Return' ? 'COMMERCIAL' : stockType ? (stockType.value === 'ALL' ? null : stockType.value) : null,
            locationId: locationId,
            berry: berry,
            barcodeId: '',
            issueToNbr: ReturnedFromNbr,
            trimSeasonId: TrimSeasonID
        };

        this.searchPlantInventory(filterObj);
    };
    searchPlantInventory = (filterObj) => {
        let token = this.props.authState.accessToken;

        const cb = (inventoryResponse) => {
            if (inventoryResponse && inventoryResponse.length) {
                inventoryResponse.forEach((item) => {
                    delete item.ID;
                });

                this.setState({ inventorySearchLines: inventoryResponse });
            } else {
                this.props.notify('Not found', true);
                this.setState({ inventorySearchLines: [] });
                this.props.setBarcodeId('');
            }
        };
        this.state.pathName === 'Transfer'
            ? InventoryUtility.SearchPlantInventory(token, filterObj).then((inventoryResponse) => cb(inventoryResponse))
            : InventoryUtility.SearchIssuanceInventory(token, filterObj, window.location.pathname.includes('GrowerStorage')).then(
                  (inventoryResponse) => cb(inventoryResponse.Records)
              );
    };

    handleAddInventoryLine = (row) => {
        //need to work this out for the Return as this is a reused there
        const inventory = [...this.state.inventoryLines];
        if (this.state.pathName === 'Transfer') {
            if (inventory.filter((item) => item.BarcodeID === row.BarcodeID).length) {
                return;
            }
        }
        inventory.push(row);
        this.setState({ barcodeId: row.BarcodeID }, () => {
            this.props.setBarcodeId(row.BarcodeID);
        });
        this.props.setTransferInventory(inventory);
    };
    render() {
        const { barcodeId, newEditableField, classes, isMasterDataInitialized } = this.props;
        const { message } = this.state;
        const editType = getEditableFieldType([newEditableField]);
        const inventoryData = addEditableColumn(this.state.inventoryLines, 'OrderQty', 'Qty', `${this.state.pathName.toLowerCase()}Inventory`);

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div>
                <BarcodeIdSearch />
                <InventorySearch
                    inventorySearchLines={this.state.inventorySearchLines}
                    inventoryLines={inventoryData}
                    onSearch={this.handleInventorySearch}
                    onAdd={this.handleAddInventoryLine}
                    pathName={this.state.pathName}
                />
                <AccordianList
                    columns={makeColumns(inventoryData[0], editType, this.editInput, this.getValue)}
                    data={inventoryData}
                    label={'BarcodeID'}
                    value={'Qty'}
                    clearSearch={this.clearSearch}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                    customExpand={(row = {}) => {
                        return row['BarcodeID'] === barcodeId;
                    }}
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    excludeColumns={getExcludedColumns(`${this.state.pathName.toLowerCase()}Inventory`)}
                    deletable={'right'}
                    onDelete={this.handleDelete}
                    disableAction={(row = {}) => {
                        return this.getValue(row[newEditableField]) === '';
                    }}
                    scrollable
                    defaultEdit
                >
                    Order
                </AccordianList>
                <DrcDialog
                    classes={classes}
                    title={<>{message}</>}
                    open={this.state.showConfirmDialog}
                    buttons={
                        <>
                            <DrcButton onClick={this.handleCloseDialog}>{!this.state.showDelete ? 'Cancel' : 'Delete'}</DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.handleConfirm}
                                {...(this.state.showReasonCodeDialog ? { disabled: !this.state.reasonCode } : null)}
                            >
                                Save
                            </DrcButton>
                        </>
                    }
                >
                    {this.state.showReasonCodeDialog && (
                        <DrcSelect
                            options={makeLocationOption(this.props.reasonCodeList, 'ReasonCode', 'ReasonDescript')}
                            onChange={this.handleSelect}
                            value={this.state.reasonCode}
                            label="Reason Code"
                            required={true}
                            isSearchable={false}
                            maxMenuHeight={window.innerHeight * 2.15}
                        />
                    )}
                </DrcDialog>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setReturnInventory: (data) => dispatch(setReturnInventory(data)),
    setTransferInventory: (dataArr) => dispatch(setTransferInventory(dataArr)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ barcodeSearchReducer, transfer, returnLog, masterReducer, adjustTrim }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        returnHeader: returnLog.header,
        returnLines: returnLog.inventoryLines,
        barcodeId: barcodeSearchReducer.barcodeId,
        transferLines: transfer.inventoryLines,
        trimSeasonMap: masterReducer.trimSeasonMap,
        reasonCodeList: adjustTrim.reasonCodeList
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddInventory)));
