/**
 * withOktaAuth only works if a component is wrapped inside <Security>
 * So this wrapper is used to render component in App.js, only if user is authenticated
 * @props receive a component as children
 *
 *  */
import { useOktaAuth } from '@okta/okta-react';
export default function IsAuthenticated(props) {
    const { authState } = useOktaAuth();
    if (!authState.isAuthenticated) return null;
    return props.children;
}
