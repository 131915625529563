import { Middleware } from '@driscollsinc/one-ring';
const getTransferMapping = [
    Middleware.CreateMapping('DumpHeader', 'DumpHeader'),
    Middleware.CreateArrayMapping('DumpLogLines', 'DumpLogLines'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('DumpInventory', 'GET')) {
        Middleware.RegisterMapping('DumpInventory', 'GET', getTransferMapping);
        Middleware.RegisterMapping('DumpInventory', 'POST', getTransferMapping);
    }
}

const DumpInventory = {
    ConfigureMiddleware
};

export default DumpInventory;
