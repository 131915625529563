import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

function getIcon(props) {
    switch (props.action) {
        case 'delete':
            return <DeleteRoundedIcon />;
        case 'add':
            return <AddIcon />;
        case 'edit':
            return <EditIcon />;
        default:
            return <AddIcon />;
    }
}

function FloatingAddBtn(props) {
    return (
        <Fab size="small" color="primary" aria-label="add" onClick={props.onClick}>
            {getIcon(props)}
        </Fab>
    );
}

export default FloatingAddBtn;
