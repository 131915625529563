export const SET_HEADER_DETAILS = 'SET_HEADER_DETAILS';
export const setIssuanceHeader = (data) => ({
    type: SET_HEADER_DETAILS,
    payload: data
});

export const SET_INVENTORY_LINES = 'SET_INVENTORY_LINES';
export const setIssuanceInventory = (data) => ({
    type: SET_INVENTORY_LINES,
    payload: data
});

export const SET_ISSUANCE_LIST = 'SET_ISSUANCE_LIST';
export const setIssuanceList = (data) => ({
    type: SET_ISSUANCE_LIST,
    payload: data
});
