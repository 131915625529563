import React, { useState } from 'react';
import { DrcSelect, DrcButton } from '@driscollsinc/driscolls-react-components';
import { makeLocationOption, formatData, formatKey } from './../utils/helper';
import { StockTypes } from '../data/constants';
import { notify } from '../actions/NotificationAction';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import AccordianList from './AccordianList';
import { getExcludedColumns } from './../data/constants';

let styles = (theme) => ({
    accordion: {
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    grid: {
        padding: theme.spacing(0)
    },
    accordianListContainer: {
        height: 'fit-content !important'
    },
    labelStyle: {
        color: theme.palette.primary.light,
        fontWeight: 'bold'
    }
});

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        position: 'absolute'
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        height: '35px',
        width: '100%',
        backgroundColor: 'transparent'
    }),
    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: '1400'
    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: '25vh'
    })
};

const InventorySearch = (props) => {
    const { classes, onSearch, inventorySearchLines, onAdd, onCancel, inventoryLines } = props;
    const [options, setOptions] = useState([]);

    const handleSelectChange = (option, props) => {
        const newOptions = { ...options, [props.name]: option };
        setOptions(newOptions);
    };

    const searchInventory = () => {
        if (onSearch) {
            onSearch(options);
        }
    };

    const handleAdd = (row) => {
        if (onAdd) {
            onAdd(row);
        }
    };

    const handleCancel = (row) => {
        console.log('Cancelled', row);
        if (onCancel) {
            onCancel(row);
        }
    };

    const headerLabelFormatter = (value, row) => {
        return (
            <>
                <div className={classes.headerLabel}>
                    <span className={classes.labelStyle}>{row.BarcodeID}</span>
                    {/* <span className={classes.labelStyle}>{row.Qty}</span> */}
                </div>
            </>
        );
    };
    return (
        <React.Fragment>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <SearchIcon />
                    Advanced Search
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.grid}>
                            <DrcSelect
                                options={makeLocationOption(JSON.parse(localStorage.getItem('variety')), 'Code', 'Descript', true)}
                                label="Select Variety"
                                name="variety"
                                onChange={handleSelectChange}
                                value={options.variety}
                                isWindowedSelect
                                ignoreAccents={true}
                                maxMenuHeight={window.innerHeight * 0.25}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <DrcSelect
                                options={props.trimTypeList}
                                label="Select Trim Type"
                                name="trimType"
                                onChange={handleSelectChange}
                                value={options.trimType}
                                isWindowedSelect
                                ignoreAccents={true}
                                styles={customStyles}
                            />
                        </Grid>
                        {props.pathName !== 'Issuance' &&
                            props.pathName !== 'GrowerStorage/Issuance' &&
                            props.pathName !== 'GrowerStorage/Dump' &&
                            props.pathName !== 'Return' && (
                                <Grid item xs={12} className={classes.grid}>
                                    <DrcSelect
                                        options={StockTypes}
                                        label="Select Stock Type"
                                        name="stockType"
                                        onChange={handleSelectChange}
                                        value={options.stockType}
                                        isWindowedSelect
                                        ignoreAccents={true}
                                        styles={customStyles}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12} className={classes.grid}>
                            <DrcButton isPrimary floatRight onClick={searchInventory}>
                                Search
                            </DrcButton>
                        </Grid>

                        {inventorySearchLines.length > 0 && (
                            <Grid item xs={12} className={classes.grid + ' ' + classes.accordianListContainer}>
                                <AccordianList
                                    label={'BarcodeId'}
                                    value={'Qty'}
                                    data={inventorySearchLines}
                                    dataFormatter={formatData}
                                    keyFormatter={formatKey}
                                    excludeColumns={getExcludedColumns('issuanceLine')}
                                    labelFormatter={headerLabelFormatter}
                                    onSave={handleAdd}
                                    onCancel={handleCancel}
                                    disableAction={(row = {}) => {
                                        return inventoryLines.filter((line) => line.BarcodeID === row.BarcodeID).length;
                                    }}
                                >
                                    Add
                                </AccordianList>
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ userAccesses, masterReducer, barcodeSearchReducer, adjustTrim, growerStorage }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        barcodeId: barcodeSearchReducer.barcodeId,
        trimTypeList: masterReducer.trimTypeList,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        varietyList: adjustTrim.varietyList
    };
}

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(InventorySearch))));
