import { Middleware } from '@driscollsinc/one-ring';
const getDumpMappings = [
    Middleware.CreateMapping('DumpWarehouse', 'DumpWarehouse'),
    Middleware.CreateMapping('LogNbr', 'LogNbr'),
    Middleware.CreateMapping('LastChangedBy', 'LastChangedBy'),
    Middleware.CreateMapping('Comments', 'Comments'),
    Middleware.CreateMapping('AuthorizedBy', 'AuthorizedBy'),
    Middleware.CreateMapping('TrimSeasonID', 'TrimSeasonID'),
    Middleware.CreateArrayMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('AuthorizedDate', 'AuthorizedDate'),
    Middleware.CreateMapping('LastChangedDateTime', 'LastChangedDateTime'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('StorageType', 'StorageType'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Dump', 'GET')) {
        Middleware.RegisterMapping('Dump', 'GET', getDumpMappings);
        Middleware.RegisterMapping('Dump', 'POST', getDumpMappings);
    }
}

const Dump = {
    ConfigureMiddleware
};

export default Dump;
