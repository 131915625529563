export const berryMap = {
    BLACK: 1,
    BLUE: 2,
    RASP: 3,
    STRAW: 4
};

export const uiFieldMap = {
    VarietyCode: 'Variety',
    OriginLocationID: 'Source',
    DestinationLocationId: 'Destination',
    DestinationLocationID: 'Destination',
    TruckId: 'Truck ID',
    TruckID: 'Truck ID',
    ReceiptPlantTemperature: 'Receive Temp',
    ShipPlantTemperature: 'Ship Temp',
    ReferenceNbr: 'Reference Nbr',
    ExpectedShipDate: 'Ship Date',
    ExpectedArrivalDate: 'Arrival Date',
    ReturnedFromNbr: 'Grower Entity',
    ReturnedFromType: 'Entity Type',
    InventoryEffect: 'Inventory Affect'
};

export const getExcludedColumns = (type) => {
    let whoColumns = ['CreatedBy', 'CreatedDateTime', 'LastChangedBy', 'LastChangedDateTime'];
    let inventoryColumns = ['DigDate', 'DigDestination', 'DigFieldDesignator', 'CloneDestination', 'CloneDesignator', 'PackDate', 'LOTID'];
    switch (type) {
        case 'issuanceHeader':
            return [
                'TagNbr',
                'IssueToType',
                'IssueToNbr',
                'TrimSeasonID',
                'StorageType',
                'EntityName',
                'PickUpLocationId',
                'GrowingDistrict',
                'VendorInvoiceAccNum',
                ...whoColumns
            ];
        case 'issuanceLine':
            return [
                'BarcodeID',
                'LogNbr',
                'CodeID',
                'VarietyID',
                'AttributesID',
                'InventoryLocation',
                'IsReplant',
                'ID',
                'StorageType',
                'IssuanceID',
                'Commodity',
                'InventoryType',
                'Key',
                ...inventoryColumns
            ];
        case 'transferHeader':
            return [
                'TransferNbr',
                'ReferenceNbr',
                'DestinationLocationID',
                'TrimSeasonID',
                'TrimSeasonId',
                'TrimSeasonName',
                'ID',
                'ShipPlantTemperature',
                'ReceiptPlantTemperature',
                'RequestID',
                ...whoColumns
            ];

        case 'transferDetailsHeader':
            return [
                'TransferNbr',
                'ReferenceNbr',
                'OriginLocationID',
                'DestinationLocationId',
                'DestinationLocationID',
                'TrimSeasonID',
                'TrimSeasonId',
                'ID',
                'TrimSeasonName',
                ...whoColumns
            ];
        case 'transferInventory':
            return [
                'BarcodeID',
                'UpdateQty',
                'CodeID',
                'VarietyID',
                'AttributesID',
                'InventoryLocation',
                'InventoryType',
                'ID',
                'TransferNbr',
                'StorageLocation',
                'StorageType',
                'Commodity',
                'ReceivedQty',
                ...inventoryColumns,
                ...whoColumns
            ];
        case 'AdjustmentRequests':
            return [
                'BarcodeID',
                'UpdateQty',
                'CodeID',
                'VarietyID',
                'AttributesID',
                'InventoryLocation',
                'InventoryType',
                'ID',
                'TransferNbr',
                'StorageLocation',
                'StorageType',
                'Commodity',
                'RequestID',
                'LineID',
                'Qty',
                'ShippedQty',
                'ReceivedQty',
                ...inventoryColumns,
                ...whoColumns
            ];
        case 'adjustTrim':
            return [
                'BarcodeID',
                'CodeID',
                'VarietyID',
                'AttributesID',
                'InventoryLocation',
                'ID',
                'StorageType',
                'InventoryType',
                'Commodity',
                'PackDate',
                ...inventoryColumns
            ];
        case 'printLabel':
            return [
                'BarcodeID',
                'CodeID',
                'VarietyID',
                'AttributesID',
                'StorageLocation',
                'ID',
                'StorageType',
                'InventoryType',
                'Commodity',
                'PackDate',
                ...inventoryColumns
            ];
        case 'dumpHeader':
            return ['LogNbr', 'TrimSeasonID', 'StorageType', 'DumpWarehouse', 'Status', ...whoColumns];
        case 'dumpInventory':
            return [
                'BarcodeID',
                'LogNbr',
                'Commodity',
                'CodeID',
                'ID',
                'AttributesID',
                'VarietyID',
                'InventoryLocation',
                'InventoryType',
                'StorageType',
                'Key',
                ...inventoryColumns
            ];

        case 'returnHeader':
            return ['LogNbr', 'RefNbr', 'growerEntity', 'TrimSeasonID', 'ReturnWarehouse', 'ReturnedFromNbr', ...whoColumns];
        case 'returnDetailsHeader':
            return ['Comments', 'TrimSeasonID', 'ReturnedFromType', 'ReturnWarehouse', 'ReturnedFromNbr', ...whoColumns];

        case 'returnInventory':
            return [
                'BarcodeID',
                'Commodity',
                'LogNbr',
                'StorageType',
                'CodeID',
                'InventoryType',
                'VarietyID',
                'ID',
                'Id',
                'InventoryLocation',
                'IsReplant',
                'UpdateQty',
                'AttributesID',
                ...inventoryColumns
            ];
        case 'printServerList':
            return ['ID', 'PrintLocation', 'CreatedBy', 'CreationDateTime', 'LastChangedBy', 'LastChangedDateTime', 'PrintServer'];

        default:
            return [];
    }
};

export const getColumnsOrder = (type) => {
    switch (type) {
        case 'transferDetailsHeader': {
            return [
                'TransferNbr',
                'ReferenceNbr',
                'Status',
                'CarrierName',
                'TruckId',
                'TruckID',
                'OriginLocationID',
                'ExpectedShipDate',
                'DestinationLocationId',
                'ExpectedArrivalDate',
                'ShipPlantTemperature',
                'ReceiptPlantTemperature',
                'LastChangedBy',
                'CreatedBy',
                'CreatedDateTime',
                'LastChangedDateTime',
                'TrimSeasonId'
            ];
        }

        case 'transferHeader': {
            return [
                'TransferNbr',
                'ReferenceNbr',
                'Status',
                'CarrierName',
                'TruckId',
                'TruckID',
                'ExpectedShipDate',
                'OriginLocationID',
                'DestinationLocationId',
                'ExpectedArrivalDate',
                'ShipPlantTemperature',
                'LastChangedBy',
                'CreatedBy',
                'CreatedDateTime',
                'ReceiptPlantTemperature',
                'LastChangedDateTime',
                'TrimSeasonId'
            ];
        }
        case 'transferInventory': {
            return [
                'Status',
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'StorageLocation',
                'Attributes',
                'Qty',
                'ReceivedQty',
                'PickQty'
            ];
        }
        case 'AdjustmentRequests': {
            return [
                'Status',
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'StorageLocation',
                'Attributes',
                'Qty',
                'ReceiveQty',
                'PickQty'
            ];
        }
        case 'issuanceHeader': {
            return ['EntityName', 'IssueToType', '', 'ReferenceNbr', 'IssueDate', 'Status', 'Comments', 'TrimSeasonName'];
        }
        case 'issuanceInventory': {
            return [
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'StorageLocation',
                'Attributes',
                'IsReplant',
                'Status',
                'Qty',
                'PickQty',
                'OrderQty'
            ];
        }
        case 'dumpHeader':
            return ['DumpNbr', 'AuthorizedBy', 'AuthorizedDate', 'Comments'];
        case 'dumpInventory':
            return [
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'ReasonCode',
                'StorageLocation',
                'InventoryType',
                'Attributes',
                'IsReplant',
                'Status',
                'ReasonCode',
                'Qty',
                'DumpQty'
            ];
        case 'returnHeader': {
            return ['PostStatus', 'InventoryEffect', 'ReturnedDate', 'ReturnedFromType', 'ReturnedBy', 'CreatedBy', 'Comments', 'TrimSeasonName'];
        }
        case 'returnInventory': {
            return [
                'Status',
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'StorageLocation',
                'Attributes',
                'ReasonCode',
                'Qty',
                'OrderQty'
            ];
        }

        case 'plantInventory': {
            return [
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'StorageLocation',
                'Attributes',
                'ReasonCode',
                'Qty'
            ];
        }
        case 'printInventory': {
            return [
                'BarcodeID',
                'VarietyCode',
                'PlantCode',
                'TrimType',
                'StockType',
                'Grade',
                'UOM',
                'Rate',
                'InventoryLocation',
                'Attributes',
                'ReasonCode',
                'Qty'
            ];
        }
        default:
            return [];
    }
};

export const STATUS = {
    OPEN: 'OPEN',
    RESERVED: 'RESERVED',
    SHIPPED: 'SHIPPED',
    CANCELLED: 'CANCELLED',
    PICKED: 'PICKED',
    DUMPED: 'DUMPED'
};

export const StockTypes = [
    { label: 'ALL', value: 'ALL' },
    { label: 'COMMERCIAL', value: 'COMMERCIAL' },
    { label: 'FOUNDATION', value: 'FOUNDATION' },
    { label: 'RESERVED', value: 'RESERVED' },
    { label: 'APPLIEDRESEARCH', value: 'APPLIEDRESEARCH' },
    { label: 'NURSERYTRIALS', value: 'NURSERYTRIALS' },
    { label: 'BREEDING', value: 'BREEDING' },
    { label: 'ONFARMTRIALS', value: 'ONFARMTRIALS' }
];

//ZPL constants
export const largeLogoZPL = `^GFA,975,975,25,I0FFEgR01E,007IFCgQ07F,03KFgQ0FF800FC078,0LFCgO01FFC03FE0F8,1LFEgO03FFC07FF0F8,3MFgO07FFC0IF0F8,7MF8M03EY0IFC0IF1F,7FFE1IFCM07EY0FF3C1IF1F,FFE001FFEM0FFX01FE7C3FCF1E,FF8700FFEM0FFX01FC7C3F9F3C,7E1F807FFM0FFX03FCF87F9F38,381FC03FFM07FI0CT03F8F87F3E,003FC01FFM03E00FF8S07F9F8FE3E01F8,003FC01FF078078J01FFE001FCI033C007F1F0FE7E07FE,003FC00FF87C3FCJ03IF007FF001FFE00FF3F1FC7C0IF,007FC00FF87EFFCJ07IF81IF803IF00FE7E1FCFC1IF8007F800FF87IF83F007IF03IF807IF81FE7C3F8F83IF8007F800FF87IF07F80FF8007IFC0JF81FCFC3F9F03IF800FF800FF87FFC07F00FFI0JFC1JF81FDF83FBF07F87800FFI0FF87FF80FF00FF001FF0FC3FCFFC3IF07F3E07F,00FFI0FF87FF00FF00FF001FC0387F8FFC3FFE07F7C07F,01FFI0FF07FC01FE007F803FCI07F8FFC3FFC07FF807F8,01FE001FF0FFC01FE007FC03F8I0FF07FC3FF80IF007F8,01FE001FF0FF803FC003FC07F8I0FE07FC7FF00FFE003FC,03FE001FE0FF003FC003FE07FI01FE03JFE00FFC003FC,03FC003FE0FF003F8001FF07FI01FE01JF800FF8001FE,03FC007FE1FF007F8I0FF07FI03FC01JF001FFI01FF,07FC007FC1FE007F8I0FF8FFI07FC01JF001FEJ0FF,07FC01FFC1FE007F8I07F8FFI0FFC03F07F001FCJ07F8,07F803FF81FE007F8I03F8FF001FFC03F07F003FCJ07F8,07F80IF03FC007F8I03F87F803FFE07F07F007FCJ03F8,0FF87FFE03FC007FC4E07F87FC0IFE0FE07F00FFEJ03F8,0LFC03FC007FFEFCFF87JFCJFC07F83FFE00787F8,0LF807F8007FFCJF87JF8JFC03LF00JF8,0LF007F8007FFCJF03JF07IF803IFCIF8JF8,07JFC007FI03FFC7FFE01IFC07IF001IF8IF8JF,07JFI07FI01FF83FFC00IF803FFCI0FFE07FF07FFE,01IFCI03CJ0FE00FFI03FCI0FFJ07FC03FE03FFC,003F8gV0FI0FF,^FS`;
export const mediumlogoZPL =
    '^GFA,551,551,19,007FCgJ0E,03IFgI03F003818,1JFCgH07F80FC3C,3KFgH0FF81FE3C,7KF8K03U0FFC3FE38,7FF3FF8K07CS01FBC7FE78,FF007FCK0FCS03F387CE7,FC781FEK0FCS03E78FDE6,30F80FEK07C01Q07E79F9E,00F80FE08I0380FE001M07CF1F3C0F8,01F807E1C3EI01FF80FE007F80FCF3F3C3FE,01F807F1EFFI03FF81FF01FFC0F9E3E787FE,01F807F1FFE3E07FF87FF83FFE0FBE3E78IF,03F807F1FF83E07E00IF87FFE1FFC7EF0FCF,03F007F1FF07E07E00FC787EFE1FF87DE0F8,03F007E3FC07E07E01FI0FCFE1FF07FC0F8,07F007E3F80FC03F03F001F8FE3FE0FF80FC,07E00FE3F80FC03F83E001F07F3FC0FF00FC,07E00FE3F01F801FC3E003F03IF80FE007E,0FE01FC3F01F800FC7E003F03FFE00FC007F,0FC03FC7E01F800FE7E007F03FFE01F8003F,0FC07F87E01FI07E7E00FF03C3E01F8001F8,1FC0FF07E01F8007E7E01FF07C3E03F8001F8,1FCFFE0FC01FCB07E3F07FF8F83E07F8001F8,1JFC0FC01FFBFFE3IF9IF83F1FF80F3F8,1JF80FC01FFBFFC3IF1IF03FFEFF8IF8,0JF01F800FF1FF81FFE0FFE01FF8FFCIF,0IF800FI07E0FF007F807F800FF07F87FE,01FCgI01803E01FC,^FS';
export const smallLogoZPL =
    'GFA,345,345,15,03FEY0F,0IF8W01F81E18,3IFCW03F83F38,7JFK0CQ07F87F38,FF8FFJ01EQ07B8FF38,FC83F8I01EQ0FB9F77,71C1F8I01EP01F39E7,03E0FCJ0C0FCM01F73EE0F,03E07C73E001FE07E03F81EF3CE3F803C07C7FE303FE1FF07FC3EE7DC7FC07C07C7F8783E03FF0FFC3DC79C7FC07C07C7F0F83C07C71F7C7FC7F878,0F807CFE0F03E07803E7E7F8FF078,0F80FCFC1F03F0F003C7E7F0FE07C,0F80F8F81E01F0F007C3FFC0FC07C,1F81F8F83E00F8F00781FF80F803E,1F03F1F03E00F9F00F83CF81F001E,1F07F1F03E0079F01F83C781F001F,1F1FE1F03E0878F87FC78783F001F,1IFC3E03JF8IF3FF07CFF039F,1IF83E01FDFF87FE3FF07FEFF3FF,1FFE03C01F8FF03F81FC03F87E3FE,07F001K01800C007I0E03C0FC,^FS';
export const noticeInfo = `The Plants in this container are Proprietary to Driscoll's (Driscoll's Plants). Driscoll's Plants are licensed to certain select Growers for use under a Driscoll's Growers Agreement that your company has signed. Under this Agreement, you have agreed to use these licensed Driscoll's Plants for the sole purpose of production of Berries. Grower cannot sell, transfer, propagate, or otherwise distribute these Driscoll's proprietary plants.Grower shall not reproduce (sexually or asexually) any Driscoll's Plants, shall not transfer any and shall not subject the seeds or plants produced therefrom (not their pollen or any other plant part) to any breeding, sexual or asexual propagation, biotechnology process, or any other genetic manipulation techniques, including (but not limited to) tissue culture, gentic fingerprinting or transformation techniques.`;
export const invertedLogoZPL = `^GFA,360,360,3,J06,J0F,J0F87F00F87FE07CIF87CJF3EJFBEF1IFEF01F9EF0031EF8001E78003E7C003E7E007E3F00FC3FC1FC1JF80JF,07IF,01FFC,007F,7,FE,7FE,7FFE,3FFE,0FFE,01FC,007C,003E,001E,I0E,I06,1F06,7FC,7FF,7FF8,7FFC,707CE,7019F,3001F,1I0E,3,78,7,707C,F0FE,F3FF,7IF,7FEF,7FC7,3F07,I07,I06,06,1FC,3FF,7FF8,7FFC,F83C,F01E,700E,::381E,:1C1C,0E,0F8,3FE,7FF,7FF8,F8FC,F03E,F01E,700E,787E,3EFE,3FFE,0FFE,07FC,01E,018,038,3FF,7FFC,7IF8,JFC,F0IF,E077F86079FC703E7E301F1E3807FE1E03FE3FE0FC7FFC,JF,JFC,E1FFE,E0F7F,6071F8203C7C001F3CI0FFC7003FC7I0F8F,E0FC4,E1FE78IFE7CJF3CFFCF,7F0F,3C0F,I0E,I0C,,:^FS`;

export const getZPLHexCode = (str) => {
    let newText = '';
    let splitStr = str.split('');
    splitStr.forEach((eachChar) => {
        newText += eachChar.charCodeAt(0) < 128 ? eachChar : encodeURIComponent(eachChar).replace(/\%/g, '_').toUpperCase();
    });
    return newText;
};
