import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './data/store';
import App from './App';
import Middleware from './data/middleware';
import Fake from './data/fake';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
const renderApp = () =>
    render(
        <Provider store={Store}>
            <Router >
                <App />
            </Router>
        </Provider>,
        document.getElementById('root')
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

serviceWorker.register();
Middleware.ConfigureAllMiddleware();
Fake.ConfigureAllFakeData();
renderApp();
