import React from 'react';
import AccordianList from '../../components/AccordianList';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { setColumnOrder } from '../../utils/helper';
import NewIssuanceHeader from './NewIssuanceHeader';
import { withRouter } from 'react-router-dom';
import { setGrowerStorageIssuanceEditMode } from '../../actions/GrowerStorage';
const styles = (theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    headerAction: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        width: '230px',
        '&>div': {
            flexBasis: '45%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    }
});

class IssuanceHeader extends React.Component {
    state = {
        editHeader: false,
        headerData: {}
    };

    headerFormatter = (value, row) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>Tag #</span>
                        <span className={classes.labelValue}>{value}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>Entity Type</span>
                        <span className={classes.labelValue}>{row.IssueToType}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>{`${row.IssueToType} Entity`}</span>
                    <span className={classes.labelValue}>{`${row.IssueToNbr}-${row.EntityName}`}</span>
                </div>
            </>
        );
    };

    setGrowerStorageEditMode = (editMode) => {
        if (this.props.isGrowerStorageNavigation) {
            this.props.setGrowerStorageIssuanceEditMode(editMode);
        }
    };

    handleEdit = (row) => {
        this.setState({ editHeader: true, headerData: row });
        this.setGrowerStorageEditMode(true);
    };

    handleCancel = () => {
        this.setState({ editHeader: false, headerData: null });
        this.setGrowerStorageEditMode(false);
    };

    handleSave = () => {
        this.setState({ editHeader: false, headerData: null });
        this.setGrowerStorageEditMode(false);
    };

    render() {
        const { classes, issuanceHeader = [], location, isGrowerStorageNavigation, enableEdit = true, ...rest } = this.props;
        const isEditMode = location.pathname.includes('/Issuance/Edit') || location.pathname.includes('/GrowerStorage/Issuance/Edit');

        return (
            <>
                {this.state.editHeader ? (
                    <NewIssuanceHeader
                        onCancel={() => this.handleCancel()}
                        data={this.state.headerData}
                        onSave={() => this.handleSave()}
                        growerEntity={this.props.growerEntity}
                    />
                ) : (
                    <>
                        <div className={classes.headerAction}>
                            {!location.pathname.includes('/GrowerStorage/Issuance/Edit') ? (
                                <Typography gutterBottom variant="h6" className={classes.heading}>
                                    Issuance Header
                                </Typography>
                            ) : null}
                            {this.props.children}
                        </div>

                        <AccordianList
                            data={setColumnOrder([issuanceHeader], 'issuanceHeader')}
                            label="TagNbr"
                            labelFormatter={this.headerFormatter}
                            checkEditable={(row) => {
                                if (isGrowerStorageNavigation) {
                                    return isEditMode && enableEdit ? 'right' : null;
                                } else {
                                    return isEditMode && enableEdit && (row.Status === 'OPEN' || row.Status === 'RESERVED') ? 'right' : null;
                                }
                            }}
                            onEdit={(row) => this.handleEdit(row)}
                            {...{ ...rest, children: null }}
                        />
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps({ issuance, growerStorage }) {
    return {
        issuanceHeader: issuance.header,
        isGrowerStorageNavigation: growerStorage.isNavigated
    };
}

const mapDispatchToProps = (dispatch) => ({
    setGrowerStorageIssuanceEditMode: (dataObj) => dispatch(setGrowerStorageIssuanceEditMode(dataObj))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(IssuanceHeader))));
