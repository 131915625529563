import React, { useEffect } from 'react';
import AddInventoryLines from './AddInventoryLines';
import { connect } from 'react-redux';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { makeStyles } from '@material-ui/core/styles';
import { setToNavBack } from '../actions/MasterActions';
import ConfirmDialog from './ConfirmDialog';
import { useOktaAuth } from '@okta/okta-react';
import APIEndPoints from '../services/api';
import { Middleware } from '@driscollsinc/one-ring';
import { notify } from '../actions/NotificationAction';
import { setIssuanceHeader, setIssuanceInventory } from '../actions/Issuance';
import { setBarcodeId } from '../actions/barcodeSearchAction';
import { cloneDeep } from 'lodash';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { STATUS } from '../data/constants';
import { getGrowerDistrict, isSuccessful, getStoredLocation } from '../utils/helper';
import { setGrowerStorageNav } from '../actions/GrowerStorage';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    main: {
        marginBottom: '8vh'
    },
    growerStorageStyle: {
        border: '0',
        padding: '0',
        marginTop: '-1vh'
    }
}));
function CreateNewRecord(props) {
    const classes = useStyles();
    const { authState } = useOktaAuth();
    const [openConfirmDialog, showConfirmation] = React.useState(false);
    const [rowData, setRowData] = React.useState({});
    const pageType =
        props.match.path.split('/')[1] === 'GrowerStorage'
            ? props.match.path.split('/')[1] + '/' + props.match.path.split('/')[2]
            : props.match.path.split('/')[1];
    useEffect(() => {
        props.setToNavBack(true);
    }, []);

    const { isGrowerStorageNavigation, inventoryLines, label, value, isMasterDataInitialized } = props;

    const handleDelete = async (row, hideDialog = false) => {
        setRowData(row);
        if (hideDialog) {
            await deleteInventoryData(row);
        } else {
            showConfirmation(true);
        }
    };

    const getApiResponse = async (row) => {
        if (!rowData.ID && !row.ID) {
            return null;
        }

        let token = authState.accessToken;
        switch (pageType) {
            case 'Issuance':
            case 'GrowerStorage/Issuance':
                return Middleware.Send(
                    'Issuance',
                    token,
                    APIEndPoints.DELETE_ISSUANCE_INVENTORY(
                        rowData.ID || row.ID,
                        rowData.IssuanceID || row.IssuanceID,
                        props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY'
                    ),
                    'DELETE'
                );
            case 'Dump':
            case 'GrowerStorage/Dump':
                return Middleware.Send(
                    'DumpInventory',
                    token,
                    APIEndPoints.DELETE_DUMP_LOG_LINE(rowData.ID || row.ID, rowData.LogNbr || row.LogNbr),
                    'DELETE'
                );
            default:
                return null;
        }
    };

    const deleteInventoryData = async (row) => {
        if (rowData) {
            try {
                let token = authState.accessToken;
                let response = await getApiResponse(row);
                if (
                    !response ||
                    response.Message === 'Inventory line deleted from Issuance' ||
                    response.Message.toUpperCase() === 'ID DELETED SUCCESSFULLY'
                ) {
                    let newInventoryLines = cloneDeep(inventoryLines).filter((line) => {
                        const id = row.ID ? row.ID : rowData.ID;
                        return line.ID !== id;
                    });
                    props.setIssuanceInventory(newInventoryLines);
                    if (response) {
                        if (pageType !== 'Dump' && pageType !== 'GrowerStorage/Dump' && !newInventoryLines.length) {
                            let resp = await Middleware.Send('Issuance', token, APIEndPoints.CREATE_ISSUANCE, 'POST', {
                                IssuanceHeader: { ...getHeaderData(), Status: STATUS.OPEN, InventoryLocation: getStoredLocation().Code },
                                InventoryLines: []
                            });
                            if (isSuccessful(resp.Message)) {
                                props.setIssuanceHeader({ ...getHeaderData(), Status: STATUS.OPEN });
                            }
                        }
                        props.notify('Inventory Line Deleted', false);
                        props.setBarcodeId('');
                    }
                    setRowData({});
                    showConfirmation(false);
                } else {
                    props.notify('Failed to Delete', true);
                    showConfirmation(false);
                    props.setBarcodeId('');
                }
            } catch (err) {
                console.error(err);
                props.notify('Failed to Delete', true);
            }
        }
    };
    if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    const getHeaderData = () => {
        switch (pageType) {
            case 'Issuance':
            case 'GrowerStorage/Issuance':
                return {
                    InventoryLocation: getStoredLocation().Code,
                    ...props.issuanceHeader,
                    ...(props.issuanceHeader.IssueToType === 'Grower'
                        ? {
                              VendorInvoiceAccNum: getGrowerDistrict(props.issuanceHeader.EntityName).VendorInvoiceAccNum
                          }
                        : '')
                };
            case 'Dump':
            case 'GrowerStorage/Dump':
                return props.dumpHeader;
            default:
                return {};
        }
    };
    const newEditableField = pageType === 'Dump' || pageType === 'GrowerStorage/Dump' ? 'DumpQty' : 'OrderQty';

    const getUniqueKey = () => {
        if (pageType === 'GrowerStorage/Dump') {
            return 'Key';
        }
        return null;
    };

    return (
        <DrcMain
            transparent
            handHeld
            className={`${classes.main} ${isGrowerStorageNavigation && pageType.includes('Issuance') ? classes.growerStorageStyle : null}`}
        >
            <DrcPanel
                className={`${classes.panel} ${isGrowerStorageNavigation && pageType.includes('Issuance') ? classes.growerStorageStyle : null}`}
            >
                <AddInventoryLines
                    header={getHeaderData()}
                    label={label}
                    value={value}
                    newEditableField={newEditableField}
                    handleDelete={handleDelete}
                    growerEntity={props.growerEntity}
                    uniqueKey={getUniqueKey()}
                    inventoryLines={inventoryLines}
                />
                <ConfirmDialog
                    message={`Delete Inventory ${rowData.BarcodeID}?`}
                    open={openConfirmDialog}
                    closeConfirmDialog={() => {
                        showConfirmation(false);
                    }}
                    submitRequest={(row) => {
                        setRowData(row);
                        deleteInventoryData(row);
                    }}
                />
            </DrcPanel>
        </DrcMain>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setToNavBack: (bool, route) => dispatch(setToNavBack(bool, route)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setIssuanceHeader: (data) => dispatch(setIssuanceHeader(data)),
    setIssuanceInventory: (data) => dispatch(setIssuanceInventory(data)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setGrowerStorageNav: (isNavigated) => dispatch(setGrowerStorageNav(isNavigated))
});

function mapStateToProps({ issuance, dump, growerStorage, masterReducer }) {
    return {
        issuanceHeader: issuance.header,
        dumpHeader: dump.dumpHeader,
        inventoryLines: issuance.inventoryLines,
        label: masterReducer.label,
        value: masterReducer.value,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        isMasterDataInitialized: masterReducer.isInitialized
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateNewRecord));
