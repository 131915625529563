export const SET_PRINT_INVENTORY_DATA = 'SET_PRINT_INVENTORY_DATA';
export const setPrintInventoryData = (data) => ({
    type: SET_PRINT_INVENTORY_DATA,
    payload: data
});

export const SET_PRINT_QUANTITY = 'SET_PRINT_QUANTITY';
export const setPrintQuantity = (data) => ({
    type: SET_PRINT_QUANTITY,
    payload: data
});

export const SET_PRINT_SERVERS = 'SET_PRINT_SERVERS';
export const setPrintServers = (data) => ({
    type: SET_PRINT_SERVERS,
    payload: data
});
