import { makeStyles } from '@material-ui/core';
import { DrcButton, DrcDialog } from '@driscollsinc/driscolls-react-components';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '250px'
    }
}));
function ZeroQtyDialog(props) {
    const classes = useStyles();
    return (
        <DrcDialog
            classes={classes}
            open={props.showDialog}
            title="Quantity cannot be zero"
            buttons={<DrcButton onClick={props.onClose}>Ok</DrcButton>}
        />
    );
}

export default ZeroQtyDialog;
