import { SET_TRANSFER_LIST, SET_TRANSFER_INVENTORY, SET_TRANSFER_HEADER, UPDATE_INVENTORY_STATUS } from '../actions/Transfer';

const initialState = {
    transferHeader: {},
    transferList: [],
    inventoryLines: [],
    inventoryStatus: {}
};

const Transfer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFER_HEADER:
            return Object.assign({}, state, {
                transferHeader: action.payload
            });
        case SET_TRANSFER_LIST:
            return Object.assign({}, state, {
                transferList: action.payload
            });
        case SET_TRANSFER_INVENTORY:
            return Object.assign({}, state, {
                inventoryLines: action.payload
            });
        case UPDATE_INVENTORY_STATUS:
            return Object.assign({}, state, {
                inventoryStatus: action.payload
            });
        default:
            return state;
    }
};

export default Transfer;
