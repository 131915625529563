export const SET_TRANSFER_LIST = 'SET_TRANSFER_LIST';
export const setTransferList = (data) => ({
    type: SET_TRANSFER_LIST,
    payload: data
});

export const SET_TRANSFER_HEADER = 'SET_TRANSFER_HEADER';
export const setTransferHeader = (data) => ({
    type: SET_TRANSFER_HEADER,
    payload: data
});

export const SET_TRANSFER_INVENTORY = 'SET_TRANSFER_INVENTORY';
export const setTransferInventory = (data) => ({
    type: SET_TRANSFER_INVENTORY,
    payload: data
});

export const UPDATE_INVENTORY_STATUS = 'UPDATE_INVENTORY_STATUS';
export const updateInventoryStatus = (data) => ({
    type: UPDATE_INVENTORY_STATUS,
    payload: data
});
