import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcPanel, DrcInput, DrcButton, DrcDatePicker } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { notify } from '../../actions/NotificationAction';
import { addTimeStampToDate, getLocalTrimAndLocation, getTrimSeasonId, toISOdateString, isDateValid } from '../../utils/helper';
import { setDumpHeader } from '../../actions/Dump';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../../services/api';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex'
    },
    buttonContainer: {
        width: '100%',
        marginTop: '1rem',
        background: 'transparent',
        height: '50px',
        bottom: '0px'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    }
});
const PAGE_TITLE = 'Dump';

class DumpNew extends React.Component {
    constructor(props) {
        super(props);
        const { AuthorizedDate, AuthorizedBy, Comments, LogNbr } = {
            headerData: {},
            ...this.props.headerData
        };
        this.state = {
            authorizedDate: AuthorizedDate ? new Date(AuthorizedDate) : new Date(),
            authorizedBy: AuthorizedBy || null,
            comment: Comments,
            logNbr: LogNbr || null
        };
    }
    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitleAction(PAGE_TITLE);
        }
    }

    handleInput = (fieldName) => (event) => {
        this.setState({ [fieldName]: event.target.value });
    };

    handleDateChange = (dateObj) => {
        this.setState({ authorizedDate: dateObj });
    };

    handleSelectChange = (option, props) => {
        this.setState({ [props.name]: option.value });
    };

    isHeaderValid = () => this.state.authorizedBy && isDateValid(this.state.authorizedDate);

    getHeaderData = async () => {
        const { authorizedBy, comment, authorizedDate, logNbr } = this.state;
        let token = this.props.authState.accessToken;
        return {
            LogNbr: logNbr,
            Status: 'OPEN',
            AuthorizedBy: authorizedBy,
            Comments: comment,
            TrimSeasonID: getTrimSeasonId().Id,
            DumpWarehouse: getLocalTrimAndLocation().location.Code,
            AuthorizedDate: toISOdateString(authorizedDate),
            CreatedBy: DuAuthenticationUtilities.GetUserId(token),
            CreatedDateTime: new Date().toISOString(),
            LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
            LastChangedDateTime: new Date().toISOString(),
            StorageType: this.props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY'
        };
    };
    createDumpHeader = async () => {
        try {
            let token = this.props.authState.accessToken;
            let dumpLog = await this.getHeaderData();
            //API call to save the header details
            let response = await Middleware.Send('Dump', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                DumpHeader: dumpLog,
                DumpLogLines: []
            });
            if (response.LogNbr === dumpLog.LogNbr) {
                this.props.cancelDump();
                this.props.notify('Dump Header Updated', false);
                this.props.setDumpHeader(dumpLog);
            } else if (response.LogNbr) {
                this.props.notify('Dump Header Created', false);
                dumpLog.LogNbr = response.LogNbr;
                if (!this.props.isGrowerStorageNavigation) {
                    this.props.history.push(`/Dump/Edit/${response.LogNbr}`);
                } else {
                    this.props.history.push(`/GrowerStorage/Dump/Edit/${response.LogNbr}`);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }

        const { classes } = this.props;
        const trimSeason = getLocalTrimAndLocation().location.label;

        return (
            <DrcPanel style={{ border: '0px', padding: '0px', height: '100%' }}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    Dump Header
                </Typography>
                <div className={classes.flexContainer}>
                    <DrcInput label={'Dump Status'} onChange={this.handleInput('dumpStatus')} value={'Open'} disabled />
                </div>

                <DrcInput label={'Dump Warehouse'} onChange={this.handleInput('dumpWarehouse')} value={trimSeason} disabled={true} />

                <DrcInput
                    label={'Authorized By'}
                    onChange={this.handleInput('authorizedBy')}
                    value={this.state.authorizedBy}
                    inputProps={{ maxLength: 50 }}
                />

                <DrcDatePicker
                    clearable={false}
                    value={this.state.authorizedDate}
                    label={'Authorized Date'}
                    onChange={(date) => {
                        this.handleDateChange(addTimeStampToDate(date));
                    }}
                    InputProps={{ readOnly: true }}
                ></DrcDatePicker>

                <DrcInput label={'Comments'} onChange={this.handleInput('comment')} value={this.state.comment} inputProps={{ maxLength: 255 }} />

                <div className={`${classes.flexContainer} ${classes.buttonContainer}`}>
                    <DrcButton className={null} onClick={this.props.cancelDump} isSecondary={true}>
                        Cancel
                    </DrcButton>
                    <DrcButton onClick={this.createDumpHeader} isPrimary={true} disabled={!this.isHeaderValid()}>
                        {this.props.headerData.LogNbr ? 'Update' : 'Create'}
                    </DrcButton>
                </div>
            </DrcPanel>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setDumpHeader: (data) => dispatch(setDumpHeader(data)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ dump, masterReducer, rootReducer, growerStorage }) {
    return {
        isGrowerStorageNavigation: growerStorage.isNavigated,
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        headerData: dump.dumpHeader
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(DumpNew))));
