import React, { useEffect } from 'react';
import AddInventory from '../../components/AddInventory';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { makeStyles } from '@material-ui/core/styles';
import HeaderPanel from '../../components/HeaderPanel';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import { setTransferHeader, setTransferInventory } from '../../actions/Transfer';
import { Middleware } from '@driscollsinc/one-ring';
import { useOktaAuth } from '@okta/okta-react';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { cloneDeep, isEmpty } from 'lodash';
import { setFields, setToNavBack } from '../../actions/MasterActions';
import ConfirmDialog from '../../components/ConfirmDialog';
import { setColumnOrder, getTrimSeasonId, formatKey, isSuccessful } from '../../utils/helper';
import TransferNew from './TransferNew';

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    main: {
        marginBottom: '8vh'
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            // width: '24vw',
            flex: '1',
            padding: '0 0.2rem'
            // flexBasis: '20%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    }
}));
const EditTransfer = (props) => {
    const classes = useStyles();
    const { authState } = useOktaAuth();
    const [openConfirmDialog, setDialogState] = React.useState(false);
    const [rowData, setRowDetails] = React.useState({});
    const [editTransferDialog, setEditTransferDialog] = React.useState(false);

    useEffect(() => {
        if (!props.inventoryLines.length) {
            getData();
        }
    }, []);

    const updateInventoryData = async (row, updateQty) => {
        const { headerData } = props;
        try {
            let inventoryLines = cloneDeep(props.inventoryLines).filter((line) => line.BarcodeID !== row.BarcodeID);
            let token = authState.accessToken;
            const originalQty = row.Qty;
            const originalStatus = headerData.Status;

            delete row.OrderQty;
            delete row.defaultValue;

            if (!row.TransferNbr) {
                row.ID = null;
                row.Qty = parseInt(updateQty);
            } else {
                headerData.LastChangedBy = DuAuthenticationUtilities.GetUserId(token);
            }
            row.UpdateQty = parseInt(updateQty);
            row.Status = 'RESERVED';

            headerData.LastChangedBy = DuAuthenticationUtilities.GetUserId(token);
            headerData.Status = 'RESERVED';
            headerData.TrimSeasonName = getTrimSeasonId().TrimSeason;

            let response = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                TransferHeader: headerData,
                TransferLines: [row]
            });
            if (isSuccessful(response.Message)) {
                if (response.TransferLines[0].Message === 'Transfer Line RESERVED successfully') {
                    row.ID = response.TransferLines[0]['ID'];
                    if (!row.TransferNbr) row.TransferNbr = response.TransferNbr;
                    row.Qty = row.UpdateQty;
                    let tagNbr = props.match.params.TransferId;
                    let responseData = await Middleware.Send(
                        'TransferInventory',
                        token,
                        APIEndPoints.TRANSFER_INVENTORY(tagNbr, getTrimSeasonId().Id),
                        'GET',
                        {}
                    );
                    let inventoryLines = responseData.TransferLines;
                    props.setTransferInventory(inventoryLines);
                    props.setBarcodeId('');
                } else if (response.TransferLines[0].Message === 'Insufficient quantity') {
                    props.notify('Insufficient quantity', true);
                    inventoryLines.push({ ...row, Qty: originalQty, defaultValue: updateQty });
                    props.setTransferHeader({ ...headerData, Status: originalStatus });
                    props.setTransferInventory(inventoryLines);
                    props.setBarcodeId(row.BarcodeID);
                } else {
                    props.setTransferInventory(inventoryLines);
                    props.setBarcodeId('');
                }
            }else{
                props.notify(response.Message, true);
            }
        } catch (err) {
            console.error(err);
            props.notify('Failed to Pick Inventory', true);
        }
    };

    const getData = async () => {
        props.setToNavBack(true);

        let token = authState.accessToken;
        let transferNbr = props.match.params.TransferId;
        let responseData = await Middleware.Send(
            'TransferInventory',
            token,
            APIEndPoints.TRANSFER_INVENTORY(transferNbr, getTrimSeasonId().Id),
            'GET',
            {}
        );
        let inventoryLines = responseData.TransferLines;

        let header = responseData.TransferHeader;
        props.setFields({
            label: 'BarcodeID',
            value: 'Qty',
            newEditableField: 'OrderQty'
        });
        props.setTransferInventory(inventoryLines);
        props.setTransferHeader(header);
    };

    const handleDelete = (dataObj, showDialog = true) => {
        if (showDialog) {
            setRowDetails(dataObj);
            setDialogState(true);
        } else {
            setRowDetails(dataObj);
            deleteInventoryData(dataObj);
        }
    };

    const removeUnsavedLine = (data) => {
        const filteredLines = cloneDeep(props.inventoryLines).filter((line) => line.BarcodeID !== data.BarcodeID);
        props.notify('Inventory Line Deleted', false);
        props.setTransferInventory(filteredLines);
        props.setBarcodeId('');
        setDialogState(false);
    };

    const deleteInventoryData = async (dataObj = {}) => {
        const data = !isEmpty(dataObj) ? cloneDeep(dataObj) : cloneDeep(rowData);
        if (!isEmpty(data)) {
            try {
                if (!data.TransferNbr) {
                    removeUnsavedLine(data);
                    return;
                }
                const transferID = data.ID;
                let token = authState.accessToken;
                let response = await Middleware.Send('Transfer', token, APIEndPoints.DELETE_INVENTORY(transferID), 'DELETE');
                if (response.Message === 'Id Deleted Successfully') {
                    let inventoryLines = cloneDeep(props.inventoryLines).filter((line) => line.ID !== response.Id);
                    if (!inventoryLines.length) {
                        const headerData = cloneDeep(props.headerData);
                        headerData.Status = 'OPEN';
                        headerData.TrimSeasonName = getTrimSeasonId().TrimSeason;
                        try {
                            let headerResponse = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                                TransferHeader: headerData,
                                TransferLines: []
                            });
                            if (isSuccessful(headerResponse.Message)) {
                                props.notify('Inventory Line Deleted', false);
                                props.setTransferHeader(headerData);
                                props.setTransferInventory(inventoryLines);
                                props.setBarcodeId('');
                            } else {
                                props.notify('Failed to Delete', true);
                                props.setBarcodeId('');
                            }
                        } catch (err) {
                            props.notify('Failed to Delete', true);
                            console.error(err);
                        }
                    } else {
                        props.setTransferInventory(inventoryLines);
                        props.setBarcodeId('');
                    }
                } else {
                    props.setBarcodeId('');
                }
            } catch (err) {
                console.error(err);
                props.notify('Failed to Delete', true);
            }
        }
        setRowDetails({});
        setDialogState(false);
    };

    const getHeaderPanel = (value, dataObj) => {
        const { locationMap } = props;
        let locationId = 'DestinationLocationID';
        let labelValue = `${dataObj[locationId]} - ${locationMap[dataObj[locationId]]}`;

        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('TransferNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['TransferNbr']}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('ReferenceNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['ReferenceNbr']}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>{formatKey(locationId)}</span>
                    <span className={classes.labelValue}>{labelValue}</span>
                </div>
            </>
        );
    };

    const { label, value, newEditableField, headerData } = props;
    const confirmationMsg = 'Are you sure you want to delete ';
    return (
        <DrcMain transparent handHeld className={classes.main}>
            <DrcPanel className={classes.panel}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {`Edit Transfer`}
                </Typography>
                {editTransferDialog ? (
                    <TransferNew
                        headerDataObj={headerData}
                        close={() => {
                            setEditTransferDialog(false);
                        }}
                        editTransfer={editTransferDialog}
                    />
                ) : (
                    <HeaderPanel
                        data={setColumnOrder([headerData], 'transferHeader')}
                        headerName={'Transfer'}
                        columnType={'transferDetailsHeader'}
                        headerFormatter={getHeaderPanel}
                        editHeader={() => {
                            setEditTransferDialog(!editTransferDialog);
                        }}
                    />
                )}
                <AddInventory
                    pathName={'Transfer'}
                    label={label}
                    value={value}
                    newEditableField={newEditableField}
                    updateInventoryData={updateInventoryData}
                    handleDelete={handleDelete}
                    inventoryLines={props.inventoryLines}
                />
                <ConfirmDialog
                    message={`${confirmationMsg} ${rowData.BarcodeID}?`}
                    open={openConfirmDialog}
                    closeConfirmDialog={() => {
                        setDialogState(false);
                    }}
                    submitRequest={() => deleteInventoryData()}
                />
            </DrcPanel>
        </DrcMain>
    );
};
function mapStateToProps({ rootReducer, transfer, growerStorage, masterReducer }) {
    return {
        headerData: transfer.transferHeader,
        inventoryLines: transfer.inventoryLines,
        label: masterReducer.label,
        value: masterReducer.value,
        newEditableField: masterReducer.newEditableField,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        pageTitle: rootReducer.pageTitle,
        locationMap: masterReducer.locationMap
    };
}

const mapDispatchToProps = (dispatch) => ({
    setTransferInventory: (dataArr) => dispatch(setTransferInventory(dataArr)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setTransferHeader: (dataObj) => dispatch(setTransferHeader(dataObj)),
    setFields: (dataObj) => dispatch(setFields(dataObj)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setToNavBack: (data) => dispatch(setToNavBack(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTransfer);
