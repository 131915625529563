import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setTagNbr } from '../../actions/barcodeSearchAction';
import { setDumpList, setDumpHeader, setDumpInventory } from '../../actions/Dump';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcMain, DrcPanel, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import DumpNew from './DumpNew';
import AccordianList from '../../components/AccordianList';
import TagNumberSearch from '../../components/TagNumberSearch';
import FloatingAddBtn from '../../components/FloatingAddBtn';
import { Typography } from '@material-ui/core';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { formatData, formatKey, getStoredLocation, getTrimSeasonId, setColumnOrder, checkPermission } from '../../utils/helper';
import APIEndPoints from '../../services/api';
import { getExcludedColumns, STATUS } from '../../data/constants';
import { cloneDeep } from 'lodash';
import { setToNavBack } from '../../actions/MasterActions';
import { notify } from '../../actions/NotificationAction';
import { withRouter } from 'react-router-dom';
import { setGrowerStorageNav } from '../../actions/GrowerStorage';
import { ReportUtility } from './../../utils/reportUtility';
import PrintDialog from './../../components/PrintDialog';
import { PrintUtility } from './../../utils/printUtility';

const PAGE_KEY = 'DUMPHOMEKEY';
const LIST_KEY = 'DUMPKEY';
const CREATE_KEY = 'DUMPCREATEKEY';
const GROWER_DUMPLIST_KEY = 'GROWERSTORAGEDUMPLISTKEY';

const styles = (theme) => ({
    main: {
        marginBottom: '9vh',
        borderRadius: '0'
    },
    panel: {
        border: '0'
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    search: {
        width: '87% '
    },
    growerStorageStyle: {
        border: '0',
        padding: '0',
        marginTop: '-2vh'
    },
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '0.9rem',
        paddingTop: '1rem',
        gridColumnEnd: 'span 4'
    },
    labelContainer: {
        letterSpacing: '0.5px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '&>div': {
            flexBasis: '20%'
        }
    },
    labelStyle: {
        flex: 1,
        marginRight: '1em',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    subLabel: {
        flex: 3,
        color: theme.palette.grey[600],
        paddingLeft: '1em',
        borderLeft: '1px solid ' + theme.palette.text.secondary,
        wordBreak: 'break-word'
    },
    label: {
        letterSpacing: '0.2px',
        fontWeight: 500
    }
});
const pageTitle = 'Dump';

class Dump extends React.Component {
    state = {
        createNewDump: false,
        currentPageName: 'Dump',
        list: [],
        hasMoreData: true,
        pageSize: 25,
        startIndex: 0,
        hasAccess: false,
        hasListAccess: false,
        hasCreateAccess: false,
        printDialogOpen: false,
        printRow: ''
    };
    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (!this.props.ignorePermissions) {
            if (!checkPermission(PAGE_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasAccess: false });
            } else {
                this.setState({ hasAccess: true });
            }
            if (!checkPermission(LIST_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasListAccess: false });
            } else {
                this.setState({ hasListAccess: true });
            }
            if (!checkPermission(CREATE_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasCreateAccess: false });
            } else {
                this.setState({ hasCreateAccess: true });
            }
        } else {
            this.setState({ hasAccess: true });
            this.setState({ hasCreateAccess: true });
            if (!checkPermission(GROWER_DUMPLIST_KEY, this.props.distinctAccessKeys)) {
                this.setState({ hasListAccess: false });
            } else {
                this.setState({ hasListAccess: true });
            }
        }
        this.props.setGrowerStorageNav(this.props.location.pathname.includes('GrowerStorage'));
        this.props.setToNavBack(false);
        try {
            if (getTrimSeasonId()) {
                let token = this.props.authState.accessToken;
                let inventoryType = this.props.ignorePermissions ? 'GROWERSTORAGE' : 'INVENTORY';
                let response = await Middleware.Send(
                    'Dump',
                    token,
                    APIEndPoints.DUMP_LIST(0, this.state.pageSize, getStoredLocation().Code, getTrimSeasonId().Id, STATUS.OPEN, inventoryType),
                    'GET'
                );
                this.props.setDumpList(response.Records);
                this.props.setDumpHeader({});
                this.setState({
                    list: response.Records,
                    totalRowCount: response.TotalRowCount,
                    hasMoreData: true,
                    GrowerStorageTabNav: this.props.location.pathname.includes('GrowerStorage')
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    clearSearch = (bool, row) => {
        this.props.setTagNbr(bool ? row.LogNbr : '');
    };

    toggleCreateDump = () => {
        this.setState({ createNewDump: !this.state.createNewDump, currentPageName: 'New Dump' });
    };

    handleLogNbrClick = (row) => {
        this.props.isGrowerStorageNavigation
            ? this.props.history.push(`/GrowerStorage/Dump/${row.LogNbr}`)
            : this.props.history.push(`/Dump/${row.LogNbr}`);
    };

    fetchDetails = async () => {
        let token = this.props.authState.accessToken;
        let inventoryType = this.props.ignorePermissions ? 'GROWERSTORAGE' : 'INVENTORY';
        Middleware.Send(
            'DumpInventory',
            token,
            APIEndPoints.DUMP_LOG_LINE(this.props.LogNbr, getStoredLocation().Code, getTrimSeasonId().Id, inventoryType),
            'GET',
            {}
        )
            .then((response) => {
                if (!response.DumpHeader) {
                    this.props.setTagNbr('');
                    return this.props.notify('LogNbr does not exist', true);
                } else {
                    this.props.setDumpHeader(response.DumpHeader);
                    this.props.setDumpInventory(response.DumpLogLines);
                    this.handleLogNbrClick(response.DumpHeader);
                }
            })
            .catch((err) => {
                console.error(err);
                this.props.notify('An error occurred', true);
            });
    };

    getHeaderPanel = (value, dataObj) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.labelContainer}>
                    <span
                        className={`${classes.labelValue} ${classes.labelStyle}`}
                        onClick={() => {
                            this.handleLogNbrClick(dataObj);
                        }}
                    >
                        {dataObj['LogNbr']}
                    </span>
                    <span className={classes.subLabel}>{`Warehouse : ${dataObj['DumpWarehouse'] ? dataObj['DumpWarehouse'] : ''}`}</span>
                </div>
            </>
        );
    };

    async loadAndUpdateData(startIndex, pageSize) {
        try {
            if (getTrimSeasonId()) {
                let token = this.props.authState.accessToken;
                let inventoryType = this.props.ignorePermissions ? 'GROWERSTORAGE' : 'INVENTORY';
                let response = await Middleware.Send(
                    'Dump',
                    token,
                    APIEndPoints.DUMP_LIST(startIndex, pageSize, getStoredLocation().Code, getTrimSeasonId().Id, STATUS.OPEN, inventoryType),
                    'GET',
                    {}
                );
                let data = cloneDeep(this.props.dumpList).concat(response.Records);
                this.props.setDumpList(data);
                this.setState({ totalRowCount: response.TotalRowCount, hasMoreData: true });
            }
        } catch (err) {
            console.log(err);
        }
    }

    fetchMoreData = async () => {
        const { dumpList } = this.props;
        const { totalRowCount } = this.state;
        const lastRowAdded = dumpList.length;
        if (lastRowAdded >= totalRowCount) {
            this.setState({ hasMoreData: false });
            return;
        }

        await this.loadAndUpdateData(lastRowAdded, this.state.pageSize);
    };

    redirect = (dumpNbr) => {
        if (!this.props.isGrowerStorageNavigation) {
            this.props.history.push(`/Dump/Edit/${dumpNbr}`);
        } else {
            this.props.history.push(`/GrowerStorage/Dump/Edit/${dumpNbr}`);
        }
    };

    togglePrintDialog = () => {
        this.setState({ printDialogOpen: !this.state.printDialogOpen });
    };

    handlePrint = (row) => {
        if (!PrintUtility.GetPrintServer()) {
            this.props.notify('Printing report...', false);
            this.setState({ printRow: row }, async () => this.printRow());
            return;
        }

        this.setState({ printRow: row });
        this.togglePrintDialog();
    };

    printRow = async (numberOfCopies, printerAddress) => {
        const { printRow } = this.state;
        let token = this.props.authState.accessToken;
        try {
            if (this.props.isGrowerStorageNavigation) {
                await ReportUtility.PrintGrowerStorageDump(token, printRow && printRow.LogNbr, numberOfCopies, printerAddress);
            } else {
                await ReportUtility.PrintDump(token, printRow && printRow.LogNbr, numberOfCopies, printerAddress);
            }

            if (printerAddress) {
                this.togglePrintDialog();
                this.props.notify(`Submitted ${numberOfCopies} ${numberOfCopies > 1 ? 'copies' : 'copy'} to printer`, false);
            }
        } catch (err) {
            console.log(err);
            this.props.notify(`Unable to print report`, true);
        }
    };

    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }

        const { classes, isGrowerStorageNavigation, dumpList } = this.props;
        const { printDialogOpen } = this.state;
        if (this.state.hasAccess) {
            return (
                <DrcMain transparent handHeld className={`${classes.main} ${isGrowerStorageNavigation ? classes.growerStorageStyle : null}`}>
                    <DrcPanel className={`${classes.panel} ${isGrowerStorageNavigation ? classes.growerStorageStyle : null}`}>
                        {!isGrowerStorageNavigation ? <Typography variant="h6">Dump</Typography> : null}
                        {this.state.createNewDump ? (
                            <DumpNew cancelDump={this.toggleCreateDump} redirect={this.redirect} />
                        ) : (
                            <>
                                <div className={classes.action}>
                                    <TagNumberSearch autoFocus className={classes.search} search={this.fetchDetails} label={'Search by Log #'} />
                                    {this.state.hasCreateAccess && <FloatingAddBtn onClick={this.toggleCreateDump} />}
                                </div>
                                {this.state.hasListAccess && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom className={classes.subText}>
                                            {`${dumpList.length} of ${this.state.totalRowCount || 0}`}
                                        </Typography>
                                        <AccordianList
                                            data={setColumnOrder(dumpList, 'dumpHeader')}
                                            fetchMoreData={this.fetchMoreData}
                                            hasMoreData={this.state.hasMoreData}
                                            labelFormatter={this.getHeaderPanel}
                                            dataFormatter={formatData}
                                            keyFormatter={formatKey}
                                            excludeColumns={getExcludedColumns('dumpHeader')}
                                            scrollable
                                            // customExpand={(row) => '' + row.LogNbr === LogNbr}
                                            expandRight={false}
                                            clearSearch={this.clearSearch}
                                            checkEditable={(row) =>
                                                row.Status === 'OPEN' && !this.props.isGrowerStorageNavigation
                                                    ? checkPermission('DUMPCREATEKEY', this.props.distinctAccessKeys)
                                                        ? 'right'
                                                        : null
                                                    : 'right'
                                            }
                                            onEdit={(row) => {
                                                this.redirect(row.LogNbr);
                                            }}
                                            checkPrintable={(row) => (row.Status === 'DUMPED' ? 'right' : null)}
                                            onPrint={(row) => this.handlePrint(row)}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {this.state.printDialogOpen && (
                            <PrintDialog reportType={'Dump'} onCancel={this.togglePrintDialog} onPrint={this.printRow} open={printDialogOpen} />
                        )}
                    </DrcPanel>
                </DrcMain>
            );
        } else {
            return <DrcPageNotAuthorized />;
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setTagNbr: (nbr) => dispatch(setTagNbr(nbr)),
    setDumpList: (data) => dispatch(setDumpList(data)),
    setDumpHeader: (data) => dispatch(setDumpHeader(data)),
    setDumpInventory: (data) => dispatch(setDumpInventory(data)),
    setToNavBack: (bool) => dispatch(setToNavBack(bool)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setGrowerStorageNav: (isNavigated) => dispatch(setGrowerStorageNav(isNavigated))
});

function mapStateToProps({ userAccesses, masterReducer, rootReducer, barcodeSearchReducer, growerStorage, dump }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        pageTitle: rootReducer.pageTitle,
        LogNbr: barcodeSearchReducer.tagNbr,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        dumpList: dump.dumpList,
        distinctAccessKeys: userAccesses.distinctAccessKeys
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Dump))));
