import React from 'react';
import AccordianList from './AccordianList';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { formatData, formatKey, getTrimSeasonId, makeColumns, setColumnOrder, validateTemperature, isNumber, isSuccessful } from '../utils/helper';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import { getExcludedColumns } from '../data/constants';
import { withRouter } from 'react-router-dom';
import { notify } from '../actions/NotificationAction';
import { setTransferHeader } from '../actions/Transfer';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../services/api';

const styles = (theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            // width: '24vw',
            flex: '1',
            padding: '0 0.2rem'
            // flexBasis: '20%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    },
    actionButton: {
        marginTop: '-3.5em',
        textAlign: 'end'
    }
});

class HeaderPanel extends React.Component {
    state = {
        editedQuantity: null,
        originalQuantity: null
    };
    async componentDidMount() {
        try {
            // write something
        } catch (err) {
            console.log(err);
        }
    }

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;
        if (validateTemperature(value)) {
            this.setState({ editedQuantity: value, originalQuantity: originalVal });
        }
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    getHeaderData = async (data) => {
        let token = this.props.authState.accessToken;
        let headerObj = {
            ...data,
            ReceiptPlantTemperature: this.state.editedQuantity,
            LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
            LastChangedDateTime: new Date().toISOString(),
            TrimSeasonID: getTrimSeasonId().Id,
            TrimSeasonName: getTrimSeasonId().TrimSeason
        };
        return headerObj;
    };

    updateTemperature = async (row) => {
        if (!isNumber(this.state.editedQuantity)) {
            this.props.notify('Please input correct temperature', true);
            this.setState({ editedQuantity: this.state.originalQuantity });
        } else {
            try {
                let token = this.props.authState.accessToken;
                let header = await this.getHeaderData(row);
                let response = await Middleware.Send('Transfer', token, APIEndPoints.UPDATE_TRANSFER, 'POST', {
                    TransferHeader: { ...header, ReceiptPlantTemperature: this.state.editedQuantity },
                    TransferLines: []
                });
                if (isSuccessful(response.Message)) {
                    this.props.setTransferHeader({ ...header, ReceiptPlantTemperature: this.state.editedQuantity });
                    this.props.notify(`Receive Temperature Updated`, false);
                } else {
                    this.props.notify(`Failed to Update`, true);
                }
            } catch (err) {
                console.error(err);
                this.props.notify(`Failed to Update`, true);
            }
        }
    };

    handleCancel = (row) => {
        this.setState({ editedQuantity: null, originalQuantity: null });
    };

    render() {
        const { classes, data = [], headerName, action, cancel, columnType, headerFormatter } = this.props;
        const editTemperature = this.props.location.pathname.includes('/ReceiveInventory') && (data[0] || {}).Status === 'SHIPPED';
        const newEditableField = 'ReceiptPlantTemperature';
        const editType = [
            {
                label: newEditableField,
                editType: 'text'
            }
        ];
        return (
            <>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {`${headerName} Header`}
                </Typography>

                {!this.props.editHeader && (action || cancel) && (
                    <div className={classes.actionButton}>
                        {action && (
                            <DrcButton isPrimary onClick={() => this.props.onSelectAction(action)}>
                                {action}
                            </DrcButton>
                        )}
                        {cancel && (
                            <DrcButton isPrimary onClick={() => this.props.onSelectAction('cancel')}>
                                Cancel
                            </DrcButton>
                        )}
                    </div>
                )}

                <AccordianList
                    data={setColumnOrder(data, columnType)}
                    labelFormatter={headerFormatter}
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    excludeColumns={getExcludedColumns(columnType)}
                    checkEditable={() => (this.props.editHeader ? 'right' : '')}
                    onEdit={() => {
                        this.props.editHeader();
                    }}
                    {...(editTemperature
                        ? { defaultEdit: true, newEditableField, columns: makeColumns(data[0], editType, this.editInput, this.getValue) }
                        : null)}
                    onSave={this.updateTemperature}
                    onCancel={this.handleCancel}
                >
                    {editTemperature ? 'Update' : null}
                </AccordianList>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setTransferHeader: (dataObj) => dispatch(setTransferHeader(dataObj)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ masterReducer }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        locationMap: masterReducer.locationMap
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(HeaderPanel))));
