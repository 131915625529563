import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import AccordianList from './AccordianList';
import BarcodeIdSearch from './BarcodeIDSearch';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { makeColumns, addEditableColumn, formatData, formatKey, setColumnOrder, isWholeNumber } from '../utils/helper';
import { setBarcodeId } from '../actions/barcodeSearchAction';
import { getExcludedColumns } from '../data/constants';
const styles = (theme) => ({
    container: {
        marginTop: '1.5rem'
    },
    headerLabel: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px !important'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    }
});
class InventoryLines extends Component {
    state = {
        editedQuantity: null,
        originalQuantity: null
    };
    headerFormatter = (value, row, indx) => {
        const { classes, label } = this.props;
        return (
            <div className={classes.headerLabel}>
                <span>{row[label || 'BarcodeID']}</span>
                <span>{row.Qty}</span>
            </div>
        );
    };
    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: +value, originalQuantity: originalVal });
        }
    };
    handleSave = (row) => {
        let newQty = this.state.editedQuantity ?? row.Qty;
        this.setState({ editedQuantity: null, originalQuantity: null });
        this.props.onPick(row, newQty);
        this.clearSearch(false, {});
    };
    handleCancel = () => {
        this.setState({ editedQuantity: null, originalQuantity: null });
        this.clearSearch(false, {});
    };
    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool && row['BarcodeID'] ? row['BarcodeId'] : '');
    };
    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };
    render() {
        const { barcodeId, issuanceInventory, newEditableField = 'IssuanceQty', isPickEnabled, value, labelFormatter, classes, ...rest } = this.props;
        const data =
            { ...rest }.defaultEdit || !isPickEnabled
                ? setColumnOrder(issuanceInventory, 'issuanceInventory')
                : addEditableColumn(issuanceInventory, newEditableField, 'Qty', 'issuanceInventory');
        const label = this.props.label || 'BarcodeID';
        const editField = isPickEnabled
            ? [
                  {
                      label: newEditableField,
                      editType: 'text'
                  }
              ]
            : [];
        return (
            <div className={classes.container}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {data.length ? 'Inventory' : 'No Inventory'}
                </Typography>
                {data.length ? <BarcodeIdSearch /> : null}
                <AccordianList
                    columns={makeColumns(data[0], editField, this.editInput, this.getValue)}
                    scrollable
                    data={data}
                    label={label}
                    labelFormatter={labelFormatter || this.headerFormatter}
                    // action="menu"
                    excludeColumns={[...getExcludedColumns('issuanceLine'), 'UpdateQty']}
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    defaultEdit
                    actionOptions={['Edit']}
                    clearSearch={this.clearSearch}
                    {...rest}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                >
                    {isPickEnabled ? 'Pick' : null}
                </AccordianList>
            </div>
        );
    }
}
function mapStateToProps({ issuance, barcodeSearchReducer }) {
    return {
        issuanceHeader: issuance.header,
        issuanceInventory: issuance.inventoryLines,
        barcodeId: barcodeSearchReducer.barcodeId
    };
}
const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (id) => dispatch(setBarcodeId(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InventoryLines));
