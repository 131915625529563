import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
    actionLeft: {
        marginRight: '1%'
    },
    actionRight: {
        marginLeft: '1%'
    },
    deleteIcon: {
        color: theme.palette.secondary.main
    },
    editIcon: {
        color: theme.palette.primary.main
    },
    printIcon: {
        color: theme.palette.info.dark
    }
});

class AccordianHeaderActions extends React.Component {
    render() {
        const { row, classes, editable, printable, deletable, onDelete, onPrint, displaySide, onEdit } = this.props;
        return (
            <>
                {deletable === displaySide && (
                    <Typography className={deletable === displaySide ? classes.actionRight : classes.actionLeft} onClick={(e) => onDelete(e)}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </Typography>
                )}
                {printable === displaySide && (
                    <Typography className={printable === displaySide ? classes.actionRight : classes.actionLeft} onClick={(e) => onPrint(e)}>
                        <PrintIcon className={classes.printIcon} />
                    </Typography>
                )}
                {editable === displaySide && (
                    <Typography
                        className={editable === displaySide ? classes.actionRight : classes.actionLeft}
                        onClick={(e) => {
                            onEdit(e);
                        }}
                    >
                        <EditIcon />
                    </Typography>
                )}
            </>
        );
    }
}

export default withRouter(withStyles(styles)(AccordianHeaderActions));
