import { Middleware } from '@driscollsinc/one-ring';
const getListMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('ReasonType', 'ReasonType'),
    Middleware.CreateMapping('ReasonCode', 'ReasonCode'),
    Middleware.CreateMapping('ReasonDescript', 'ReasonDescript'),
    Middleware.CreateArrayMapping('Result', 'Result')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AdjustTrimReasonCode', 'GET')) {
        Middleware.RegisterMapping('AdjustTrimReasonCode', 'GET', getListMapping);
    }
}

const AdjustTrimReasonCodeMapping = {
    ConfigureMiddleware
};

export default AdjustTrimReasonCodeMapping;
