import { PrintUtility } from './printUtility';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from './../services/api';

const reports = {
    GROWERISSUANCE: { name: 'GROWERISSUANCE', reportName: 'Grower Issuance', id: 'TagNbr' },
    GROWERSTORAGEDUMP: { name: 'GROWERSTORAGEDUMP', reportName: 'Grower Storage Dump', id: 'LogNbr' },
    GROWERSTORAGEISSUANCE: { name: 'GROWERSTORAGEISSUANCE', reportName: 'Grower Storage Issuance', id: 'TagNbr' },
    NURSERYISSUANCE: { name: 'NURSERYISSUANCE', reportName: 'Nursery Issuance', id: 'TagNbr' },
    TRANSFER: { name: 'TRANSFER', reportName: 'Transfers', id: 'TransferNbr' },
    DUMP: { name: 'DUMP', reportName: 'Plants Dump', id: 'LogNbr' },
    RETURN: { name: 'RETURN', reportName: 'Returns', id: 'LogNbr' }
};

const fetchReportData = async (token, reportType, id) => {
    const reportName = encodeURI(reportType.reportName);
    const identifier = reportType.id;
    let apiEndPoint = APIEndPoints.PRINT_REPORT(reportName, identifier, id);
    let response = await Middleware.Send('PrintReport', token, apiEndPoint, 'GET', {});
    return response.FileData;
};

const printReport = async (token, report, id, numberOfCopies, deviceAddress) => {
    let reportData = await fetchReportData(token, report, id);

    const reportName = report.reportName;
    const reportType = report.name;

    let printerAddress = `wss://${deviceAddress}/printer`;
    let fileName = `${reportName} - ${id}.pdf`;

    if (!deviceAddress) {
        printerAddress = null; // print on browser if there is no print server available
    }

    PrintUtility.PrintBase64ToPDF(printerAddress, reportData, reportType, fileName, numberOfCopies);
};

// Wrap each report over printReport. Better than using switch case if there are new reports in future.
const printGrowerIssuance = async (token, tagNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.GROWERISSUANCE, tagNbr, numberOfCopies, deviceAddress);
};

const printNurseryIssuance = async (token, tagNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.NURSERYISSUANCE, tagNbr, numberOfCopies, deviceAddress);
};

const printGrowerStorageIssuance = async (token, tagNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.GROWERSTORAGEISSUANCE, tagNbr, numberOfCopies, deviceAddress);
};

const printGrowerStorageDump = async (token, logNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.GROWERSTORAGEDUMP, logNbr, numberOfCopies, deviceAddress);
};

const printReturn = async (token, logNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.RETURN, logNbr, numberOfCopies, deviceAddress);
};

const printTransfer = async (token, transferNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.TRANSFER, transferNbr, numberOfCopies, deviceAddress);
};

const printDump = async (token, logNbr, numberOfCopies, deviceAddress) => {
    await printReport(token, reports.DUMP, logNbr, numberOfCopies, deviceAddress);
};

export const ReportUtility = {
    PrintGrowerIssuance: printGrowerIssuance,
    PrintNurseryIssuance: printNurseryIssuance,
    PrintGrowerStorageIssuance: printGrowerStorageIssuance,
    PrintGrowerStorageDump: printGrowerStorageDump,
    PrintReturn: printReturn,
    PrintTransfer: printTransfer,
    PrintDump: printDump
};
