import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AccordianList from '../../components/AccordianList';
import { Typography } from '@material-ui/core';
import BarcodeIDSearch from '../../components/BarcodeIDSearch';
import { makeColumns, setColumnOrder, addEditableColumn, formatData, formatKey, isWholeNumber, getGrowerDumpLineIndex } from '../../utils/helper';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import { getExcludedColumns, STATUS } from '../../data/constants';

const styles = (theme) => ({
    container: {
        marginTop: '1.5rem'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    headerLabel: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px !important'
    }
});
class DumpInventoryLines extends Component {
    state = {
        editedQuantity: null,
        originalQuantity: null
    };

    headerFormatter = (value, row) => {
        const { classes, label } = this.props;
        return (
            <div className={classes.headerLabel}>
                <span>{row[label || 'BarcodeID']}</span>
                <span>{row.Qty}</span>
            </div>
        );
    };

    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool ? row['BarcodeID'] : '');
    };

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: +value, originalQuantity: originalVal });
        }
    };

    handleSave = (row) => {
        let newQty = this.state.editedQuantity ?? row.Qty;
        this.props.onDump(row, newQty);
        this.setState({ editedQuantity: null, originalQuantity: null });
    };

    handleCancel = () => {
        this.setState({ editedQuantity: null, originalQuantity: null });
        this.clearSearch(false, {});
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    handleExpand = (row) => {
        const { grower, growerStorage, barcodeId } = this.props;
        if (growerStorage && grower && grower.Id) {
            return row['Key'] === getGrowerDumpLineIndex(barcodeId, grower.Id);
        }
        return row['BarcodeID'] === barcodeId;
    };

    render() {
        const { classes, barcodeId, newEditableField = 'DumpQty', inventoryLinesData, labelFormatter, dumpHeaderData, ...rest } = this.props;
        const inventoryData =
            { ...rest }.defaultEdit || dumpHeaderData.Status !== STATUS.OPEN
                ? inventoryLinesData
                : addEditableColumn(inventoryLinesData, newEditableField, 'Qty', 'dumpInventory');
        const editableField =
            dumpHeaderData.Status !== STATUS.OPEN
                ? []
                : [
                      {
                          label: newEditableField,
                          editType: 'text'
                      }
                  ];
        return (
            <div className={classes.container}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {inventoryData.length ? 'Inventory' : 'No Inventory'}
                </Typography>
                {inventoryData.length ? <BarcodeIDSearch /> : null}
                <AccordianList
                    data={setColumnOrder(inventoryData, 'dumpInventory')}
                    columns={makeColumns(inventoryData[0], editableField, this.editInput, this.getValue)}
                    labelFormatter={labelFormatter || this.headerFormatter}
                    excludeColumns={getExcludedColumns('dumpInventory')}
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    value={'Qty'}
                    scrollable
                    customExpand={(row = {}) => this.handleExpand(row)}
                    clearSearch={!this.props.isGrowerStorageNavigation ? this.clearSearch : null}
                    // clearSearch={this.clearSearch}
                    onSave={this.handleSave}
                    onCancel={this.handleCancel}
                    uniqueKey={'Key'}
                    {...rest}
                >
                    {dumpHeaderData.Status === STATUS.DUMPED || dumpHeaderData.Status === STATUS.CANCELLED ? null : 'Dump'}
                </AccordianList>
            </div>
        );
    }
}

function mapStateToProps({ barcodeSearchReducer, dump, growerStorage }) {
    return {
        barcodeId: barcodeSearchReducer.barcodeId,
        inventoryLinesData: dump.inventoryLines,
        dumpHeaderData: dump.dumpHeader,
        grower: growerStorage.grower,
        growerStorage: growerStorage.isNavigated,
        isGrowerStorageNavigation: growerStorage.isNavigated
    };
}

const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (id) => dispatch(setBarcodeId(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DumpInventoryLines));
