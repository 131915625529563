import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from './../services/api';

const searchPlantInventory = async (token, filterObj, growerStorage) => {
    const { locationId, barcodeId, berry, stocktype, variety, trimtype } = filterObj;
    const apiEndpoint = APIEndPoints.GET_PLANT_INVENTORY(
        locationId,
        barcodeId,
        berry,
        growerStorage ? 'GROWERSTORAGE' : 'INVENTORY',
        stocktype,
        variety,
        trimtype
    );
    return Middleware.Send('PlantInventory', token, apiEndpoint, 'GET', {});
};

const searchIssuanceInventory = async (token, filterObj) => {
    const { locationId, barcodeId, stocktype, variety, trimtype, issueToNbr, trimSeasonId } = filterObj;
    let varietycode = variety;
    const newFilterObj = Object.entries({ varietycode, stocktype, trimtype }).reduce((a, [k, v]) => (v ? (a = a + `&${k}=${v}`) : a), '');
    const apiEndpoint = APIEndPoints.GET_ISSUANCE_DETAILS(
        barcodeId,
        issueToNbr,
        trimSeasonId,
        'INVENTORY',
        'Grower',
        'SHIPPED',
        locationId,
        newFilterObj
    );

    let response = await Middleware.Send('PlantInventory', token, apiEndpoint, 'GET', {});
    return response;
};

const InventoryUtility = {
    SearchPlantInventory: searchPlantInventory,
    SearchIssuanceInventory: searchIssuanceInventory
};

export default InventoryUtility;
