// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

// Modal Reducers
export const SET_ERRORS = 'SET_ERRORS';
export const setErrorsAction = (title, errors) => ({
    type: SET_ERRORS,
    payload: { title, errors }
});

export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';
export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const LOGOUT = 'LOGOUT';
export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const showLoadingScreenAction = (loadingMessage) => ({
    type: SHOW_LOADING_SCREEN,
    payload: loadingMessage
});

export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';
export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});
