import { SET_USER_LOCATIONS, SET_USER_SETTINGS } from '../actions/UserAccesses';

const initialState = {
    userSettings: [],
    userLocations: [],
    distinctAccessKeys: []
};

const getDistinctKeys = (payload) => {
    return payload.map((obj) => {
        return obj.securityItem;
    });
};

const userAccesses = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_SETTINGS:
            return Object.assign({}, state, {
                userSettings: action.payload,
                distinctAccessKeys: getDistinctKeys(action.payload)
            });
        case SET_USER_LOCATIONS:
            return { ...state, userLocations: [...action.payload] };
        default:
            return state;
    }
};

export default userAccesses;
