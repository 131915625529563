import { SET_RETURN_HEADER_DETAILS, SET_RETURN_INVENTORY_LINES, SET_RETURN_LIST } from '../actions/Return';

const initialState = {
    header: {},
    inventoryLines: [],
    returnList: []
};

const Return = (state = initialState, action) => {
    switch (action.type) {
        case SET_RETURN_HEADER_DETAILS:
            return Object.assign({}, state, {
                header: action.payload
            });
        case SET_RETURN_INVENTORY_LINES:
            return Object.assign({}, state, {
                inventoryLines: action.payload
            });
        case SET_RETURN_LIST:
            return Object.assign({}, state, {
                returnList: action.payload
            });
        default:
            return state;
    }
};

export default Return;
