import { SET_HEADER_DETAILS, SET_INVENTORY_LINES, SET_ISSUANCE_LIST } from '../actions/Issuance';

const initialState = {
    header: {},
    inventoryLines: [],
    issuanceList: [],
    editedList: []
};

const Issuance = (state = initialState, action) => {
    switch (action.type) {
        case SET_HEADER_DETAILS:
            return Object.assign({}, state, {
                header: action.payload
            });
        case SET_INVENTORY_LINES:
            return Object.assign({}, state, {
                inventoryLines: action.payload
            });
        case SET_ISSUANCE_LIST:
            return Object.assign({}, state, {
                issuanceList: action.payload
            });
        default:
            return state;
    }
};

export default Issuance;
