import React from 'react';
import { connect } from 'react-redux';
import Dump from '../Dump/DumpList';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    subText: {
        color: theme.palette.text.secondary,
        letterSpacing: '0.2px',
        fontSize: '1rem',
        fontStyle: 'italic',
        gridColumnEnd: 'span 4'
    }
});

function GrowerDump(props) {
    return (
        <div>
            <Dump ignorePermissions />
        </div>
    );
}

const mapStateToProps = ({ masterReducer }) => ({
    trimSeasonMap: masterReducer.trimSeasonMap
});

export default connect(mapStateToProps, null)(withStyles(styles)(GrowerDump));
