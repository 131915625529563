import { SET_TAG_NBR, SET_BARCODE_ID } from '../actions/barcodeSearchAction';

const initialState = {
    tagNbr: '',
    barcodeId: ''
};

const barcodeSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAG_NBR:
            return Object.assign({}, state, {
                tagNbr: action.payload
            });
        case SET_BARCODE_ID:
            return Object.assign({}, state, {
                barcodeId: action.payload
            });
        default:
            return state;
    }
};

export default barcodeSearchReducer;
