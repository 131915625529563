import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPageTitleAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcPanel, DrcLegend } from '@driscollsinc/driscolls-react-components';

const PAGE_TITLE = 'Dashboard';

export default function Dashboard() {
    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    const pageTitle = useSelector((state) => state.rootReducer.pageTitle);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }

        if (pageTitle !== PAGE_TITLE) {
            dispatch(setPageTitleAction(PAGE_TITLE));
        }
    });

    return (
        <DrcMain transparent handHeld>
            <DrcPanel>
                <DrcLegend>Dashboard</DrcLegend>
            </DrcPanel>
        </DrcMain>
    );
}
