import { isMobile, isTablet, isDesktop, deviceDetect } from 'react-device-detect';

const isMobileDevice = () => {
    return isMobile;
};

const isTabletDevice = () => {
    return isTablet;
};

const isDesktopDevice = () => {
    return isDesktop;
};

const getDevice = () => {
    return deviceDetect();
};

export const DeviceDetectionUtility = {
    IsMobileDevice: isMobileDevice,
    IsTablet: isTabletDevice,
    IsDesktop: isDesktopDevice,
    GetDevice: getDevice
};
