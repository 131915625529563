export const SET_RETURN_HEADER_DETAILS = 'SET_RETURN_HEADER_DETAILS';
export const setReturnHeader = (data) => ({
    type: SET_RETURN_HEADER_DETAILS,
    payload: data
});

export const SET_RETURN_INVENTORY_LINES = 'SET_RETURN_INVENTORY_LINES';
export const setReturnInventory = (data) => ({
    type: SET_RETURN_INVENTORY_LINES,
    payload: data
});

export const SET_RETURN_LIST = 'SET_RETURN_LIST';

export const setReturnList = (data) => ({
    type: SET_RETURN_LIST,
    payload: data
});
