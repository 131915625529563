export const SET_TAG_NBR = 'SET_TAG_NBR';
export const setTagNbr = (nbr) => ({
    type: SET_TAG_NBR,
    payload: nbr
});

export const SET_BARCODE_ID = 'SET_BARCODE_ID';
export const setBarcodeId = (id) => ({
    type: SET_BARCODE_ID,
    payload: id
});
