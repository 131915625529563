import React, { Component } from 'react';
import { DrcButton, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import { Middleware } from '@driscollsinc/one-ring';
import Divider from '@material-ui/core/Divider';
import DumpHeader from './DumpHeader';
import DumpInventory from './DumpInventory';
import { setPageTitleAction } from '../../actions/actions';
import { setDumpHeader, setDumpInventory } from '../../actions/Dump';
import { connect } from 'react-redux';
import { setToNavBack } from '../../actions/MasterActions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import { getExcludedColumns, STATUS } from '../../data/constants';
import {
    formatData,
    formatKey,
    getStoredTrim,
    getStoredLocation,
    getTrimSeasonId,
    isSuccessful,
    checkPermission,
    getGrowerStorageLineIndex
} from '../../utils/helper';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as StatusIndicator } from '../../assets/StatusIndicator.svg';
import { cloneDeep } from 'lodash';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import ConfirmDialog from '../../components/ConfirmDialog';
import ZeroQtyDialog from '../../components/ZeroQtyDialog';

const styles = (theme) => ({
    root: {
        minWidth: '250px'
    },
    container: {
        margin: '48px 0 60px'
    },
    labelStyle: {
        color: theme.palette.primary.light,
        fontWeight: 'bold'
    },
    labelIndicator: {
        fill: theme.palette.primary.light,
        marginLeft: '1em'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelBtn: {
        color: theme.palette.error.light,
        border: '1px solid #c54',
        display: 'flex',
        margin: '1em auto !important;'
    },
    panel: {
        border: 0,
        marginBottom: '9vh'
    }
});
const pageTitle = 'Dump';
class ViewDump extends Component {
    state = {
        showConfirmation: false,
        row: {},
        qty: null
    };
    async componentDidMount() {
        this.props.setToNavBack(true);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        if (MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            let logNbr = this.props.match.params.Nbr;
            let token = this.props.authState.accessToken;
            let inventoryType = this.props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY';
            let promises = [
                Middleware.Send(
                    'DumpInventory',
                    token,
                    APIEndPoints.DUMP_LOG_LINE(logNbr, getStoredLocation().Code, getTrimSeasonId().Id, inventoryType),
                    'GET',
                    {}
                )
            ];
            Promise.all(promises)
                .then((response) => {
                    this.props.setDumpHeader(response[0].DumpHeader);

                    const dumpLogLines = response[0].DumpLogLines;
                    const dumpInventory = dumpLogLines.map((item) => ({
                        ...item,
                        Key: getGrowerStorageLineIndex(item.BarcodeID, item.StorageLocation)
                    }));

                    this.props.setDumpInventory(dumpInventory);
                })
                .catch((err) => {
                    console.error(err);
                    this.props.notify('An error occurred', true);
                });
        }
    }

    getHeaderLabel = (value, row) => {
        const { classes } = this.props;
        return (
            <>
                <span className={classes.labelStyle}>{row['BarcodeID']}</span>
                {row.Status === 'DUMPED' && (
                    <span className={classes.labelIndicator}>
                        <StatusIndicator style={{ width: '1em', height: '1em' }} />
                    </span>
                )}
            </>
        );
    };

    confirmEdit = async (row, qty) => {
        if (qty !== undefined && +qty === 0) {
            return this.setState({ showZeroQty: true });
        }
        await this.setState({ row, qty });
        await this.handleEditSave(this.state.row, this.state.qty);
        this.clearSearch();
    };

    handleEditSave = async (row, qty) => {
        try {
            let inventoryLines = cloneDeep(this.props.inventoryLines).filter((line) =>
                this.props.isGrowerStorageNavigation ? line.Key !== row.Key : line.BarcodeID !== row.BarcodeID
            );
            row.Status = STATUS.DUMPED;
            let token = this.props.authState.accessToken;
            let response = await Middleware.Send('DumpInventory', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                DumpHeader: this.props.dumpHeader,
                DumpLogLines: [{ ...row, Qty: row.Qty, UpdateQty: qty }]
            });
            if (response.InventoryLines[0].Message === 'Dump Log Line DUMPED successfully') {
                row.Qty = qty;
                inventoryLines.push(row);
                this.props.setDumpInventory(inventoryLines);
            } else {
                row.DumpQty = row.Qty;
                return this.props.notify(response.InventoryLines[0].Message, true);
            }
        } catch (err) {
            console.error(err);
            this.props.notify('Failed to Save Dump Qty', true);
        }
    };

    handleDump = async (status) => {
        try {
            let token = this.props.authState.accessToken;
            let header = {
                ...this.props.dumpHeader,
                LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                LastChangedDateTime: new Date().toISOString(),
                TrimSeason: getStoredTrim(),
                Status: status
            };
            let inventoryLines = cloneDeep(this.props.inventoryLines).map((line) => {
                line.Status = status;
                line.UpdateQty = line.Qty;
                return line;
            });
            let response = await Middleware.Send('DumpInventory', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                DumpHeader: header,
                DumpLogLines: inventoryLines
            });
            if (isSuccessful(response.Message)) {
                this.props.notify(`${status} successfully`, false);
                return this.props.isGrowerStorageNavigation ? this.props.history.push('/GrowerStorage/Dump/') : this.props.history.push('/Dump/');
            } else {
                this.props.notify('Error occurred', true);
            }
        } catch (err) {
            console.error(err);
            this.props.notify('Error Occurred', true);
        }
    };

    clearSearch = () => {
        this.props.setBarcodeId('');
    };

    isActionEnabled = () => {
        const { inventoryLines } = this.props;
        if (inventoryLines.length > 0) {
            return !inventoryLines.filter((line) => line.Qty === 0).length;
        }
        return false;
    };

    isDumpEnabled = () =>
        checkPermission('DUMPKEY', this.props.distinctAccessKeys) && !this.props.inventoryLines.filter((line) => line.Status === 'OPEN').length;

    render() {
        const { isMasterDataInitialized, dumpHeader, classes } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.container}>
                <DrcPanel className={classes.panel}>
                    <DumpHeader excludeColumns={getExcludedColumns('dumpHeader')} dataFormatter={formatData} keyFormatter={formatKey}>
                        {dumpHeader.Status === STATUS.OPEN && this.isActionEnabled() && (
                            <div className={classes.button}>
                                <DrcButton onClick={() => this.handleDump(STATUS.DUMPED)} isPrimary disabled={!this.isDumpEnabled()}>
                                    Dump
                                </DrcButton>
                            </div>
                        )}
                    </DumpHeader>
                    <Divider />
                    <DumpInventory
                        newEditableField="DumpQty"
                        label="BarcodeID"
                        onDump={(row, qty) => this.confirmEdit(row, qty)}
                        labelFormatter={this.getHeaderLabel}
                    />
                    {[STATUS.OPEN].includes(this.props.dumpHeader.Status) && (
                        <DrcButton
                            isSecondary
                            className={classes.cancelBtn}
                            onClick={() => this.setState({ showConfirmation: true })}
                            disabled={this.props.isGrowerStorageNavigation ? !checkPermission('DUMPCREATEKEY', this.props.distinctAccessKeys) : false}
                        >
                            Cancel Dump
                        </DrcButton>
                    )}

                    <ConfirmDialog
                        message="Are you sure you want to permanently Cancel Dump?"
                        open={this.state.showConfirmation}
                        closeConfirmDialog={() => {
                            this.setState({ showConfirmation: false, row: {}, qty: null });
                        }}
                        submitRequest={() => this.handleDump(STATUS.CANCELLED)}
                    />
                    <ZeroQtyDialog onClose={() => this.setState({ showZeroQty: false })} showDialog={this.state.showZeroQty} />
                </DrcPanel>
            </div>
        );
    }
}
function mapStateToProps({ userAccesses, rootReducer, barcodeSearchReducer, growerStorage, masterReducer, dump }) {
    return {
        pageTitle: rootReducer.pageTitle,
        barcodeId: barcodeSearchReducer.barcodeId,
        isMasterDataInitialized: masterReducer.isInitialized,
        inventoryLines: dump.inventoryLines,
        dumpHeader: dump.dumpHeader,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        grower: growerStorage.grower
    };
}
const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setDumpHeader: (header) => dispatch(setDumpHeader(header)),
    setDumpInventory: (data) => dispatch(setDumpInventory(data)),
    setToNavBack: (data) => dispatch(setToNavBack(data)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id))
});
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewDump)));
