import React, { useEffect } from 'react';
import AddInventory from '../../components/AddInventory';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { makeStyles } from '@material-ui/core/styles';
import HeaderPanel from '../../components/HeaderPanel';
import { setBarcodeId } from '../../actions/barcodeSearchAction';
import { setReturnHeader, setReturnInventory } from '../../actions/Return';
import { Middleware } from '@driscollsinc/one-ring';
import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import APIEndPoints from '../../services/api';
import { notify } from '../../actions/NotificationAction';
import { cloneDeep, isEmpty } from 'lodash';
import { setFields, setToNavBack } from '../../actions/MasterActions';
import ConfirmDialog from '../../components/ConfirmDialog';
import { setColumnOrder, getTrimSeasonId, getStoredLocation, formatKey, isSuccessful } from '../../utils/helper';
import NewReturn from './NewReturn';
import { STATUS } from '../../data/constants';
import MasterDataUtilities from '../../data/MasterDataUtilities';

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    main: {
        marginBottom: '8vh'
    },
    header: {
        display: 'flex',
        flexDirection: 'column'
    },
    labelContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        '&>div': {
            // width: '24vw',
            flex: '1',
            padding: '0 0.2rem'
            // flexBasis: '20%'
        }
    },
    label: {
        color: theme.palette.grey[500]
    },
    labelValue: {
        fontWeight: '600',
        wordBreak: 'break-word'
    }
}));
const EditReturn = (props) => {
    const classes = useStyles();
    const { authState } = useOktaAuth();
    const [openConfirmDialog, setDialogState] = React.useState(false);
    const [rowData, setRowDetails] = React.useState({});
    const [editReturnDialog, setEditReturnDialog] = React.useState(false);

    useEffect(() => {
        if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (!props.inventoryLines.length) {
            getData();
        }
    }, []);

    const updateInventoryData = async (row, updateQty, returnReasonCode) => {
        const { headerData } = props;
        let inventoryLines = cloneDeep(props.inventoryLines).filter((line) => line.BarcodeID !== row.BarcodeID);

        try {
            let token = authState.accessToken;

            delete row.OrderQty;
            delete row.defaultValue;

            let response = await Middleware.Send('Return', token, APIEndPoints.RETURN_UPDATE, 'POST', {
                ReturnHeader: {
                    ...headerData,
                    LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                    PostStatus: STATUS.OPEN,
                    TrimSeasonName: getTrimSeasonId().TrimSeason
                },
                ReturnLogLines: [
                    {
                        ...row,
                        Qty: row.Qty,
                        LogNbr: headerData.LogNbr,
                        UpdateQty: parseInt(updateQty),
                        ...{ ...(!row.LogNbr ? { ID: null, Qty: parseInt(updateQty) } : null) },
                        Status: STATUS.OPEN,
                        ReasonCode: returnReasonCode.label
                    }
                ]
            });
            if (isSuccessful(response.Message)) {
                if (!response.InventoryLines.length) {
                    props.notify('Insufficient quantity', true);
                    const inventoryLines = [{ ...row, defaultValue: updateQty }];
                    props.setReturnInventory(inventoryLines);
                    props.setBarcodeId(row.BarcodeID);
                } else if (response.InventoryLines[0].Message === 'Insufficient quantity') {
                    props.notify('Insufficient quantity', true);
                    const inventoryLines = [{ ...row, defaultValue: updateQty }];
                    props.setReturnInventory(inventoryLines);
                    props.setBarcodeId(props.BarcodeID);
                } else if (response.InventoryLines[0].Message === 'Return Log Line OPEN successfully') {
                    row.ID = response.InventoryLines[0]['ID'];
                    if (!row.LogNbr) row.LogNbr = response.LogNbr;
                    row.Qty = parseInt(updateQty);
                    row.ReasonCode = returnReasonCode.label;
                    inventoryLines.push(row);
                    props.setReturnInventory(inventoryLines);
                    props.setBarcodeId('');
                } else {
                    props.setReturnInventory(inventoryLines);
                    props.setBarcodeId('');
                }
            }
        } catch (err) {
            console.error(err);
            props.setBarcodeId('');
            props.notify('Failed to Order Return Inventory', true);
        }
    };

    const getData = async () => {
        props.setToNavBack(true);
        let token = authState.accessToken;
        let logNbr = props.match.params.LogNbr;
        let responseData = await Middleware.Send(
            'Return',
            token,
            APIEndPoints.RETURN_INVENTORY(logNbr, getTrimSeasonId().Id, getStoredLocation().Code),
            'GET',
            {}
        );
        let header = responseData.ReturnHeader;
        let inventoryLines = responseData.ReturnLines;

        props.setFields({
            label: 'BarcodeID',
            value: 'Qty',
            newEditableField: 'OrderQty'
        });
        props.setReturnInventory(inventoryLines);
        props.setReturnHeader(header);
    };

    const handleDelete = (dataObj, showDialog = true) => {
        if (showDialog) {
            setRowDetails(dataObj);
            setDialogState(true);
        } else {
            setRowDetails(dataObj);
            deleteInventoryData(dataObj);
        }
    };

    const removeUnsavedLine = (data) => {
        const filteredLines = cloneDeep(props.inventoryLines).filter((line) => line.BarcodeID !== data.BarcodeID);
        props.notify('Inventory Line Deleted', false);
        props.setReturnInventory(filteredLines);
        props.setBarcodeId('');
        setDialogState(false);
    };

    const deleteInventoryData = async (dataObj = {}) => {
        const data = !isEmpty(dataObj) ? cloneDeep(dataObj) : cloneDeep(rowData);
        if (!isEmpty(data)) {
            try {
                if (!data.LogNbr) {
                    removeUnsavedLine(data);
                    return;
                }

                const logId = data.ID;
                let token = props.authState.accessToken;
                let response = await Middleware.Send('ReturnList', token, APIEndPoints.DELETE_RETURN_LOG_LINE(logId, data.LogNbr), 'DELETE');
                if (response.Message === 'ID Deleted Successfully') {
                    props.notify('Inventory Line Deleted', false);
                    let inventoryLines = cloneDeep(props.inventoryLines).filter((line) => line.ID !== logId);
                    props.setReturnInventory(inventoryLines);
                    props.setBarcodeId('');
                } else {
                    props.notify('Failed to Delete', true);
                    props.setBarcodeId('');
                }
            } catch (err) {
                console.error(err);
                props.notify('Failed to Delete', true);
            }
        }
        setRowDetails({});
        setDialogState(false);
    };

    const getHeaderPanel = (value, dataObj) => {
        let labelValue = `${dataObj['ReturnedFromNbr']}-${dataObj.growerEntity}`;

        return (
            <>
                <div className={classes.labelContainer}>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('LogNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['LogNbr']}</span>
                    </div>
                    <div className={classes.header}>
                        <span className={classes.label}>{formatKey('RefNbr')}</span>
                        <span className={classes.labelValue}>{dataObj['RefNbr']}</span>
                    </div>
                </div>
                <div className={classes.header}>
                    <span className={classes.label}>{formatKey('ReturnedFromNbr')}</span>
                    <span className={classes.labelValue}>{labelValue}</span>
                </div>
            </>
        );
    };

    const { label, value, newEditableField, headerData, inventoryLines } = props;
    const confirmationMsg = 'Are you sure you want to delete ';
    return (
        <DrcMain transparent handHeld className={classes.main}>
            <DrcPanel className={classes.panel}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {`Edit Return`}
                </Typography>
                {editReturnDialog ? (
                    <NewReturn
                        headerDataObj={headerData}
                        onCancel={() => {
                            setEditReturnDialog(false);
                        }}
                        editReturn={editReturnDialog}
                    />
                ) : (
                    <HeaderPanel
                        data={setColumnOrder([headerData], 'returnHeader')}
                        headerName={'Return'}
                        columnType={'returnHeader'}
                        headerFormatter={getHeaderPanel}
                        editHeader={() => {
                            setEditReturnDialog(!editReturnDialog);
                        }}
                    />
                )}
                <AddInventory
                    pathName={'Return'}
                    label={label}
                    value={value}
                    newEditableField={newEditableField}
                    updateInventoryData={updateInventoryData}
                    handleDelete={handleDelete}
                    inventoryLines={inventoryLines}
                />
                <ConfirmDialog
                    message={`${confirmationMsg} ${rowData.BarcodeID}?`}
                    open={openConfirmDialog}
                    closeConfirmDialog={() => {
                        setDialogState(false);
                    }}
                    submitRequest={() => deleteInventoryData()}
                />
            </DrcPanel>
        </DrcMain>
    );
};
function mapStateToProps({ rootReducer, returnLog, growerStorage, masterReducer }) {
    return {
        headerData: returnLog.header,
        inventoryLines: returnLog.inventoryLines,
        isMasterDataInitialized: masterReducer.isInitialized,
        label: masterReducer.label,
        value: masterReducer.value,
        newEditableField: masterReducer.newEditableField,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        pageTitle: rootReducer.pageTitle
    };
}

const mapDispatchToProps = (dispatch) => ({
    setReturnHeader: (dataObj) => dispatch(setReturnHeader(dataObj)),
    setReturnInventory: (dataArr) => dispatch(setReturnInventory(dataArr)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setFields: (dataObj) => dispatch(setFields(dataObj)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setToNavBack: (data) => dispatch(setToNavBack(data))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(EditReturn));
