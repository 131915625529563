export const SET_GROWER_STORAGE_NAV = 'SET_GROWER_STORAGE_NAV';
export const setGrowerStorageNav = (isNavigated) => ({
    type: SET_GROWER_STORAGE_NAV,
    payload: isNavigated
});

export const SET_SELECTED_GROWER = 'SET_SELECTED_GROWER';
export const setSelectedGrower = (data) => ({
    type: SET_SELECTED_GROWER,
    payload: data
});

export const SET_GROWER_INVENTORY_FETCHING = 'SET_GROWER_INVENTORY_FETCHING';
export const setGrowerInventoryFetching = (data) => ({
    type: SET_GROWER_INVENTORY_FETCHING,
    payload: data
});

export const SET_GROWERSTORAGE_ISSUANCE_EDIT_MODE = 'SET_GROWERSTORAGE_ISSUANCE_EDIT_MODE';
export const setGrowerStorageIssuanceEditMode = (data) => ({
    type: SET_GROWERSTORAGE_ISSUANCE_EDIT_MODE,
    payload: data
});
