import { combineReducers } from 'redux';
import masterReducer from './MasterReducer';
import barcodeSearchReducer from './barcodeSearchReducer';
import issuance from './Issuance';
import transfer from './Transfer';
import adjustTrim from './AdjustTrim';
import print from './Print';
import returnLog from './Return';
import growerStorage from './GrowerStorage';
import dump from './Dump';
import notificationReducer from './NotificationReducer';
import userAccesses from './UserAccesses';
import { SHOW_LOADING_SCREEN, HIDE_LOADING_SCREEN, SET_ERRORS, HIDE_ERROR_DIALOG, SET_PAGE_TITLE } from '../actions/actions';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    errorDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    }
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: action.payload
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    masterReducer,
    barcodeSearchReducer,
    issuance,
    transfer,
    adjustTrim,
    print,
    growerStorage,
    dump,
    returnLog,
    notificationReducer,
    userAccesses
});
