import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import AccordianList from '../../components/AccordianList';
import BarcodeIdSearch from '../../components/BarcodeIDSearch';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { getExcludedColumns } from '../../data/constants';
import { makeColumns, addEditableColumn, formatData, formatKey, setColumnOrder, isWholeNumber } from '../../utils/helper';
import { setBarcodeId } from '../../actions/barcodeSearchAction';

const styles = (theme) => ({
    container: {
        marginTop: '1.5rem'
    },
    headerLabel: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px !important'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    }
});
class ReturnInventoryLines extends Component {
    state = {
        editedQuantity: null,
        originalQuantity: null
    };
    headerFormatter = (value, row) => {
        const { classes } = this.props;
        return (
            <div className={classes.headerLabel}>
                <span>{row['BarcodeID']}</span>
                <span>{row.Qty}</span>
            </div>
        );
    };

    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool ? row['BarcodeID'] : '');
    };

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: +value, originalQuantity: originalVal });
        }
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    handleSave = () => {
        //Make Api call and save edited quantity
        alert(this.state.editedQuantity);
    };

    handleCancel = () => {
        this.setState({ editedQuantity: null, originalQuantity: null });
    };

    render() {
        const { barcodeId, returnInventory, classes, ...rest } = this.props;
        const inventoryData = true
            ? setColumnOrder(returnInventory, 'returnInventory')
            : addEditableColumn(returnInventory, 'NewQty', 'Qty', 'returnInventory');

        const editableField = [
            {
                label: 'NewQty',
                editType: 'text'
            }
        ];

        return (
            <div className={classes.container}>
                <Typography variant="h6" gutterBottom className={classes.heading}>
                    {inventoryData.length ? 'Inventory' : 'No Inventory'}
                </Typography>
                {inventoryData.length ? <BarcodeIdSearch /> : null}
                <AccordianList
                    data={barcodeId === '' ? inventoryData : inventoryData.filter((row) => '' + row['BarcodeID'] === '' + barcodeId)}
                    customExpand={(row = {}) => '' + row['BarcodeID'] === '' + barcodeId}
                    columns={makeColumns(inventoryData[0], editableField, this.editInput, this.getValue)}
                    label="BarcodeID"
                    // labelFormatter={this.headerFormatter}
                    value={'Qty'}
                    clearSearch={this.clearSearch}
                    scrollable
                    dataFormatter={formatData}
                    keyFormatter={formatKey}
                    excludeColumns={getExcludedColumns('returnInventory')}
                    {...rest}
                />
            </div>
        );
    }
}
function mapStateToProps({ returnLog, barcodeSearchReducer }) {
    return {
        returnHeader: returnLog.header,
        returnInventory: returnLog.inventoryLines,
        barcodeId: barcodeSearchReducer.barcodeId
    };
}

const mapDispatchToProps = (dispatch) => ({
    setBarcodeId: (id) => dispatch(setBarcodeId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReturnInventoryLines));
