import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcInput, DrcButton, DrcSelect, DrcDatePicker } from '@driscollsinc/driscolls-react-components';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { Middleware } from '@driscollsinc/one-ring';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { setReturnHeader } from '../../actions/Return';
import { setFields } from '../../actions/MasterActions';
import APIEndPoints from '../../services/api';
import {
    getLocalTrimAndLocation,
    getTrimSeasonId,
    makeLocationOption,
    toISOdateString,
    getGrowerEntities,
    isSuccessful,
    addTimeStampToDate
} from '../../utils/helper';
import { notify } from '../../actions/NotificationAction';

const pageTitle = 'Return';

const styles = (theme) => ({
    wrapper: {
        marginTop: '5px'
    },
    container: {
        margin: '5px 0px'
    },
    header: {
        color: theme.palette.text.secondary,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    },
    buttonContainer: {
        width: '100%',
        marginTop: '1rem',
        background: 'transparent',
        height: '50px',
        bottom: '0px'
    },
    flexContainer: {
        display: 'flex'
    },
    spaceContainer: {
        paddingRight: '10px',
        flexBasis: '45%'
    }
});

class NewReturn extends React.Component {
    constructor(props) {
        super(props);
        const {
            LogNbr,
            Comments,
            TrimSeasonID,
            PostStatus,
            ReturnedFromType,
            ReturnWarehouse,
            RefNbr,
            growerEntity,
            InventoryEffect,
            ReturnedFromNbr,
            CreatedBy,
            ReturnedDate,
            ReturnedBy,
            CreatedDateTime
        } = {
            headerData: {},
            ...this.props.headerData
        };

        this.state = {
            growerEntities: [],
            selectedGrower: growerEntity ? { label: `${ReturnedFromNbr}-${growerEntity}`, value: ReturnedFromNbr, Id: ReturnedFromNbr } : null,
            growerEntity: growerEntity ? growerEntity : null,
            returnedFromNbr: ReturnedFromNbr ? ReturnedFromNbr : null,
            returnFromType: ReturnedFromType ? ReturnedFromType : 'Grower',
            status: PostStatus ? PostStatus : 'OPEN',
            logNbr: LogNbr ? LogNbr : null,
            inventoryEffect: InventoryEffect ? InventoryEffect : 'GROWERSTORAGE',
            referenceNbr: RefNbr ? RefNbr : null,
            comments: Comments ? Comments : '',
            returnedBy: ReturnedBy ? ReturnedBy : '',
            returnedDate: ReturnedDate ? new Date(ReturnedDate) : new Date(),
            trimSeasonId: TrimSeasonID ? TrimSeasonID : getTrimSeasonId().Id,
            returnWarehouse: ReturnWarehouse ? ReturnWarehouse : getLocalTrimAndLocation().location.Code,
            createdBy: CreatedBy,
            createdDateTime: CreatedDateTime
        };
    }

    componentDidMount() {
        const { ReturnedFromNbr } = this.props.headerData;
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitleAction(pageTitle);
        }
        let growerEntities = getGrowerEntities();
        const growerDetails = growerEntities.filter((eachGrower) => parseInt(eachGrower.Id) === parseInt(ReturnedFromNbr));
        this.setState({
            growerEntities: makeLocationOption(growerEntities, 'Id', 'GrowerEntity'),
            selectedGrower: makeLocationOption(growerDetails, 'Id', 'GrowerEntity')[0]
        });
    }

    handleInput = (fieldName) => (event) => {
        this.setState({ [fieldName]: event.target.value });
    };

    handleSelectInput = (name) => (option) => {
        this.setState({ [name]: option });
    };

    handleDateChange = (dateObj) => {
        this.setState({ returnedDate: dateObj });
    };

    getHeaderData = async () => {
        const { editReturn } = this.props;
        const {
            selectedGrower,
            logNbr,
            status,
            inventoryEffect,
            referenceNbr,
            comments,
            returnWarehouse,
            returnedBy,
            returnFromType,
            returnedDate,
            createdBy,
            createdDateTime
        } = this.state;
        let token = this.props.authState.accessToken;
        let headerObj = {
            LogNbr: logNbr ? logNbr : null,
            LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
            Comments: comments ? comments : '',
            TrimSeasonID: getTrimSeasonId().Id,
            PostStatus: status,
            ReturnedFromType: returnFromType,
            ReturnWarehouse: returnWarehouse,
            RefNbr: referenceNbr,
            growerEntity: selectedGrower.GrowerEntity,
            InventoryEffect: inventoryEffect,
            ReturnedFromNbr: selectedGrower.Id,
            CreatedBy: createdBy,
            LastChangedDateTime: new Date().toISOString(),
            ReturnedDate: toISOdateString(returnedDate),
            ReturnedBy: returnedBy,
            CreatedDateTime: createdDateTime
        };
        if (!editReturn) {
            headerObj.CreatedBy = DuAuthenticationUtilities.GetUserId(token);
            headerObj.CreatedDateTime = new Date().toISOString();
        }
        return headerObj;
    };

    createReturnHeader = async () => {
        const { editReturn } = this.props;
        try {
            let token = this.props.authState.accessToken;
            let header = await this.getHeaderData();
            let response = await Middleware.Send('Return', token, APIEndPoints.RETURN_UPDATE, 'POST', {
                ReturnHeader: header,
                ReturnLogLines: []
            });

            if (isSuccessful(response.Message)) {
                header.LogNbr = response.LogNbr;
                this.props.setReturnHeader(header);
                this.props.setFields({
                    label: 'BarcodeID',
                    value: 'Qty',
                    newEditableField: 'OrderQty'
                });
                if (editReturn && this.props.onCancel) {
                    this.props.onCancel();
                } else {
                    this.props.redirect(`${response.LogNbr}`);
                }

                this.props.notify(`Return Header ${response.LogNbr} ${editReturn ? 'Updated' : 'Created'}  Successfully`, false);
            }
        } catch (err) {
            console.error(err);
            this.props.notify(`Failed To ${editReturn ? 'Update' : 'Create'} Return Header`, true);
        }
    };

    isFormValid = () => {
        return this.props.editReturn ? false : !(this.state.selectedGrower && this.state.inventoryEffect);
    };
    render() {
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
        const { classes, editReturn } = this.props;
        return (
            <>
                <DrcSelect
                    label={'Grower'}
                    onChange={this.handleSelectInput('selectedGrower')}
                    value={this.state.selectedGrower}
                    options={this.state.growerEntities}
                    isWindowedSelect
                    ignoreAccents={true}
                    required
                />
                <div className={classes.container}>
                    <span className={classes.header}>Entity Type :</span> <span>{this.state.returnFromType}</span>
                </div>
                <FormControl component="fieldset" className={classes.wrapper} required>
                    <FormLabel component="legend">Inventory Affect</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="trimSeason"
                        value={this.state.inventoryEffect}
                        onChange={this.handleInput('inventoryEffect')}
                        row
                    >
                        <FormControlLabel control={<Radio color="default" />} value="GROWERSTORAGE" label="Grower Storage" />
                        <FormControlLabel control={<Radio color="default" />} value="DUMP" label="Dump" />
                    </RadioGroup>
                </FormControl>
                <DrcInput
                    label={'Reference #'}
                    onChange={this.handleInput('referenceNbr')}
                    value={this.state.referenceNbr}
                    inputProps={{ maxLength: 50 }}
                />

                <DrcDatePicker
                    clearable={false}
                    value={this.state.returnedDate || new Date()}
                    label={'Return Date'}
                    onChange={(date) => {
                        this.handleDateChange(addTimeStampToDate(date), 'returnedDate');
                    }}
                />

                <DrcInput
                    label={'Returned By'}
                    onChange={this.handleInput('returnedBy')}
                    value={this.state.returnedBy}
                    inputProps={{ maxLength: 50 }}
                />
                <DrcInput label={'Comments'} onChange={this.handleInput('comments')} value={this.state.comments} inputProps={{ maxLength: 255 }} />
                <div className={`${classes.flexContainer} ${classes.buttonContainer}`}>
                    <DrcButton onClick={this.props.onCancel} isSecondary>
                        Cancel
                    </DrcButton>
                    <DrcButton onClick={this.createReturnHeader} isPrimary disabled={this.isFormValid()}>
                        {editReturn ? 'Update' : 'Create'}
                    </DrcButton>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitleAction: (pageTitle) => dispatch(setPageTitleAction(pageTitle)),
    setFields: (obj) => dispatch(setFields(obj)),
    setReturnHeader: (obj) => dispatch(setReturnHeader(obj)),
    notify: (data, isError) => dispatch(notify(data, isError))
});

function mapStateToProps({ masterReducer, rootReducer, returnLog }) {
    return {
        isMasterDataInitialized: masterReducer.isInitialized,
        entityMetaData: masterReducer.metaData,
        filters: masterReducer.filters,
        pageTitle: rootReducer.pageTitle,
        headerData: returnLog.header,
        returnList: returnLog.returnList,
        inventoryLines: returnLog.inventoryLines
    };
}
export default withOktaAuth(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewReturn))));
