import React, { Component } from 'react';
import { setGrowerInventoryFetching, setGrowerStorageNav, setSelectedGrower } from '../actions/GrowerStorage';
import { Typography } from '@material-ui/core';
import AccordianList from './AccordianList';
import BarcodeIdSearch from './BarcodeIDSearch';
import IssuanceHeader from '../pages/Issuance/issuanceHeader';
import DumpHeader from '../pages/Dump/DumpHeader';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { setIssuanceInventory, setIssuanceHeader } from '../actions/Issuance';
import { setDumpHeader, setDumpInventory } from '../actions/Dump';
import { setBarcodeId } from '../actions/barcodeSearchAction';
import { Middleware } from '@driscollsinc/one-ring';
import {
    addEditableColumn,
    formatData,
    formatKey,
    getGrowerDistrict,
    getStoredLocation,
    getTrimSeasonId,
    getStoredTrim,
    makeColumns,
    makeLocationOption,
    isWholeNumber,
    getGrowerDumpLineIndex,
    getGrowerStorageLineIndex,
    getGrowerId,
    checkPermission,
    isSuccessful
} from '../utils/helper';
import { withStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import APIEndPoints from '../services/api';
import { withRouter } from 'react-router-dom';
import { notify } from '../actions/NotificationAction';
import { getExcludedColumns, STATUS } from '../data/constants';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcButton, DrcDialog, DrcSelect } from '@driscollsinc/driscolls-react-components';
import ZeroQtyDialog from '../components/ZeroQtyDialog';
import InventorySearch from './InventorySearch';
import InventoryUtility from './../utils/inventoryUtility';

const styles = (theme) => ({
    root: {
        minWidth: '250px'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerLabel: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px !important'
    },
    labelStyle: {
        color: theme.palette.primary.light,
        fontWeight: 'bold'
    },
    heading: {
        color: theme.palette.text.secondary,
        fontWeight: '500',
        letterSpacing: '0.29px',
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.common.white
        }
    }
});
class AddInventoryLines extends Component {
    state = {
        editedQuantity: null,
        originalQuantity: null,
        barcodeId: '',
        isEditMode: this.props.location.pathname.includes(`/Edit`), //&& !this.props.isGrowerStorageNavigation,
        pathName:
            this.props.match.path.split('/')[1] === 'GrowerStorage'
                ? this.props.match.path.split('/')[1] + '/' + this.props.match.path.split('/')[2]
                : this.props.match.path.split('/')[1],
        reasonCodesList: [],
        rowUnderEdit: {},
        showReasonCodeDialog: false,
        reasonCode: null,
        storageType: this.props.location.pathname.includes(`/GrowerStorage`) ? 'GROWERSTORAGE' : 'INVENTORY',
        grower: this.props.growerEntity ? this.props.growerEntity : null,
        growersList: [],
        inventoryLines: [],
        inventorySearchLines: []
    };

    async componentDidMount() {
        this.props.setGrowerStorageNav(this.props.location.pathname.includes('GrowerStorage'));
        await this.getApiResponse();
        this.getData();
    }
    getData = async () => {
        try {
            let trimSeason = getStoredTrim();
            let berry = this.props.trimSeasonMap[trimSeason];
            let token = this.props.authState.accessToken;
            const resp = await Middleware.Send('GrowerEntities', token, APIEndPoints.GROWERS_LIST(berry, getTrimSeasonId().Id), 'GET', {});
            this.setState({ growersList: makeLocationOption(resp.Result, 'Id', 'GrowerEntity') });
        } catch (err) {
            console.log(err);
        }
    };
    getApiResponse = async () => {
        try {
            if (getTrimSeasonId()) {
                let token = this.props.authState.accessToken;
                let tagNbr = this.props.match.params.Nbr;
                switch (this.state.pathName) {
                    case 'Issuance':
                    case 'GrowerStorage/Issuance':
                        let issuanceResp = await Middleware.Send(
                            'Issuance',
                            token,
                            APIEndPoints.ISSUANCE_DETAIL(tagNbr, getTrimSeasonId().Id, this.state.storageType),
                            'GET',
                            {}
                        );
                        this.props.setIssuanceHeader(issuanceResp.IssuanceHeader);
                        this.props.setInventory(issuanceResp.InventoryLines);
                        break;

                    case 'Dump':
                    case 'GrowerStorage/Dump':
                        let promises = [
                            Middleware.Send(
                                'DumpInventory',
                                token,
                                APIEndPoints.DUMP_LOG_LINE(tagNbr, getStoredLocation().Code, getTrimSeasonId().Id, this.state.storageType),
                                'GET',
                                {}
                            ),
                            Middleware.Send('ReasonCode', token, APIEndPoints.REASON_CODES('DUMP'), 'GET', {})
                        ];
                        Promise.all(promises)
                            .then((response) => {
                                this.setState({ reasonCodesList: response[1].Result });
                                this.props.setDumpHeader(response[0].DumpHeader);
                                let inventoryData = response[0].DumpLogLines;
                                if (this.isGrowerStorageDump()) {
                                    inventoryData =
                                        response[0].DumpLogLines ||
                                        [].map((line) => {
                                            return { ...line, Key: getGrowerStorageLineIndex(line.BarcodeID, line.StorageLocation) };
                                        });
                                }
                                this.props.setInventory(inventoryData);
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.notify('An error occurred', true);
                            });
                        break;

                    default:
                        this.props.setIssuanceHeader({});
                        this.props.setDumpHeader({});
                        this.props.setInventory([]);
                }
            }
        } catch (err) {
            this.props.notify('An Error Occurred', true);
            console.error(err);
        }
    };

    isGrowerStorageIssuance = () => {
        return (
            this.props.isGrowerStorageNavigation &&
            this.state.isEditMode &&
            this.state.pathName.includes('Issuance') &&
            this.state.grower !== this.props.growerEntity
        );
    };

    isGrowerStorageDump = () => {
        const { isGrowerStorageNavigation } = this.props;
        return isGrowerStorageNavigation && this.state.pathName.includes('Dump');
    };

    async componentDidUpdate(prevProps) {
        try {
            const { header, barcodeId, issuanceInventory, trimSeasonMap, authState, setInventory, notify, setBarcodeId } = this.props;

            if (this.isGrowerStorageIssuance()) {
                this.setState({ grower: this.props.growerEntity });
            }

            if (barcodeId.length === 10 && barcodeId !== prevProps.barcodeId) {
                let inventoryItems = cloneDeep(issuanceInventory || []);
                let found = this.isGrowerStorageDump()
                    ? inventoryItems.find((line) => line.BarcodeID === barcodeId && getGrowerId(line.StorageLocation) === this.state.grower.Id + '')
                    : inventoryItems.find((line) => line.BarcodeID === barcodeId);
                if (found) {
                    return;
                }

                //else API call to fetch barcodeId
                inventoryItems = inventoryItems.filter((eachLine) => eachLine.ID); //to allow only ordered lines to display in the list
                let locationId = getStoredLocation().Code;
                let trimSeason = getStoredTrim();
                let berry = trimSeasonMap[trimSeason];
                let token = authState.accessToken;

                let growerPrefix = '%23GR'; //equivalent to #GR in query parameters

                let apiEndPoint = this.props.isGrowerStorageNavigation
                    ? APIEndPoints.GET_GROWER_INVENTORY(
                          barcodeId,
                          getTrimSeasonId().Id,
                          berry,
                          locationId,
                          this.state.grower.Id,
                          growerPrefix + this.state.grower.Id
                      )
                    : APIEndPoints.GET_PLANT_INVENTORY(
                          locationId,
                          barcodeId,
                          berry,
                          this.props.isGrowerStorageNavigation ? 'GROWERSTORAGE' : 'INVENTORY',
                          header.IssueToType === 'Grower' ? 'COMMERCIAL' : header.IssueToType === 'Nursery' ? 'FOUNDATION' : null,
                          null,
                          null,
                          this.props.isGrowerStorageNavigation ? growerPrefix + this.state.grower.value : null
                      );

                let response = await Middleware.Send('PlantInventory', token, apiEndPoint, 'GET', {});
                let inventoryResponse = this.props.isGrowerStorageNavigation ? response.Result : response;

                if (inventoryResponse.length) {
                    let data = inventoryResponse[0];
                    delete data.ID;

                    if (this.isGrowerStorageDump()) {
                        data.Key = getGrowerDumpLineIndex(data.BarcodeID, this.state.grower.Id);
                    }
                    inventoryItems.push(data);
                    this.setState({ barcodeId: barcodeId }, () => {
                        setInventory(inventoryItems);
                        setBarcodeId(barcodeId);
                    });
                } else {
                    notify('Not found', true);
                    setBarcodeId('');
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    editInput = (row, originalVal) => (e) => {
        let value = e.target.value;

        if (isWholeNumber(value)) {
            this.setState({ editedQuantity: value, originalQuantity: originalVal });
        }
    };

    postEditedData = async (type, row, qty) => {
        let token = this.props.authState.accessToken;
        switch (type) {
            case 'Issuance':
            case 'GrowerStorage/Issuance':
                let entityType = this.props.header.IssueToType;
                return Middleware.Send('Issuance', token, APIEndPoints.CREATE_ISSUANCE, 'POST', {
                    IssuanceHeader: {
                        ...this.props.header,
                        Status: STATUS.RESERVED,
                        LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                        TrimSeasonName: getStoredTrim(),
                        InventoryLocation: getStoredLocation().Code,
                        ...{
                            ...(entityType === 'Grower'
                                ? {
                                      GrowingDistrict: getGrowerDistrict(this.props.header.EntityName).GrowingDistrict,
                                      VendorInvoiceAccNum: getGrowerDistrict(this.props.header.EntityName).VendorInvoiceAccNum
                                  }
                                : '')
                        }
                    },
                    InventoryLines: [{ ...row, UpdateQty: qty, ...{ ...(!row.Status ? { Qty: qty } : null) }, Status: STATUS.RESERVED }]
                });
            case 'Dump':
            case 'GrowerStorage/Dump':
                return Middleware.Send('DumpInventory', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                    DumpHeader: {
                        ...this.props.header,
                        Status: STATUS.OPEN,
                        LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                        TrimSeason: getStoredTrim()
                    },
                    DumpLogLines: [
                        {
                            ...row,
                            UpdateQty: qty,
                            ...{ ...(!row.Status ? { Qty: qty } : null) },
                            Status: STATUS.OPEN,
                            ReasonCode: this.state.reasonCode.label
                        }
                    ]
                });
            default:
                return null;
        }
    };

    saveData = (row) => {
        if ((this.state.editedQuantity !== null && +this.state.editedQuantity === 0) || (this.state.editedQuantity === null && row.Qty === 0)) {
            this.setState({ showDelete: true, rowUnderEdit: row });
            if (this.state.pathName.includes('Dump')) {
                this.setState({ showReasonCodeDialog: true });
            }
        } else {
            if (this.state.pathName.includes('Dump')) {
                this.setState({ showReasonCodeDialog: true, rowUnderEdit: row });
            } else {
                this.handleEditSave(row);
            }
        }
    };

    handleEditSave = async (row) => {
        try {
            let qty = +(this.state.editedQuantity ?? row.Qty);
            let inventoryLines = cloneDeep(this.props.issuanceInventory).filter((line) =>
                this.isGrowerStorageDump() ? line.Key && line.Key !== row.Key : line.BarcodeID !== row.BarcodeID
            );
            row.IssuanceID = this.props.header.TagNbr;
            delete row[this.props.newEditableField];
            var response;
            var status;
            if (row.Status !== STATUS.DUMPED) {
                response = await this.postEditedData(this.state.pathName, row, qty);
                var responseMessage = (response?.InventoryLines?.[0]?.Message) || (response?.Message);
                if (
                    responseMessage === 'Inventory Line RESERVED successfully' ||
                    responseMessage === 'Dump Log Line OPEN successfully'
                ) {
                    status = response.LogNbr ? STATUS.OPEN : STATUS.RESERVED;
                    var data = {
                        ...row,
                        Qty: qty,
                        ID: response.InventoryLines[0].ID,
                        Status: status,
                        ...{
                            ...(this.isGrowerStorageDump()
                                ? {
                                      Key: this.state.grower
                                          ? getGrowerDumpLineIndex(row.BarcodeID, this.state.grower.Id)
                                          : getGrowerStorageLineIndex(row.BarcodeID, row.StorageLocation)
                                  }
                                : null)
                        },
                        ...{ ...(response.LogNbr ? { ReasonCode: this.state.reasonCode.label } : null) }
                    };
                    this.props.setBarcodeId('');
                    inventoryLines.push(data);
                    this.props.setInventory(inventoryLines);
                    status === STATUS.OPEN
                        ? this.props.setDumpHeader({ ...this.props.header, Status: status })
                        : this.props.setIssuanceHeader({ ...this.props.header, Status: status });

                    this.setState({
                        barcodeId: '',
                        editedQuantity: null,
                        originalQuantity: null
                    });
                    this.clearSearch(false, {});
                } else {
                    this.props.notify(responseMessage, true);
                    if (!row.Status) {
                        //to continue editing
                        this.props.setInventory(this.props.issuanceInventory);
                        this.props.setBarcodeId(this.state.barcodeId);
                    }
                }
            }
            const newZeroQtyLine = qty === 0 && status === STATUS.OPEN;
            if (checkPermission('DUMPKEY', this.props.distinctAccessKeys) && this.state.pathName.includes('Dump') && !newZeroQtyLine) {
                try {
                    let inventoryLinesAfterDump = cloneDeep(this.props.issuanceInventory).filter((line) =>
                        this.props.isGrowerStorageNavigation ? line.Key !== row.Key : line.BarcodeID !== row.BarcodeID
                    );
                    row.Qty = row.Status === STATUS.DUMPED ? row.Qty : qty;
                    row.Status = STATUS.DUMPED;
                    row.ID = row.ID || response?.InventoryLines?.[0]?.ID;
                    row.LogNbr = row.LogNbr || response.LogNbr;
                    row.ReasonCode = this.state.reasonCode.label;
                    row.Key = row.Key || getGrowerStorageLineIndex(row.BarcodeID, row.StorageLocation);

                    let token = this.props.authState.accessToken;
                    let result = await Middleware.Send('DumpInventory', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                        DumpHeader: this.props.dumpHeader,
                        DumpLogLines: [{ ...row, Qty: row.Qty, UpdateQty: qty }]
                    });
                    if (result?.InventoryLines?.[0]?.Message === 'Dump Log Line DUMPED successfully') {
                        this.props.notify(result?.InventoryLines?.[0]?.Message, false);
                        const index = inventoryLinesAfterDump.findIndex((item) =>
                            this.isGrowerStorageDump() ? item.Key === row.Key : item.BarcodeID === row.BarcodeID
                        );
                        row.Qty = qty;
                        if (index > -1) {
                            inventoryLinesAfterDump[index] = row;
                        } else {
                            inventoryLinesAfterDump.push(row);
                        }
                        this.props.setInventory(inventoryLinesAfterDump);
                    } else {
                        row.DumpQty = row.Qty;
                        if(result?.InventoryLines?.[0]?.Message){
                            return this.props.notify(result?.InventoryLines?.[0]?.Message, true);
                        }
                    }
                } catch (err) {
                    console.error(err);
                    this.props.notify('Failed to Save Dump Qty', true);
                }
            }
            this.setState({ reasonCode: null });
        } catch (err) {
            console.error(err);
            this.props.notify('Failed to Reserve InventoryLine', true);
        }
    };

    handleCancel = (row) => {
        if (!row.Status) {
            let inventoryItems = cloneDeep(this.props.issuanceInventory).filter((line) =>
                this.isGrowerStorageDump() ? line.ID : line.BarcodeID !== row.BarcodeID
            );
            this.props.setInventory(inventoryItems);
        }
        this.setState({
            barcodeId: '',
            editedQuantity: null,
            originalQuantity: null,
            showReasonCodeDialog: false,
            rowUnderEdit: {},
            showDelete: false
        });
        this.clearSearch(false, {});
    };

    clearSearch = (bool, row) => {
        this.props.setBarcodeId(bool ? row['BarcodeID'] : '');
    };

    getValue = (value) => {
        return this.state.editedQuantity === null ? value : this.state.editedQuantity;
    };

    handleDelete = (row, hideConfirm) => {
        this.setState({
            editedQuantity: null,
            originalQuantity: null,
            showDelete: false,
            showReasonCodeDialog: false,
            reasonCode: null,
            barcodeId: ''
        });
        this.clearSearch(false, {});
        this.props.handleDelete(row, hideConfirm === false);
    };

    getHeaderLabel = (value, row) => {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.headerLabel}>
                    <span className={classes.labelStyle}>{row.BarcodeID}</span>
                    <span className={classes.labelStyle}>{row.Qty}</span>
                </div>
            </>
        );
    };

    handleSelect = (row) => {
        this.setState({ reasonCode: row });
    };

    handleConfirm = () => {
        this.handleEditSave(this.state.rowUnderEdit);
        this.setState({ showReasonCodeDialog: false, rowUnderEdit: {}, showDelete: false });
    };

    handleCloseDialog = () => {
        if (this.state.showDelete) {
            if (!this.state.rowUnderEdit.Status) {
                this.handleCancel(this.state.rowUnderEdit);
            } else {
                this.handleDelete(this.state.rowUnderEdit, false);
            }
        } else {
            this.setState({ showReasonCodeDialog: false, rowUnderEdit: {}, showDelete: false, reasonCode: null });
        }
    };

    onSelectChange = (row) => {
        this.props.setSelectedGrower(row);
        this.setState({ grower: row });
    };

    handleExpand = (row) => {
        const { isGrowerStorageNavigation, barcodeId } = this.props;
        const { grower } = this.state;
        if (isGrowerStorageNavigation && grower && grower.Id) {
            return row['Key'] === getGrowerDumpLineIndex(barcodeId, grower.Id);
        }
        return row['BarcodeID'] === barcodeId;
    };
    isActionEnabled = () => {
        let { issuanceInventory, newEditableField = 'DumpQty' } = this.props;
        const inventoryLines = addEditableColumn(issuanceInventory || [], newEditableField, 'Qty', 'issuanceInventory');
        if (inventoryLines.length > 0) {
            return !inventoryLines.filter((line) => line.Qty === 0).length;
        }
        return false;
    };

    isDumpEnabled = () => {
        let { issuanceInventory, newEditableField = 'DumpQty' } = this.props;
        const inventoryLines = addEditableColumn(issuanceInventory || [], newEditableField, 'Qty', 'issuanceInventory');
        return checkPermission('DUMPKEY', this.props.distinctAccessKeys) && !inventoryLines.filter((line) => line.Status !== STATUS.DUMPED).length;
    };
    handleDump = async (status) => {
        try {
            let token = this.props.authState.accessToken;
            let header = {
                ...this.props.dumpHeader,
                LastChangedBy: DuAuthenticationUtilities.GetUserId(token),
                LastChangedDateTime: new Date().toISOString(),
                TrimSeason: getStoredTrim(),
                Status: status
            };
            let inventoryLines = cloneDeep(this.props.issuanceInventory).map((line) => {
                line.Status = status;
                line.UpdateQty = line.Qty;
                return line;
            });
            let response = await Middleware.Send('DumpInventory', token, APIEndPoints.UPDATE_DUMP_LOG, 'POST', {
                DumpHeader: header,
                DumpLogLines: inventoryLines
            });
            if (isSuccessful(response.Message)) {
                this.props.notify(`${status} successfully`, false);
                return this.props.isGrowerStorageNavigation ? this.props.history.push('/GrowerStorage/Dump/') : this.props.history.push('/Dump/');
            } else {
                this.props.notify('Error occurred', true);
            }
        } catch (err) {
            console.error(err);
            this.props.notify('Error Occurred', true);
        }
    };

    handleInventorySearch = (options) => {
        const { variety, trimType, stockType } = options;
        const locationId = getStoredLocation().Code;
        const trimSeason = getStoredTrim();
        const berry = this.props.trimSeasonMap[trimSeason];
        const filterObj = {
            variety: variety ? variety.Code : null,
            trimtype: trimType ? trimType.TrimType : null,
            stocktype:
                this.props.header.IssueToType === 'Grower' && this.state.pathName === 'Issuance'
                    ? 'Commercial'
                    : this.props.header.IssueToType === 'Nursery' && this.state.pathName === 'Issuance'
                    ? 'Foundation'
                    : stockType
                    ? stockType.value === 'ALL'
                        ? null
                        : stockType.value
                    : null,
            locationId: locationId,
            berry: berry,
            barcodeId: ''
        };

        window.location.pathname.includes('GrowerStorage') ? this.searchGrowerInventory(filterObj) : this.searchPlantInventory(filterObj);
    };
    searchGrowerInventory = async (filterObj) => {
        let { locationId, berry, variety, trimtype, stocktype } = filterObj;
        let token = this.props.authState.accessToken;

        let growerPrefix = '%23GR'; //equivalent to #GR in query parameters

        let apiEndPoint = APIEndPoints.GET_GROWER_INVENTORY(
            null,
            getTrimSeasonId().Id,
            berry,
            locationId,
            this.state.grower.Id,
            growerPrefix + this.state.grower.Id,
            window.location.pathname.includes('Issuance') ? 'Commercial' : stocktype,
            variety,
            trimtype
        );
        let response = await Middleware.Send('PlantInventory', token, apiEndPoint, 'GET', {});
        let inventoryResponse = this.props.isGrowerStorageNavigation ? response.Result : response;

        if (inventoryResponse.length) {
            let data = inventoryResponse;
            data = data.map(({ ID, ...rest }) => rest);
            if (this.isGrowerStorageDump()) {
                data.Key = getGrowerDumpLineIndex(data.BarcodeID, this.state.grower.Id);
            }

            this.setState({ inventorySearchLines: data });
        } else {
            notify('Not found', true);
            setBarcodeId('');
        }
    };
    searchPlantInventory = (filterObj) => {
        let token = this.props.authState.accessToken;
        InventoryUtility.SearchPlantInventory(token, filterObj, window.location.pathname.includes('GrowerStorage')).then((inventoryResponse) => {
            if (inventoryResponse && inventoryResponse.length) {
                inventoryResponse.forEach((item) => {
                    delete item.ID;
                });

                this.setState({ inventorySearchLines: inventoryResponse });
            } else {
                this.props.notify('Not found', true);
                this.props.setBarcodeId('');
                this.setState({ inventorySearchLines: [] });
            }
        });
    };

    handleAddInventoryLine = (row) => {
        const inventory = [...this.props.issuanceInventory];
        inventory.push(row);
        this.setState({ barcodeId: row.BarcodeID }, () => {
            this.props.setBarcodeId(row.BarcodeID);
        });
        this.props.setInventory(inventory);
    };

    render() {
        const { header, issuanceInventory, barcodeId, newEditableField = 'OrderQty', classes } = this.props;
        const editField = [
            {
                label: newEditableField,
                editType: 'text'
            }
        ];
        const newDataFields = addEditableColumn(issuanceInventory || [], newEditableField, 'Qty', 'issuanceInventory');
        return (
            <div>
                {this.state.pathName.includes('Issuance') && (
                    <>
                        {!this.props.isGrowerStorageNavigation && (
                            <Typography variant="h6" gutterBottom className={classes.heading}>
                                {`Edit Issuance`}
                            </Typography>
                        )}
                        <IssuanceHeader
                            excludeColumns={getExcludedColumns('issuanceHeader')}
                            dataFormatter={formatData}
                            keyFormatter={formatKey}
                            growerEntity={this.state.grower}
                        />
                    </>
                )}
                {this.state.pathName.includes('Dump') && (
                    <>
                        {!this.props.isGrowerStorageNavigation && (
                            <Typography variant="h6" gutterBottom className={classes.heading}>
                                {`Edit Dump`}
                            </Typography>
                        )}
                        <DumpHeader excludeColumns={getExcludedColumns('dumpHeader')} dataFormatter={formatData} keyFormatter={formatKey}>
                            {header.Status === STATUS.OPEN && this.isActionEnabled() && (
                                <div className={classes.button}>
                                    <DrcButton onClick={() => this.handleDump(STATUS.DUMPED)} isPrimary disabled={!this.isDumpEnabled()}>
                                        Dump
                                    </DrcButton>
                                </div>
                            )}
                        </DumpHeader>
                    </>
                )}
                {this.props.isGrowerStorageNavigation && this.state.pathName.includes('Dump') && (
                    <DrcSelect
                        options={this.state.growersList}
                        label="Select Grower"
                        onChange={this.onSelectChange}
                        value={this.state.grower}
                        required={true}
                        isWindowedSelect
                        ignoreAccents={true}
                    />
                )}

                <>
                    {this.props.isGrowerStorageNavigation && this.state.pathName.includes('Dump') ? (
                        this.state.grower && (
                            <>
                                <BarcodeIdSearch />
                                <InventorySearch
                                    onSearch={this.handleInventorySearch}
                                    onAdd={this.handleAddInventoryLine}
                                    inventoryLines={this.props.issuanceInventory}
                                    inventorySearchLines={this.state.inventorySearchLines}
                                    pathName={this.state.pathName}
                                />
                            </>
                        )
                    ) : (
                        <>
                            <BarcodeIdSearch />
                            <InventorySearch
                                onSearch={this.handleInventorySearch}
                                onAdd={this.handleAddInventoryLine}
                                inventoryLines={this.props.issuanceInventory}
                                inventorySearchLines={this.state.inventorySearchLines}
                                pathName={this.state.pathName}
                            />
                        </>
                    )}

                    <AccordianList
                        columns={makeColumns(newDataFields[0], editField, this.editInput, this.getValue)}
                        data={newDataFields}
                        label={'BarcodeID'}
                        // value={'Qty'}
                        clearSearch={!this.isGrowerStorageDump() ? this.clearSearch : null}
                        onCancel={this.handleCancel}
                        onSave={this.saveData}
                        customExpand={(row = {}) => {
                            return this.isGrowerStorageDump()
                                ? row['BarcodeID'] === barcodeId &&
                                      getGrowerId(row.StorageLocation) === (this.state.grower && this.state.grower.Id + '')
                                : row['BarcodeID'] === barcodeId;
                        }}
                        labelFormatter={this.getHeaderLabel}
                        excludeColumns={getExcludedColumns('issuanceLine')}
                        dataFormatter={formatData}
                        keyFormatter={formatKey}
                        scrollable
                        {...(this.state.isEditMode ? { deletable: 'right' } : null)}
                        disableAction={(row = {}) => {
                            return this.getValue(row[newEditableField]) === '';
                        }}
                        onDelete={this.handleDelete}
                        newEditableField={newEditableField}
                        defaultEdit
                    >
                        {this.state.pathName.includes('Dump') ? 'Dump' : 'Order'}
                    </AccordianList>
                </>
                <ZeroQtyDialog onClose={() => this.setState({ showZeroQty: false })} showDialog={this.state.showZeroQty} />

                <DrcDialog
                    classes={classes}
                    title={this.state.showDelete ? `Qty is 0. Do you want to Delete or Save?` : `Select a reason code`}
                    open={this.state.showReasonCodeDialog || this.state.showDelete}
                    buttons={
                        <>
                            <DrcButton onClick={this.handleCloseDialog}>{this.state.showDelete ? 'Delete' : 'Cancel'}</DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.handleConfirm}
                                {...(this.state.showReasonCodeDialog ? { disabled: !this.state.reasonCode } : null)}
                            >
                                {this.state.showDelete ? 'Save' : 'Confirm'}
                            </DrcButton>
                        </>
                    }
                >
                    {this.state.showReasonCodeDialog && (
                        <DrcSelect
                            options={makeLocationOption(this.state.reasonCodesList, 'ReasonCode', 'ReasonDescript')}
                            onChange={this.handleSelect}
                            value={this.state.reasonCode}
                            label="Reason Code"
                            required={true}
                            isSearchable={false}
                            ignoreAccents={true}
                            maxMenuHeight={window.innerHeight * 2.15}
                        />
                    )}
                </DrcDialog>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setInventory: (data) => dispatch(setIssuanceInventory(data)),
    setBarcodeId: (id) => dispatch(setBarcodeId(id)),
    setIssuanceHeader: (header) => dispatch(setIssuanceHeader(header)),
    setDumpHeader: (header) => dispatch(setDumpHeader(header)),
    setGrowerStorageNav: (isNavigated) => dispatch(setGrowerStorageNav(isNavigated)),
    setSelectedGrower: (grower) => dispatch(setSelectedGrower(grower)),
    setGrowerInventoryFetching: (status) => dispatch(setGrowerInventoryFetching(status)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    setDumpInventory: (data) => dispatch(setDumpInventory(data))
});

function mapStateToProps({ dump, barcodeSearchReducer, issuance, masterReducer, growerStorage, userAccesses }) {
    return {
        dumpHeader: dump.dumpHeader,
        distinctAccessKeys: userAccesses.distinctAccessKeys,
        barcodeId: barcodeSearchReducer.barcodeId,
        issuanceInventory: issuance.inventoryLines,
        trimSeasonMap: masterReducer.trimSeasonMap,
        isGrowerStorageNavigation: growerStorage.isNavigated,
        grower: growerStorage.grower,
        growerInventoryFetching: growerStorage.growerInventoryFetching
    };
}
export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AddInventoryLines))));
