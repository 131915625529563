// break this
class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;

    static TRIM_SEASONS = this.BASE + 'trimseasons';
    static TRIM_TYPE = this.BASE + 'trimtype';
    static WAREHOUSE = this.BASE + 'warehouse';
    static VARIETY = (berry, trimseasonid, varietycode) =>
        this.BASE +
        `variety?berrytype=${berry}${trimseasonid ? '&trimseasonid=' + trimseasonid : ''}${varietycode ? '&varietycode=' + varietycode : ''}`;

    //COMMON
    static GET_PLANT_INVENTORY = (locationId, barcodeId, commodity, storageType, stocktype, variety, trimtype, storagelocation) =>
        `${this.BASE}plantinventory?inventorylocation=${locationId}${barcodeId ? '&barcodeid=' + barcodeId : ''}&commodity=${commodity}${
            storageType ? '&storagetype=' + storageType : ''
        }${stocktype ? '&stocktype=' + stocktype : ''}${variety ? '&varietycode=' + variety : ''}${
            storagelocation ? '&storagelocation=' + storagelocation : ''
        }${trimtype ? '&trimtype=' + trimtype : ''}`;

    // TRANSFER
    static OPEN_TRANSFER_LINES = (offset, limit, originlocationid, status, trimseasonid) =>
        `${this.BASE}transfers?offset=${offset}&limit=${limit}${
            status ? '&status=' + status : ''
        }&originlocationid=${originlocationid}&trimseasonid=${trimseasonid}`;

    static SEARCH_OPEN_TRANSFER = (transfernbr, originlocationid, status) => {
        return `${this.BASE}transfers?transfernbr=${transfernbr}${status ? '&status=' + status : ''}&originlocationid=${originlocationid}`;
    };
    static RECEIVING_TRANSFER_LINES = (offset, limit, originlocationid, status, trimseasonid) =>
        `${this.BASE}transfers?offset=${offset}&limit=${limit}&status=${status}&destinationlocationid=${originlocationid}&trimseasonid=${trimseasonid}`;

    static SEARCH_RECEIVING_TRANSFER = (transfernbr, originlocationid, status) => {
        return `${this.BASE}transfers?transfernbr=${transfernbr}&status=${status}&destinationlocationid=${originlocationid}`;
    };
    static TRANSFER_INVENTORY = (transfernbr, trimseasonid, status, openTransfer, originlocationid) => {
        return `${this.BASE}transfers/${transfernbr}?trimseasonid=${trimseasonid}${status ? `&status=${status}` : ''}${
            originlocationid ? (openTransfer ? `&originlocationid=${originlocationid}` : `&destinationlocationid=${originlocationid}`) : ''
        }`;
    };

    static DELETE_INVENTORY = (transferID) => {
        return `${this.BASE}transferlines/${transferID}`;
    };

    static UPDATE_TRANSFER = `${this.BASE}transfers`;

    //ISSUANCE
    static ISSUANCE_LIST = (trimseasonid, offset, limit, status, storagetype, inventorylocation) =>
        `${this.BASE}issuance?trimseasonid=${trimseasonid}&offset=${offset}&limit=${limit}${!status ? "&status=('OPEN','RESERVED')" : ''}${
            storagetype ? `&storagetype=${storagetype}` : ''
        }${inventorylocation ? `&inventorylocation=${inventorylocation}` : ''}`;
    static ISSUANCE_DETAIL = (tagNbr, trimseasonid, storagetype, inventorylocation) =>
        `${this.BASE}issuance/${tagNbr}?trimseasonid=${trimseasonid}${storagetype ? `&storagetype=${storagetype}` : ''}${
            inventorylocation ? `&inventorylocation=${inventorylocation}` : ''
        }`;
    static GROWER_ENTITIES = `${this.BASE}grower`;
    static NURSERY_ENTITIES = `${this.BASE}nursery`;
    static CREATE_ISSUANCE = `${this.BASE}issuance`;
    static DELETE_ISSUANCE_INVENTORY = (id, tagNbr, storageType) => `${this.BASE}issuancedetail?id=${id}&tagnbr=${tagNbr}&storagetype=${storageType}`;

    //ADJUST TRIM
    static REASON_CODES = (type) => `${this.BASE}reasoncode?reasontype=${type}`;
    static ADJUST_TRIM = `${this.BASE}plantinventory`;
    //ADJUSTMENT REQUEST
    static ADJUSTMENT_REQUEST = (locationId, status = 'OPEN') =>
        `${this.BASE}adjustmentrequest${locationId ? `?originlocationid=${locationId}` : ''}${status ? `&status=${status}` : ''}`;
    static ADJUSTMENT_DETAILS = (requestId) => `${this.BASE}adjustmentrequest${`/${requestId}`}`;
    static ADJUSTMENT_POST = `${this.BASE}adjustmentrequest`;

    //DUMP
    static DUMP_LIST = (offset, limit, dumpWarehouse, trimseasonid, status, storageType) =>
        `${this.BASE}dump?offset=${offset}&limit=${limit}&dumpwarehouse=${dumpWarehouse}&trimseasonid=${trimseasonid}${
            status ? `&status=${status}` : ''
        }${storageType ? `&storagetype=${storageType}` : ''}
        `;
    static DUMP_LOG_LINE = (logNbr, dumpwarehouse, trimseasonid, storageType) =>
        `${this.BASE}dump/${logNbr}?dumpwarehouse=${dumpwarehouse}&trimseasonid=${trimseasonid}${storageType ? `&storagetype=${storageType}` : ''} `;
    static UPDATE_DUMP_LOG = `${this.BASE}dump`;
    static DELETE_DUMP_LOG_LINE = (id, lognbr) => `${this.BASE}dumplogline/${id}?lognbr=${lognbr}`;

    //RETURN
    static RETURN_LIST = (offset, limit, trimSeasonId, returnWarehouse) =>
        `${this.BASE}return?offset=${offset}&limit=${limit}&trimseasonid=${trimSeasonId}&returnwarehouse=${returnWarehouse}`;
    static RETURN_INVENTORY = (tagNbr, trimSeasonid, inventoryLocation) =>
        `${this.BASE}return/${tagNbr}?${trimSeasonid ? `trimseasonid=${trimSeasonid}` : ''}${
            inventoryLocation ? `&returnwarehouse=${inventoryLocation}` : ''
        }`;
    static RETURN_UPDATE = `${this.BASE}return`;
    static DELETE_RETURN_LOG_LINE = (id, logNbr) => `${this.BASE}/returnlogline/${id}?lognbr=${logNbr}`;
    static GET_ISSUANCE_DETAILS = (barcodeid, issuetonbr, trimseasonId, storagetype, issuetotype, status, inventorylocation, filterObj = null) =>
        `${this.BASE}issuancedetail?${
            barcodeid ? 'barcodeid=' + barcodeid : ''
        }&issuetonbr=${issuetonbr}&trimseasonid=${trimseasonId}&storagetype=${storagetype}&issuetotype=${issuetotype}&status=${status}&inventorylocation=${inventorylocation}${
            filterObj ? `${filterObj}` : ''
        }`;

    //User permissions
    static USER_ROLES = (username) => `${this.BASE}userroles?username=${username}`;
    static USER_LOCATIONS = (username) => `${this.BASE}userdcs?username=${username}`;

    //Grower Storage
    static GET_GROWER_INVENTORY = (
        barcodeId,
        trimSeasonId,
        commodity,
        inventoryLocation,
        returnFromNbr,
        storagelocation,
        stocktype,
        varietycode,
        trimtype
    ) =>
        `${this.BASE}growerdetails?trimseasonid=${trimSeasonId}${
            barcodeId ? '&barcodeid=' + barcodeId : ''
        }&inventorylocation=${inventoryLocation}&commodity=${commodity}&returnedfromnbr=${returnFromNbr}&storagetype=GROWERSTORAGE&inventorytype=TRIM${
            storagelocation ? '&storagelocation=' + storagelocation : ''
        }${stocktype ? '&stocktype=' + stocktype : ''}${varietycode ? '&varietycode=' + varietycode : ''}${trimtype ? '&trimtype=' + trimtype : ''}`;
    static GROWERS_LIST = (berry, trimseasonId) =>
        `${this.BASE}growers?returnedfromtype=Grower&commodity=${berry}&trimseasonid=${trimseasonId}&inventoryeffect=GROWERSTORAGE`;

    //Maintenance
    static PRINT_SERVER_LIST = (printServer, location) =>
        `${this.BASE}printservers${printServer ? `?printserver=${printServer}` : ``}${location ? `?printlocation=${location}` : ``}`;
    static CREATE_PRINT_SERVER = `${this.BASE}printservers`;
    static DELETE_PRINT_SERVER = (printServerId) => `${this.BASE}printservers/${printServerId}`;

    //Print Report
    static PRINT_REPORT = (reportName, identifier, id) => `${this.BASE}printreport?reportname=${reportName}&identifier=${identifier}&refnbr=${id}`;
}

export default APIEndPoints;
