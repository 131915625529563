import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setToNavBack } from '../../actions/MasterActions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcMain, DrcTabs, DrcPanel, DrcPageNotAuthorized } from '@driscollsinc/driscolls-react-components';
import TransferList from './TransferList';
import ReceivingList from './ReceivingList';
import { checkPermission } from '../../utils/helper';

const PAGE_TITLE = 'Transfer';
const PAGE_KEY = 'TRANSFERLOGADMINKEY';

const useStyles = makeStyles(() => ({
    activeTabItem: {
        borderRadius: '0'
    }
}));
const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || [],
    window.config.OKTA_ENTITY_ADMIN || []
);
function TransferHome(props) {
    const classes = useStyles();

    const isMasterDataInitialized = useSelector((state) => state.masterReducer.isInitialized);
    const pageTitle = useSelector((state) => state.rootReducer.pageTitle);

    const dispatch = useDispatch();
    const [hasAccess, setAccess] = useState(false);
    useEffect(() => {
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }

        if (!checkPermission(PAGE_KEY, props.distinctAccessKeys)) {
            setAccess(false);
        } else {
            setAccess(true);
        }
        if (pageTitle !== PAGE_TITLE) {
            dispatch(setPageTitleAction(PAGE_TITLE));
        }
        dispatch(setToNavBack(false));
    });
    if (hasAccess) {
        if (!MasterDataUtilities.GetSetup()) {
            return MasterDataUtilities.RedirectToSetup();
        }
        return (
            <DrcMain transparent handHeld>
                <DrcPanel maxWidth="100%" style={{ marginBottom: '8vh', border: 'none', paddingTop: '0' }}>
                    <DrcTabs
                        menuItems={[
                            {
                                tabName: 'Open Transfers',
                                tabIndex: 0,
                                component: TransferList,
                                route: 'Open',
                                isDefault: true,
                                groupsAllowed: allGroups
                            },
                            {
                                tabName: 'Receiving List',
                                tabIndex: 2,
                                component: ReceivingList,
                                route: 'ReceivingList',
                                groupsAllowed: allGroups
                            }
                        ]}
                        registerRoutes
                        classes={classes}
                    />
                </DrcPanel>
            </DrcMain>
        );
    } else {
        return <DrcPageNotAuthorized />;
    }
}

function mapStateToProps(state) {
    return {
        distinctAccessKeys: state.userAccesses.distinctAccessKeys
    };
}

export default connect(mapStateToProps)(TransferHome);
