import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED,
    SET_IS_ADMIN,
    SET_BACK_NAV,
    SET_LOCATIONS_LIST,
    SET_TRIMSEASON_LIST,
    SET_FIELDS,
    SET_GROWER_ENTITY,
    SET_NURSERY_ENTITY,
    SET_TRIMTYPE_LIST,
    SET_IS_SUPERADMIN
} from '../actions/MasterActions';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/TrimSeason/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    isInitialized: false,
    redirectUrl: REDIRECT_URL,
    isAdmin: false,
    isSuperAdmin: false,
    toNavBack: false,
    trimSeasons: [],
    trimSeasonMap: {},
    trimTypeList: [],
    locationsList: [],
    locationMap: {},
    label: '',
    value: '',
    newEditableField: '',
    route: null,
    growerEntities: [],
    nurseryEntities: []
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            };
        case SET_IS_SUPERADMIN:
            return {
                ...state,
                isSuperAdmin: action.payload
            };
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case SET_BACK_NAV:
            return Object.assign({}, state, {
                toNavBack: action.payload.val,
                route: action.payload.route
            });

        case SET_LOCATIONS_LIST:
            let locationMap = action.payload.reduce((map, val) => {
                map[val.Code] = val.Descript;
                return map;
            }, {});
            return Object.assign({}, state, {
                ...state,
                locationsList: action.payload,
                locationMap
            });

        case SET_TRIMSEASON_LIST:
            let trimSeasonMap = action.payload.reduce((map, val) => {
                map[val.TrimSeason] = val.Commodity;
                return map;
            }, {});
            return Object.assign({}, state, {
                ...state,
                trimSeasons: action.payload,
                trimSeasonMap
            });

        case SET_TRIMTYPE_LIST:
            return Object.assign({}, state, {
                ...state,
                trimTypeList: action.payload
            });

        case SET_FIELDS:
            return Object.assign({}, state, {
                label: action.payload.label,
                value: action.payload.value,
                newEditableField: action.payload.newEditableField
            });

        case SET_GROWER_ENTITY:
            return Object.assign({}, state, {
                growerEntities: action.payload
            });

        case SET_NURSERY_ENTITY:
            return Object.assign({}, state, {
                nurseryEntities: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
