import { SET_ADJUST_TRIM_HEADER_DETAILS, SET_REASON_CODE_LIST, SET_VARIETY } from '../actions/AdjustTrim';

const initialState = {
    headerDetails: [],
    reasonCodeList: [],
    varietyList: [],
    varietyMap: {},
    inventorySearchLines: []
};

const AdjustTrim = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADJUST_TRIM_HEADER_DETAILS:
            return Object.assign({}, state, {
                headerDetails: action.payload
            });
        case SET_REASON_CODE_LIST:
            return Object.assign({}, state, {
                reasonCodeList: action.payload
            });
        case SET_VARIETY:
            let varietyMap = action.payload.reduce((map, val) => {
                map[val.Code] = val.Descript;
                return map;
            }, {});
            return Object.assign({}, state, {
                ...state,
                varietyList: action.payload,
                varietyMap
            });
        default:
            return state;
    }
};

export default AdjustTrim;
