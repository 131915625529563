function PrintService(options) {
    let defaults = {
        url: 'ws://127.0.0.1:12212/printer',
        onConnect: function () {
            //write code
        },
        onDisconnect: function () {
            //write code
        },
        onUpdate: function () {
            //write code
        }
    };

    let settings = Object.assign({}, defaults, options);
    let websocket;
    let connected = false;

    let onMessage = function (evt) {
        settings.onUpdate(evt.data);
    };

    let onConnect = function () {
        connected = true;
        settings.onConnect();
    };

    let onError = function (err) {
        settings.onError(err);
    };

    let onDisconnect = function () {
        connected = false;
        settings.onDisconnect();
    };

    this.connect = () => {
        return new Promise(function (resolve, reject) {
            websocket = new WebSocket(settings.url);
            websocket.onopen = function () {
                onConnect();
                resolve(websocket);
            };
            websocket.onerror = function (err) {
                onError(err);
            };
            websocket.onclose = onDisconnect;
            websocket.onmessage = onMessage;
            websocket.onmessage = function (err) {
                reject(err);
            };
        });
    };

    this.submit = function (data) {
        if (Array.isArray(data)) {
            data.forEach(function (element) {
                websocket.send(JSON.stringify(element));
            });
        } else {
            websocket.send(JSON.stringify(data));
        }
    };

    this.isConnected = function () {
        return connected;
    };
}

export default PrintService;
