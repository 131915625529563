import Transfer from './transfer';
import TransferInventory from './transferInventory';
import IssuanceList from './issuanceList';
import TrimSeason from './trimSeason';
import TrimType from './trimType';
import Issuance from './issuance';
import Locations from './locations';
import ReturnList from './returnList';
import ReturnInventory from './returnInventory';
import Dump from './dump';
import DumpInventory from './dumpInventory';
import AdjustTrim from './adjustTrim';
import AdjustTrimReasonCode from './adjustTrimReasonCode';
import Variety from './variety';
import GrowerEntities from './growerEntities';
import NurseryEntities from './nurseryEntities';
import PlantInventoryMapping from './plantInventory';
import userPermissions from './userPermissions';
import MaintenanceMapping from './maintenance';
import adjustmentRequestsMapping from './adjustmentRequests';
import ReportsMapping from './reports';

function ConfigureAllMiddleware() {
    Transfer.ConfigureMiddleware();
    adjustmentRequestsMapping.ConfigureMiddleware();
    TransferInventory.ConfigureMiddleware();
    IssuanceList.ConfigureMiddleware();
    Issuance.ConfigureMiddleware();
    TrimSeason.ConfigureMiddleware();
    TrimType.ConfigureMiddleware();
    Locations.ConfigureMiddleware();
    Dump.ConfigureMiddleware();
    DumpInventory.ConfigureMiddleware();
    ReturnList.ConfigureMiddleware();
    ReturnInventory.ConfigureMiddleware();
    AdjustTrim.ConfigureMiddleware();
    AdjustTrimReasonCode.ConfigureMiddleware();
    Variety.ConfigureMiddleware();
    GrowerEntities.ConfigureMiddleware();
    NurseryEntities.ConfigureMiddleware();
    PlantInventoryMapping.ConfigureMiddleware();
    userPermissions.ConfigureMiddleware();
    MaintenanceMapping.ConfigureMiddleware();
    ReportsMapping.ConfigureMiddleware();
}

const Middleware = {
    ConfigureAllMiddleware
};

export default Middleware;
